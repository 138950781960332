import { sleep } from '../helpers/utils';

export default function (Alpine) {
    Alpine.directive('textarea', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);
        // const model = ta.prop('x-model');
        const data = evaluateLater(expression);
        const withCounter = +ta.data('with-counter') === 1;
        const withInfo = `${ta.data('with-info') || ''}` !== '';

        const initial = false;
        const defHeight = `${+el.dataset.autoHeightValue || '36px'}`;
        const autoHeight = +el.dataset.autoHeight === 1;
        const tuningHeight = +(el.dataset.heightTuning || 0);

        let setBuffer = null;
        let counter = null;
        let info = null;

        ta.css('overflow', 'hidden');
        ta.height(defHeight);
        ta.wrap(`<div class='textarea--wrapper'></div>`);

        const autoSize = () => {
            if (autoHeight === false) {
                return;
            }

            // console.log(`${ta.prop('scrollHeight')}px <000000`);

            ta.css('height', 'auto');
            ta.height(`${+ta.prop('scrollHeight') - tuningHeight}px`);
        };

        const displayCount = (content) => {
            if (withCounter) {
                counter.html(`${content ?? ''}`.length);
            }
        };

        if (withInfo) {
            info = $(`<span x-tippy class="info" data-tippy-content="${ta.data('with-info')}"></span>`);

            Alpine.initTree(info.get(0));

            ta.closest('.textarea--wrapper').append(info);
            ta.toggleClass('show-info', true);
        }

        if (withCounter) {
            counter = $(`<span class="counter"></span>`);
            ta.closest('.textarea--wrapper').append(counter);
            ta.toggleClass('show-counter', true);
        }

        effect(() => {
            data((content) => {
                // console.log('textarea::effect', content);
                ta.val(`${content ?? ''}`);

                displayCount(content);
                autoSize();
            });
        });

        ta.on('input change', () => {
            // console.log('textarea::input::change', el);
            clearTimeout(setBuffer);
            setBuffer = setTimeout(() => {
                evaluate(`${expression} = \`${ta.val()}\``);
                autoSize();
            }, 100);
        });

        cleanup(() => {
            ta.off('input change');
        });
    });
}
