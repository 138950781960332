import { siteSkip } from './api';

import { sleep, buttonFlow } from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('dashboard', () => ({
        async init() {
            await Alpine.store('auth').refresh();
            this.badge.list = this.$site_current.badge;
            this.badge.success = this.badge.list.length >= 5 || this.badge.list.includes(6);
        },

        data: {},

        badge: {
            list: [],
            success: null,
            has(id) {
                return this.list.includes(+id);
            },
        },
        skip: {
            async ['@click']() {
                const ta = $(this.$el);
                const btn = buttonFlow(ta);

                try {
                    btn.loading();
                    const { code } = await siteSkip();
                    if (code === 1) {
                        Alpine.store('router').refresh();
                    }
                    btn.done();
                } catch (error) {
                    console.error('Error subscribe:', error);
                    btn.reset();
                }
            },
        },
    }));
}
