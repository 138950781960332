import { intelligenceStatus, intelligenceSlug } from '../libs/api';
import { buttonFlow } from '../helpers/utils';

export default function (Alpine) {
    const TYPE_SLUG = 'slug';

    Alpine.data('intelligence', (type, value, to) => ({
        loaded: false,

        usage: {
            usage: 0,
            remain: 0,
        },

        text: {
            get title() {
                switch (type) {
                    case TYPE_SLUG:
                        return 'AI 建議網址';
                        break;
                    default:
                        return '';
                }
            },
            get content() {
                switch (type) {
                    case TYPE_SLUG:
                        return '請先填寫專欄名稱，AI 將依據專欄名稱生成網址';
                        break;
                    default:
                        return '';
                }
            },
            get button() {
                switch (type) {
                    case TYPE_SLUG:
                        return '生成網址';
                        break;
                    default:
                        return '';
                }
            },
        },

        suggest: {
            loaded: false,
            result: null,
        },

        async init() {
            if (eval(`typeof TYPE_${type.toUpperCase()} === 'undefined'`) === true) {
                return false;
            }

            console.log(value);

            await this.status();
        },

        async status() {
            const { code, data } = await intelligenceStatus({
                type: 'press',
            });

            this.loaded = true;

            if (code === 1) {
                this.usage = data;
            }
        },

        generate: {
            async ['@click']() {
                const ta = $(this.$el);
                const btn = buttonFlow(ta);
                try {
                    btn.loading();
                    const val = eval(`this.${value}`);

                    if (!$.trim(val)) {
                        btn.reset();
                        window.sendEvent('toast', { title: `${this.text.button}錯誤` });
                        return false;
                    }

                    const {
                        code,
                        data: { usage, result },
                    } = await intelligenceSlug({ type, value: val });

                    btn.done();

                    if (code === 1) {
                        this.usage = usage;
                        this.suggest.result = result;
                        this.suggest.loaded = true;
                    }
                } catch (error) {
                    console.error('Error subscribe:', error);
                    btn.reset();
                }
            },
            [':disabled']() {
                return this.usage.remain === 0;
            },
        },

        title: {
            ['x-text']() {
                return this.text.title;
            },
        },

        content: {
            ['x-text']() {
                return this.text.content;
            },
        },

        remain: {
            ['x-text']() {
                return this.$t('press.ai_tags_remaining', { num: this.usage.remain });
            },
        },

        button: {
            ['x-text']() {
                return this.text.button;
            },
        },

        suggest: {
            ['x-text']() {
                return this.suggest.result.slug;
            },
        },

        use: {
            ['@click']() {
                eval(`this.${to} = '${this.suggest.result.slug}';`);
            },
        },
    }));
}
