import Alpine from 'alpinejs';
import Controller from './controller';

import { customerRequestList, customerRequestGet } from '../libs/api';
import { sleep } from '../helpers/utils';

class Customer extends Controller {
    filterItems = [];

    data() {
        this.filterItems = [
            {
                id: 2,
                name: AlpineI18n.t('press.search_title'),
                type: 'search',
                default: '',
                col: 'title',
            },
            {
                id: 1,
                name: '文章狀態',
                type: 'select',
                col: 'status',
                default: 'all',
                items: [
                    { id: 'all', name: AlpineI18n.t('customer.all_messages') },
                    { id: 1, name: AlpineI18n.t('customer.new_messages') },
                    { id: 2, name: AlpineI18n.t('customer.read') },
                ],
            },
        ];

        return {
            init() {
                const id = this.id ? +this.id : null;

                this.request.data.id = id;
            },

            list: {
                init() {
                    this.get();
                },
                data: {},
                async get() {
                    const query = Alpine.store('query');

                    const {
                        code,
                        data: { rows: list },
                    } = await customerRequestList(query.fetch());

                    this.data = list;
                },
            },
            request: {
                init() {
                    this.get();
                },
                loaded: false,
                copied: false,
                data: {},
                async get() {
                    const { code, data } = await customerRequestGet({ request_id: this.data.id });

                    this.data = { ...this.data, ...data };
                    this.loaded = true;
                },

                reply() {
                    sendEvent('modal', {
                        html: '/pages/customer/mailto.html',
                    });
                },
                copy(input, callback) {
                    this.copied = true;

                    callback.call();

                    sleep(3000).then(() => {
                        this.copied = false;
                    });
                },
            },
        };
    }
}

export const customer = new Customer();
