// import SlimSelect from 'slim-select';

// TODO: deleted

export const registerSelectHelper = (alpine) => {
	alpine.directive(
		'select',
		(
			el,
			{ value, modifiers, expression },
			{ Alpine, evaluate, evaluateLater, effect, cleanup }
		) => {
			// const defaults = evaluateLater(expression);
			// const ta = $(el);

			// const select = new SlimSelect({
			// 	select: el,
			// 	closeOnSelect: false,
			// 	allowDeselect: true,
			// 	searchingText: '搜尋...',
			// 	// onChange: (info) => {
			// 	// 	const ids = select.selected();
			// 	// 	if (ta.is('[multiple]')) {
			// 	// 		evaluate(`${expression}s = [${ids.join(',')}]`);
			// 	// 	} else {
			// 	// 		evaluate(`${expression} = ${ids}`);
			// 	// 	}
			// 	// },

			// 	// ajax: async function (search, callback) {
			// 	// 	await Alpine.store('api')
			// 	// 		.getClient()
			// 	// 		.get(`${value}/list`)
			// 	// 		.then((result) => {
			// 	// 			const data = result?.data?.rows.map((site) => {
			// 	// 				if (value === 'tag') {
			// 	// 					const lang = site.lang.find((lang) => lang.lang === 'tw');
			// 	// 					return { text: `${lang.title}`, value: +site._id };
			// 	// 				}

			// 	// 				return value === 'site'
			// 	// 					? { text: `${site.name} ${site.slug}`, value: +site._id }
			// 	// 					: value === 'place'
			// 	// 					? { text: `${site.name}`, value: +site._id }
			// 	// 					: value === 'service'
			// 	// 					? { text: `${site.name}`, value: +site._id }
			// 	// 					: value === 'template'
			// 	// 					? { text: `${site.name}`, value: +site._id }
			// 	// 					: {};
			// 	// 			});
			// 	// 			callback(data);
			// 	// 			// self.settings.load = null;
			// 	// 		})
			// 	// 		.catch((error) => {
			// 	// 			callback(false);
			// 	// 		});
			// 	// },
			// });

			// effect(async () => {
			// 	defaults((set) => {
			// 		if (set) {
			// 			select.ajax('', (result) => {
			// 				select.setData(result);

			// 				if (ta.is('[multiple]')) {
			// 					const setArray = JSON.parse(JSON.stringify(set));
			// 					const ids = setArray.map((set) => {
			// 						return set._id;
			// 					});

			// 					select.set(ids);
			// 				} else {
			// 					select.set(set);
			// 				}
			// 			});
			// 		}
			// 	});
			// });

			// cleanup(() => {
			// 	select.destroy();
			// 	el.remove();
			// });
			// // }
		}
	);
};
