import Alpine from 'alpinejs';
import Controller from './controller';

class Site extends Controller {
	data() {
		const auth = Alpine.store('auth');
		return {
			permissionsSet: [],
			title: '網站設定',
			basePermissions: auth?.user.base_permissions,
			site: {
				name: '',
				domain: [],
			},
			all: this.all,
			sort: this.all,
			get: this.get,
			save: this.save,
		};
	}

	async all() {
		const query = Alpine.store('query');

		const result = await Alpine.store('api').getClient().post('site/list', { query: query.fetch() });
		this.site = result.data?.rows;

		const meta = {
			pos: +result.data.pos,
			count: +result.data.count,
			total: +result.data.total,
		};

		query.midify(meta);
		this.paginate.init(meta);
	}

	async get() {
		const info = Alpine.store('info');

		if (info.id == 0) {
			return false;
		}

		const result = await Alpine.store('api').getClient().post('site/get', { id: info.id });
		this.site = (({ domain, name, slug, service_id, _id }) => ({ domain, name, slug, service_id, _id }))(
			result.data
		);
		this.title += ' - ' + this.site.name;
	}

	async save() {
		const info = Alpine.store('info');
		const result = await Alpine.store('api').getClient().post('site/save', info.site);

		if ('code' in result && result.code === 1) {
			Alpine.store('router').go('site');

			return;
		}
	}
}

export const site = new Site();
