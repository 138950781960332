import { sleep } from './utils';

export const registerUploadHelper = (alpine) => {
	alpine.directive('upload', (el, { value, expression, modifiers }, { evaluate, evaluateLater, effect, cleanup }) => {
		const form = $(el);
		const button = form.find('button');
		const input = form.find('input');
		const url = `element/upload/${value}`;

		console.info(
			'Upload::init',
			el,
			{ value, expression, modifiers },
			{ evaluate, evaluateLater, effect, cleanup }
		);

		button.on('click', function (e) {
			e.preventDefault();

			input.click();
		});

		input.on('change', async function (e) {
			let files = Object.values(e.target.files);
			let processed = [];
			let result = [];

			window.sendEvent('toast', {
				title: `已選擇：${files.length} 個檔案`,
				message: `上傳中... (${files.map((file) => file.name).join(', ')})`,
			});

			const api = Alpine.store('api').getClient();
			// const queue = ;

			const results = await Promise.all(files.map((file) => api.postForm(url, { file: file }).catch((e) => e)));
			const validResults = results.filter((result) => !result.isError);

			input.val(''); // reset input

			if (validResults.length !== files.length) {
				// error
			} else {
				// success
				window.sendEvent('toast', {
					title: `已上傳完成。`,
					message: `已上傳 ${files.length} 個檔案`,
				});

				if (modifiers.includes('refresh')) {
					sleep(650).then(() => {
						alpine.store('router').refresh();
					});
				} else {
					evaluate(`${expression} = '${validResults[0]?.data?.path}'`);
				}
			}
		});

		cleanup(() => {
			button.off('click');
			input.off('change');
		});
	});
};
