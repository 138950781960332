import feather from 'feather-icons';
import Alpine from 'alpinejs';
import { pick } from '../helpers/utils';

export default class Controller {
	isLoading = false;
	filterItems = [{ id: 1, name: '編號', type: 'int', col: 'id' }];

	data() {
		return {};
	}

	filter() {
		const query = Alpine.store('query');

		return {
			current: null,
			items: this.filterItems,
			init() {
				const settings = query.fetch();

				this.current = settings.query || {};

				console.log('filter::init', this.current);
			},
			query(id, value, callback = () => {}) {
				let settings = query.fetch();

				const item = this.items.find((item) => +item.id === +id) || null;
				if (item === null) {
					return false;
				}

				settings.query = settings.query ? settings.query : {};

				let toSave = pick(item, ['id', 'name', 'type', 'col']);
				settings.query[item.col] = { ...toSave, value, active: false };

				if (`${value}`.trim() !== '' && value !== 'all') {
					settings.query[item.col].active = true;
				} else {
					settings.query[item.col].active = false;
				}

				settings.pos = 0;

				query.update(settings);

				this.refresh(callback);
			},

			active(col = null) {
				const current = this.current;

				if (col !== null) {
					return current[col]?.active === true;
				}

				return Object.values(current).every((i) => i.active === false);
			},

			refresh(callback) {
				const settings = query.fetch();
				const info = Alpine.store('info');

				if (typeof info.list !== 'undefined') {
					info.list.get();
				} else {
					callback.apply();
				}

				this.current = settings.query || {};
			},
		};
	}

	paginate() {
		const query = Alpine.store('query');

		return {
			meta: {
				pos: 0,
				count: 1,
				total: 0,
			},
			changePos(pos) {
				this.pos = +pos;

				console.log('paginate::changePos', +pos);

				query.midify({ pos: this.pos });

				const info = Alpine.store('info');
				info.list.get();
			},
			init(meta) {
				if (!meta) {
					return false;
				}

				console.log('paginate::init', meta);

				this.meta = meta;
			},
		};
	}

	preload(_$routeParams) {
		console.log('controller::preload', _$routeParams);

		Alpine.store('info', {
			..._$routeParams,
			...this.data(_$routeParams),
			filter: this.filter(),
			paginate: this.paginate(),
		});
	}

	run(_$routeParams) {}

	// getSort() {
	// 	const route = Alpine.store('router').current();
	// 	const key = `query_${route.hashString}`;

	// 	if (localStorage) {
	// 		let saved = localStorage.getItem(key);
	// 		saved = saved === null ? '{}' : saved;

	// 		saved = JSON.parse(saved);

	// 		return saved;
	// 	} else {
	// 		window.sendEvent('toast', {
	// 			title: `Error!!`,
	// 			message: 'localStorage not supported',
	// 		});
	// 	}

	// 	return {};
	// }
}
