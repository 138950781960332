import {
    planChoose,
    planChange,
    planUnsubscribe,
    planSubscribe,
    sitePayment,
    sitePublish,
    siteRetry,
} from '../libs/api';
import { sleep, buttonFlow } from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('plan', () => ({
        data: {
            id: null,
            current: null,

            name: '',
            address: '',
            mail: '',

            invoice: {
                mode: 1,
                donation: {
                    code: '0958',
                },
                member: {
                    mode: 1,
                    barcode: '',
                    natural: '',
                },
                company: {
                    tax: '',
                    name: '',
                },
            },

            payment: 1,
        },

        describe: {
            1: {
                icon: 'bi-tornado',
                title: {
                    tw: '寧斯科技專用',
                    en: 'NINGS',
                },
                price: 299,
                helper: '全功能',
                func: [
                    '很多的功能',
                ],
                member: 9999,
            },
            2: {
                icon: 'bi-signpost-2',
                title: {
                    tw: '探索者',
                    en: 'STARTER',
                },
                price: 299,
                helper: '該方案享有的功能權限',
                func: [
                    '歷史版本紀錄（10 則，保留 7 天）',
                    '所見即所得編輯器',
                    'SEO 自動化功能',
                    '免費版型套用',
                    '產品客服 - 後台回報',
                    '網站數據分析',
                    '會員管理系統 CRM',
                ],
                member: 500,
            },
            3: {
                icon: 'bi-send',
                title: {
                    tw: '啟航者',
                    en: 'CREATOR',
                },
                price: 799,
                helper: '包含所有探索者的功能，加上',
                func: [
                    '歷史版本紀錄（100 則，保留 14天 ）',
                    '單次文件上傳限制（100MB）',
                    '電子商務功能',
                    '整合社群行銷（一鍵分享）',
                    '讀者/會員客服管理',
                    '電子報行銷（4,000封 / 月）',
                ],
                member: 1000,
            },
            4: {
                icon: 'bi-train-freight-front',
                title: {
                    tw: '領航者',
                    en: 'TEAM+',
                },
                price: 1599,
                helper: '包含所有啟航者的功能，加上',
                func: [
                    '歷史版本紀錄（500 則，保留 21 天）',
                    '單次文件上傳限制（200MB）',
                    '平台客服 - 優先服務',
                    '網站搬遷服務',
                ],
                member: 1000,
            },
            5: {
                icon: 'bi-rocket',
                title: {
                    tw: '旗艦隊伍',
                    en: 'BUSINESS',
                },
                price: 6399,
                helper: '包含所有領航者的功能，加上',
                func: [
                    '歷史版本紀錄（1000 則，保留 60 天）',
                    '單次文件上傳限制（300MB）',
                    '電子報行銷（40,000封 / 月）',
                    '平台客服 - 客戶經理專門服務',
                ],
                member: 2999,
            },
        },

        payment: {
            loaded: false,
            data: {},
            async load() {
                const { code, data } = await sitePayment();

                if (code === 1) {
                    this.data = data;
                }

                this.loaded = true;
            },
        },

        init() {
            const { data } = Alpine.store('info');
            this.data.id = data.id || null;
            const { site } = Alpine.store('auth');
            this.data.current = +site.current.service.id || null;
        },

        async submit() {
            sendEvent('modal', {
                title: '付款處理中',
                html: '/pages/plan/alert-payment.html',
            });

            try {
                const {
                    code,
                    data: { redirect_url },
                } = await planChoose(this.data);

                if (code === 1) {
                    const strWindowFeatures = 'location=yes,height=570,width=520,scrollbars=yes,status=yes';
                    const win = window.open(redirect_url, '_blank', strWindowFeatures);

                    win.focus();

                    const messageCallback = function (e) {
                        const payload = e.originalEvent.data; // Should work.

                        clearInterval(timer);
                        win.close();

                        $(window).off('message', messageCallback);

                        if (payload) {
                            if (payload.success) {
                                sleep(5000).then(() => {
                                    Alpine.store('modal').close();

                                    sleep(5).then(() => {
                                        Alpine.store('auth').refresh();

                                        sendEvent('modal', {
                                            title: '方案購買成功',
                                            html: '/pages/plan/alert-payment-succeed.html',
                                        });
                                    });
                                });
                            } else {
                                sleep(1000).then(() => {
                                    Alpine.store('modal').close();

                                    sleep(5).then(() => {
                                        sendEvent('modal', {
                                            title: '付款失敗',
                                            html: '/pages/plan/alert-payment-failed.html',
                                            closeBtn: true,
                                        });
                                    });
                                });
                            }
                        }
                    };

                    const timer = setInterval(function () {
                        if (win.closed) {
                            clearInterval(timer);

                            $(window).off('message', messageCallback);

                            Alpine.store('modal').close();

                            sleep(5).then(() => {
                                sendEvent('modal', {
                                    title: '付款失敗',
                                    html: '/pages/plan/alert-payment-failed.html',
                                    closeBtn: true,
                                });
                            });
                        }
                    }, 500);

                    $(window).off('message').on('message', messageCallback);
                    window.sendEvent('validation::done');
                }
            } catch (e) {
                window.sendEvent('validation::done');
                Alpine.store('modal').close();

                sleep(5).then(() => {
                    sendEvent('modal', {
                        title: '付款失敗',
                        html: '/pages/plan/alert-payment-failed.html',
                        closeBtn: true,
                    });
                });
            }
        },

        choose: {
            ['@click']() {
                const ta = $(this.$el);
                const id = +ta.data('id');

                Alpine.store('router').go(`/plan/${id}`);
            },
        },

        more: {
            ['@click']() {
                const router = Alpine.store('router');
                Alpine.store('modal').close();

                router.go('/plan');
            },
        },

        close: {
            ['@click']() {
                Alpine.store('modal').close();

                Alpine.store('router').go('/plan/current');
            },
        },

        publish: {
            async ['@click']() {
                const { code, data } = await sitePublish();
                if (code === 1) {
                    Alpine.store('auth').refresh();
                    Alpine.store('modal').close();
                }
            },
        },

        change: {
            async ['@click']() {
                const ta = $(this.$el);
                const force = +ta.data('force') === 1;

                if (force === true) {
                    const { code, data } = await planChange({
                        id: this.data.id,
                    });
                    if (code === 1) {
                        Alpine.store('modal').close();
                        Alpine.store('auth').refresh();

                        Alpine.store('router').go('/plan/current');
                    }
                } else {
                    sendEvent('modal', {
                        title: '你確定要更換方案嗎？',
                        html: '/pages/plan/confirm-change.html',
                        closeBtn: true,
                    });
                }
            },
        },

        unsubscribe: {
            async ['@click']() {
                const ta = $(this.$el);
                const force = +ta.data('force') === 1;

                if (force === true) {
                    const { code, data } = await planUnsubscribe();
                    if (code === 1) {
                        // Alpine.store('modal').close();
                        // Alpine.store('auth').refresh();

                        Alpine.store('router').refresh();
                    }
                } else {
                    sendEvent('modal', {
                        title: '取消訂閱',
                        html: '/pages/plan/confirm-unsubscribe.html',
                        closeBtn: true,
                    });
                }
            },
        },

        subscribe: {
            async ['@click']() {
                const ta = $(this.$el);
                const force = +ta.data('force') === 1;
                const btn = buttonFlow(ta);

                if (force === true) {
                    try {
                        btn.loading();
                        const { code, data } = await planSubscribe();
                        if (code === 1) {
                            // Alpine.store('modal').close();
                            // Alpine.store('auth').refresh();
                            Alpine.store('router').refresh();
                        }
                        btn.done();
                    } catch (error) {
                        console.error('Error subscribe:', error);
                        btn.reset();
                    }
                } else {
                    sendEvent('modal', {
                        title: '再次訂閱',
                        html: '/pages/plan/confirm-subscribe.html',
                        closeBtn: true,
                    });
                }
            },
        },

        retry: {
            async ['@click']() {
                const ta = $(this.$el);
                const btn = buttonFlow(ta);

                try {
                    btn.loading();
                    const { code, data } = await siteRetry();
                    if (code === 1) {
                        // Alpine.store('modal').close();
                        // Alpine.store('auth').refresh();
                        Alpine.store('router').refresh();
                    }
                    btn.done();
                } catch (error) {
                    console.error('Error subscribe:', error);
                    btn.reset();
                }
            },
        },
    }));
}
