export default function (Alpine) {
    Alpine.directive('altcha', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const container = $(el);

        if (container.is('.altcha-active') === true) {
            return false;
        }

        const altcha = $('<altcha-widget />')
            .attr('id', 'altcha')
            .attr('challengeurl', `${import.meta.env.VITE_API_BASE}api/challenge?v=${window.location.hostname}`)
            .attr('auto', 'onload')
            .attr('hidefooter', true)
            // .attr('debug', true)
            .attr(
                'strings',
                '{"error":"驗證錯誤，請稍候再試","label":"我不是機器人","verified":"驗證成功","verifying":"驗證中...","waitAlert":"驗證中，請稍候..."}',
            );

        altcha.on('statechange', (ev) => {
            if (ev.detail.state === 'verified') {
                evaluate(`${expression} = '${ev.detail.payload}'`);
            }
        });

        $(window)
            .off('altcha::verify')
            .on('altcha::verify', () => {
                altcha[0].verify();
            });

        container.append(altcha);
    });
}
