import {} from '../libs/api';
import { get } from '../helpers/utils';

export default function (Alpine) {
    const filterTemplate = `
        <template x-for="rule in rules.items">
            <template x-if="['select', 'search'].includes(rule.type)">
                <div class="col-auto filter__item">
                    <template x-if="rule.type === 'select'">
                        <select class="form-select" x-bind="select">
                            <template x-for="option in rule.items">
                                <option x-bind="select_option"></option>
                            </template>
                        </select>
                    </template>
                    <template x-if="rule.type === 'search'">
                        <div class="filter--text">
                            <input type="text" class="form-control" x-bind="input">
                        </div>
                    </template>
                </div>
            </template>
        </template>
    `;

    Alpine.data('filter', (rules, callback = () => {}) => ({
        rules: null,
        init() {
            $(this.$el).html(filterTemplate);

            rules.init();

            this.rules = rules;
        },

        select: {
            ['@change']() {
                rules.query(this.rule.id, this.$event.target.value, callback);
            },
        },

        select_option: {
            [':value']() {
                return this.option.id;
            },
            ['x-text']() {
                return this.option.name;
            },
            [':selected']() {
                if (rules.current === null) {
                    return false;
                }

                const selected = +get(rules.current, `${this.rule.col}.value`, this.rule.default);
                return selected === +this.option.id;
            },
        },

        input: {
            [':placeholder']() {
                return this.rule.name;
            },
            [':value']() {
                if (rules.current === null) {
                    return null;
                }

                const selected = get(rules.current, `${this.rule.col}.value`, this.rule.default);
                return selected;
            },
            ['@change']() {
                rules.query(this.rule.id, this.$event.target.value, callback);
            },
        },
    }));
}
