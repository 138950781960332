import EditorJS from '@editorjs/editorjs';
import Undo from 'editorjs-undo';

import draftConfig from './draft.config';

export default function (Alpine) {
    Alpine.data('draft', (post, onSave = () => {}) => ({
        editor: null,
        init() {
            this.editor = new EditorJS({
                ...draftConfig(AlpineI18n),
                holder: this.$el,

                onReady: () => {
                    const obj = JSON.parse(post);
                    const undo = new Undo({ editor: this.editor });

                    if (obj && typeof obj === 'object') {
                        this.editor.blocks.render(obj).then(() => {
                            undo.initialize(obj);
                        });
                    }

                    console.debug('draft::editor::onReady', obj);
                },

                onChange: async (api, event) => {
                    if (typeof onSave !== 'function') return;

                    const output = await api.saver.save();

                    console.log('onChange', output, onSave);
                    onSave.apply(null, [output]);
                },
            });
        },
    }));
}
