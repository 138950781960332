import Alpine from 'alpinejs';
import Controller from './controller';

import { themeList, themeApply } from '../libs/api';
import { sleep, buttonFlow } from '../helpers/utils';

class Theme extends Controller {
    data(params) {
        return {
            title: '主題樣式',
            list: {
                loaded: false,
                data: {
                    rows: [],
                },
                init() {
                    this.load();
                },
                async load() {
                    const { code, data } = await themeList();

                    if (code === 1) {
                        this.data.rows = data.rows;
                        this.loaded = true;
                    }
                },

                async apply(id) {
                    const { code, data } = await themeApply({ id });
                    if (code === 1) {
                        await this.load();
                        // this.data.rows = data.rows;
                        // this.loaded = true;
                    }
                },
            },
        };
    }
}
export const theme = new Theme();
