import Swiper, { Navigation, Pagination } from 'swiper';

export default function (Alpine) {
    Alpine.directive('swiper', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const selected = el.dataset.selected;
        const perView = el.dataset.perView || 1;

        console.log(el.dataset, '<--');

        const selectedCallback = (idx) => {
            if (selected === '') {
                return false;
            }

            const callback = evaluate(selected);

            if (typeof callback === 'function') {
                callback(idx);
            }
        };

        let options = {
            modules: [Navigation, Pagination],

            slidesPerView: perView,
            spaceBetween: 30,
            centeredSlides: true,

            mousewheel: {
                enabled: true,
                forceToAxis: true,
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                init: (sw) => selectedCallback(sw.activeIndex),
            },
        };

        console.log('swiper::init', options)

        const swiper = new Swiper(el, options);

        swiper.on('slideChange', (sw) => selectedCallback(sw.activeIndex));

        cleanup(() => {
            swiper.detachEvents();
            swiper.destroy(true);
        });
    });
}
