import Alpine from 'alpinejs';
import Controller from './controller';
import ShortUniqueId from 'short-unique-id';

import { logisticsList, logisticsSave, logisticsGet } from '../libs/api';
// import {  } from '../helpers/utils';

class Logistics extends Controller {
    filterItems = [];

    data() {
        this.filterItems = [
            {
                id: 2,
                name: '搜尋物流關鍵字',
                type: 'search',
                default: '',
                col: 'title',
            },
            {
                id: 1,
                name: '物流狀態',
                type: 'select',
                col: 'status',
                default: 'all',
                items: [
                    { id: 'all', name: '所有狀態' },
                    { id: 1, name: '已上架' },
                    { id: 0, name: '已下架' },
                ],
            },
        ];

        return {
            init() {
                const id = +this.id;
                if (id !== 0) {
                    this.item.data.id = id;
                    this.item.mode = 'edit';
                }
            },
            list: {
                loaded: false,
                data: {},
                async init() {
                    await this.get();
                },
                async get() {
                    const query = Alpine.store('query');

                    const { code, data } = await logisticsList({ query: query.fetch() });

                    if (code === 1) {
                        this.data = data;
                        this.loaded = true;
                    }
                },
            },

            item: {
                mode: 'new',
                loaded: false,
                data: {
                    id: null,
                    content: '',
                    fee: 0,
                    free_threshold: 0,
                    name: '',
                    description: '',
                    status: 'offline',
                },

                async init() {
                    if (this.mode === 'new') {
                        const generator = new ShortUniqueId();

                        this.loaded = true;
                        this.data.id = generator();
                    } else {
                        await this.load();
                    }
                },

                async load() {
                    const { code, data } = await logisticsGet({ id: this.data.id });

                    if (code === 1) {
                        this.data = data;
                    }

                    this.loaded = true;
                },

                async save() {
                    const {
                        code,
                        data: { id },
                    } = await logisticsSave({
                        ...this.data,
                    });

                    window.sendEvent('validation::done');

                    if (code === 1) {
                        const generator = new ShortUniqueId();

                        Alpine.store('router').go(`/logistics/${id}?t=${generator()}`);
                    }
                },
            },
        };
    }
}

export const logistics = new Logistics();
