import { verifyOTP, resendOTP } from '../libs/api';
import { sleep } from '../helpers/utils';

export const registerVerifyService = (alpine) => {
	alpine.store('verify', {
		token: null,
		ts: 0,

		length: 6,
		input: [],
		status: false,

		data: {},

		resendStatus: {
			enable: false,
			timer: null,
			mode: 'new',
			count: 120,
			text: '已重新發送 (120s)',
		},
		callback: null,
		init() {
			const that = this;

			clearTimeout(that.resendStatus.timer);
			that.resendStatus.timer = setTimeout(() => {
				that.resendStatus.enable = true;
			}, 10 * 1000);
		},

		async resend() {
			const that = this;
			this.resendStatus.mode = 'pending';

			const { code, data } = await resendOTP({ token: this.token });
			if (code === 1 && data.status === true) {
				this.resendStatus.mode = 'done';

				clearInterval(that.resendStatus.timer);
				that.resendStatus.timer = setInterval(() => {
					that.resendStatus.count -= 1;
					that.resendStatus.text = `已重新發送 (${that.resendStatus.count}s)`;

					if (that.resendStatus.count <= 0) {
						clearInterval(that.resendStatus.timer);
						that.resendStatus.mode = 'new';
					}
				});

				// setTimeout(() => {

				// 	// this.resendStatus.mode = 'new';
				// }, 2000);
			} else {
				window.sendEvent('toast', { title: '驗證碼發送頻繁，請稍候再試。' });
				this.resendStatus.mode = 'new';
			}
		},
		// // this will only check when inputing the last number
		// // usefull if you're going to limit number of checks
		// onlyCheckOnLastFieldInput: true,
		// paste(event) {
		// 	// raw pasted input
		// 	let pasted = event.clipboardData.getData('text');
		// 	// only get numbers
		// 	pasted = pasted.replace(/\D/g, '');
		// 	// don't get more than the PIN length
		// 	pasted = pasted.substring(0, this.length);
		// 	// if after all that sanitazation the string is not empty
		// 	if (pasted) {
		// 		// split the pasted string into an array and load it
		// 		this.input = pasted.split('');
		// 		// check if the PIN is correct
		// 		this.check();
		// 	}
		// },
		// type(event, index, cb) {
		// 	if (event.ctrlKey && event.key == 'v') {
		// 		console.log('ctrl-v');
		// 	} else if (event.keyCode == 8) {
		// 		event.stopPropagation();
		// 		event.preventDefault();
		// 		this.input[index - 1] = 0;
		// 	} else {
		// 		// only allow numbers
		// 		let key = event.key.replace(/\D/g, '');
		// 		if (key != '') {
		// 			this.input[index - 1] = key;
		// 		}
		// 	}
		// 	// check if the PIN is correct
		// 	if ((this.onlyCheckOnLastFieldInput && index == this.length) || !this.onlyCheckOnLastFieldInput) {
		// 		this.check();
		// 	}

		// 	cb();
		// },
		// goto(n) {
		// 	if (!n || n > this.length) {
		// 		n = 1;
		// 	}
		// 	let el = document.querySelector(`input[name=pin${n}]`);
		// 	el.focus();
		// },
		check(el) {
			const input = $(el);

			const content = input.val();
			if (content != '' && content.length === this.length) {
				this.status = true;
				this.verifying(content);
			} else {
				this.status = false;
			}
		},

		display(token, timeUp, data = {}, title = '', callback = () => {}) {
			this.token = token;
			this.ts = timeUp;

			if (typeof callback === 'function') {
				this.callback = callback;
			} else {
				this.callback = () => {};
			}

			this.data = data;

			console.log('verify::display', token, timeUp, data, callback, this.callback);

			window.sendEvent('modal', {
				html: '/pages/verify/email.html',
				title: title,
				closeBtn: true,
			});
		},

		async verifying(pincode) {
			const that = this;
			const modal = alpine.store('modal');
			const { code, data } = await verifyOTP({
				pincode: +pincode,
				token: this.token,
			});

			if (code === 1 && data.status === true) {
				sleep(5000).then(() => {
					that.token = null;
					that.ts = 0;
					that.status = false;
					// that.input = [];

					that.callback.apply(null, [data]);
					that.callback = () => {};

					modal.close();
				});
			} else {
				window.sendEvent('toast', { title: '驗證碼輸入錯誤' });
				this.status = false;
			}
		},
	});
};
