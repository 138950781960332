import Alpine from 'alpinejs';
import Controller from './controller';
// import ShortUniqueId from 'short-unique-id';

// import { goodsList, goodsSave, goodsGet, tagList, intelligenceTagSuggest } from '../libs/api';
// import { uploadProgress, pluck } from '../helpers/utils';

class Subscription extends Controller {
    data() {
        this.filterItems = [
            {
                id: 1,
                name: '搜尋',
                type: 'search',
                default: '',
                col: 'title',
            },
        ];

        return {
            data: {},
            init() {
                const current = Alpine.store('router').current();

                if (current.params && current.params.type) {
                    this.data.type = current.params.type;
                }
            },
        };
    }
}

export const subscription = new Subscription();
