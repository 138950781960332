import { DateTime } from 'luxon';
import { isPossiblePhoneNumber, parsePhoneNumberFromString as parsePhoneNumber } from 'libphonenumber-js';

import { url, slug, get } from './utils';

export const registerMagic = (alpine) => {
	const site_url = (str) => {
		const site = alpine.store('auth')?.site?.current?.domain ?? '';
		return url(`https://${site}`, str);
	};

	window.site_url = site_url;

	alpine.magic('site_url', () => {
		return (str) => site_url(str);
	});

	alpine.magic('asset', () => {
		return (asset) => url(import.meta.env.VITE_CDN_BASE, asset);
	});

	alpine.magic('web', () => {
		return (uri) => url(import.meta.env.VITE_WEB_URL, uri);
	});

	alpine.magic('web_domain', () => {
		return () => {
			let domain = new URL(import.meta.env.VITE_WEB_URL);
			return domain.hostname;
		};
	});

	alpine.magic('clearBreaks', () => {
		return (str) => {
			if (typeof str !== 'string') {
				return '';
			}

			return `${str.replace(/(\r\n|\n|\r)/gm, '')}`;
		};
	});

	alpine.magic('hideEmail', () => {
		return (email) => {
			return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
				for (let i = 0; i < gp3.length; i++) {
					gp2 += '*';
				}
				return gp2;
			});
		};
	});

	alpine.magic('slug', () => {
		return (text) => slug(text);
	});

	alpine.magic('url', () => {
		return (base, path, params = {}) => {
			return url(base, path, params);
		};
	});

	alpine.magic('nextMonth', () => {
		return () => {
			return DateTime.now().plus({ months: 1, days: 1 }).toFormat('yyyy/MM/dd（ccc）', { locale: 'zh-TW' });
		};
	});

	alpine.magic('now', () => {
		return (fmt = 'yyyy-MM-dd HH:mm:ss') => {
			return DateTime.now().toFormat(fmt);
		};
	});

	alpine.magic('nowWithTZ', () => {
		return (fmt = 'yyyy-MM-dd HH:mm:ss', tz = 'Asia/Taipei') => {
			return DateTime.now().setZone(tz).toFormat(fmt);
		};
	});

	alpine.magic('tsFormat', () => {
		return (ts, fmt = 'yyyy-MM-dd') => {
			return DateTime.fromSQL(ts).toFormat(fmt);
		};
	});

	alpine.magic('tsAfterNow', () => {
		return (start, other) => {
			return DateTime.fromSQL(start) > DateTime.now();
		};
	});

	alpine.magic('cond', () => {
		return (value, def) => {
			console.log(
				'magic::cond',
				value,
				def,
				'result',
				typeof value === 'undefined',
				typeof value === 'undefined' ? def : true,
			);
			return typeof value === 'undefined' ? def : true;
		};
	});

	alpine.magic('get', () => {
		return (obj, path = '', def = undefined) => {
			console.log('magic::get', obj, path, 'result', get(obj, path, def));
			return get(obj, path, def);
		};
	});

	alpine.magic('length', () => {
		return (aray) => {
			if (Array.isArray(aray)) {
				return aray.length;
			}

			return false;
		};
	});

	alpine.magic('formatMoney', () => {
		return (amount, decimalCount = 0, decimal = '.', thousands = ',') => {
			try {
				decimalCount = Math.abs(decimalCount);
				decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

				const negativeSign = amount < 0 ? '-' : '';

				let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
				let j = i.length > 3 ? i.length % 3 : 0;

				return (
					negativeSign +
					(j ? i.substr(0, j) + thousands : '') +
					i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
					(decimalCount
						? decimal +
							Math.abs(amount - i)
								.toFixed(decimalCount)
								.slice(2)
						: '')
				);
			} catch (e) {
				return amount;
			}
		};
	});

	alpine.magic('formatTel', () => {
		return (str) => {
			if (isPossiblePhoneNumber(str, 'TW')) {
				const phoneNumber = parsePhoneNumber(str, 'TW');
				return phoneNumber.formatNational();
			} else {
				return str;
			}
		};
	});

	/*-1:Invalid;0:New;1:Paid;2:Shipped;3:Done;*/

	alpine.magic('formatStatus', () => {
		return (str) => {
			const tranxStatus = {
				0: '建立訂單',
				1: '完成付款',
				2: '完成出貨',
				3: '完成訂單',
				'-1': '訂單失效',
			};
			return tranxStatus[str];
		};
	});

	alpine.magic('paginate', () => {
		return (current, page2Show, totalPage) => {
			let pages = [];

			const start = Math.max(+current - Math.floor(+page2Show / 2), 1);
			const end = Math.min(start + +page2Show - 1, +totalPage);

			if (start > 1) {
				pages.push(1);
				if (start > 2) {
					pages.push('...');
				}
			}

			for (let i = start; i <= end; i++) {
				pages.push(i);
			}

			if (end < +totalPage) {
				if (end < +totalPage - 1) {
					pages.push('...');
				}
				pages.push(+totalPage);
			}

			console.log(pages, '==========================================');

			return pages;
		};
	});
};

/*
function convertToSlug(Text) {
  return Text.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
}

*/
