import { sleep } from '../helpers/utils';

export default function (Alpine) {
    const toggleContent = (toggle, target, show = true) => {
        // const isShow = target.is('.show');

        if (show === false) {
            // close
            toggle.toggleClass('collapsed', true);
            toggle.attr('aria-expanded', 'false');
            target.toggleClass('show', false);
            target.toggleClass('collapse', true);
        } else {
            // open
            toggle.toggleClass('collapsed', false);
            toggle.attr('aria-expanded', 'true');
            target.toggleClass('collapse show', true);
        }
    };

    Alpine.directive('collapse', (el, { modifiers }, { cleanup }) => {
        const toggle = $(el);
        const selector = toggle.data('bs-target');

        toggle.on('click', function (e) {
            e.preventDefault();

            const target = $(`${selector}`);
            toggleContent(toggle, target, !target.is('.show'));
        });

        cleanup(() => {
            toggle.off('click');
        });
    });
}
