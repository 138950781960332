import Iodine from '@caneara/iodine';

import { sitePublish } from '../libs/api';
import { sleep } from '../helpers/utils';

export default function (Alpine) {
    // const goBackToProfile = () => {
    //     Alpine.store('router').go('profile');
    // };

    // const

    Alpine.data('setting', () => ({
        site: {
            status: 0,
            with: {
                service: false,
            },
            service: {
                name: '',
            },

            async publish(status = true) {
                const { code, data } = await sitePublish({
                    publish: status,
                });
                if (code === 1) {
                    Alpine.store('auth').refresh();
                    Alpine.store('modal').close();
                }
            },
        },

        init() {
            const {
                site: { current: site },
            } = Alpine.store('auth');

            this.site.status = !!site.status;
            this.site.with.service = +site.service.id !== 0;
            this.site.service.name = site.service.name;
        },

        service: {
            ['x-text']() {
                return this.site.with.service ? '了解方案' : '立即訂閱';
            },
            ['@click']() {
                if (this.site.with.service === true) {
                    Alpine.store('router').go('plan/current');
                } else {
                    Alpine.store('router').go('plan');
                }
            },
        },

        service_status: {
            ['x-text']() {
                return this.site.service.name || '未訂閱';
            },
        },
        site_status: {
            async ['@change']() {
                const ta = $(this.$el);
                await this.site.publish(ta.is(':checked'));
            },
        },
        site_publish: {
            async ['@click']() {
                await this.site.publish(true);
            },
        },
    }));
}
