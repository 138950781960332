import {
    subscriptionInfo,
    subscriptionPlanSave,
    subscriptionContentSave,
    subscriptionPlanGet,
    subscriptionStatus,
} from '../libs/api';
import { buttonFlow, uploadProgress } from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('subscription', (id = null) => ({
        async init() {
            if (id === null) {
                await this.load();
                // } else if (+id > 0) {
            } else {
                await this.item.load(id);
            }
        },

        loaded: false,

        data: {
            subscription: [],
            content: '',
            status: 0,
            able: false,
        },

        async load() {
            const { code, data } = await subscriptionInfo();

            this.loaded = true;

            if (code === 1) {
                this.data = data;
            }
        },

        update() {
            const that = this;
            return (content) => {
                console.log('update', content);
                that.data.content = content;
            };
        },

        item: {
            loaded: false,

            data: {
                name: '',
                price: '',
                content: '',
                cover: null,
                freq: 1,
                func: ['1'],
                status: 0,
            },

            display(id, onClose = () => {}) {
                sendEvent('modal', {
                    title: +id === 0 ? '新增方案' : '編輯方案',
                    html: '/pages/subscription/item.html',
                    closeBtn: true,
                    data: { id },
                    onClose,
                });
            },

            async load(id) {
                if (+id === 0) {
                    this.loaded = true;
                    return;
                }

                const { code, data } = await subscriptionPlanGet({ id });

                this.loaded = true;

                if (code === 1) {
                    this.data = data;
                    this.data.status = !!data.status;
                }

                console.log(code, data, this.item);
            },

            async saving() {
                const { code, data } = await subscriptionPlanSave(this.item.data);

                if (code === 1) {
                    this.modal.close();
                    // this.item.display(data.id);
                }
            },

            cover() {
                const that = this;
                return async (files, limit) => {
                    const result = await uploadProgress(files, limit);
                    if (result.success === true) {
                        that.item.data.cover = result.path;
                    }

                    return result;
                };
            },
        },

        add: {
            ['@click']() {
                const that = this;
                this.item.display(0, () => {
                    that.load();
                });
            },
        },

        active: {
            [':checked']() {
                return this.sub.status === 1 ? 'checked' : '';
            },
        },

        edit: {
            ['@click']() {
                const that = this;
                this.item.display(this.sub.id, () => {
                    that.load();
                });
            },
        },

        save: {
            async ['@click']() {
                if (typeof this.data.content === 'string' && this.data.content.trim() === '') {
                    window.sendEvent('toast', {
                        title: '方案內文不能為空。',
                    });
                    return false;
                }

                if (typeof this.data.content === 'object' && this.data.content.blocks.length === 0) {
                    window.sendEvent('toast', {
                        title: '方案內文不能為空。',
                    });
                    return false;
                }

                let content = this.data.content;
                if (content && typeof content === 'object') {
                    content = JSON.stringify(content);
                }

                const { code } = await subscriptionContentSave({
                    content: content,
                });

                if (code === 1) {
                    window.sendEvent('toast', {
                        title: '方案內文已更新。',
                    });
                }
            },
        },

        status: {
            async ['@change']() {
                const payload = {};
                const main = typeof this.sub === 'undefined';

                payload['status'] = this.$el.checked ? 1 : 0;
                if (main === false) {
                    payload['id'] = this.sub.id;
                }

                const { code } = await subscriptionStatus(payload);

                if (code === 1) {
                    window.sendEvent('toast', {
                        title: main ? '訂閱服務狀態已更新。' : '方案狀態已更新。',
                    });
                }
            },
        },
    }));
}
