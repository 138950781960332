const status = (els) => {
	const notCheckLength = els.not(':checked').length;

	if (notCheckLength === 0) {
		return 1; // checked all
	} else if (notCheckLength === els.length) {
		return -1; // not checked all
	}

	return 0; // checked some
};

export const registerCheckboxHelper = (alpine) => {
	alpine.directive('checkbox', (el, {}, { evaluateLater, evaluate, effect, cleanup }) => {
		const ta = $(el);
		const groupId = ta.data('group');
		const subGroupId = ta.data('sub-group');
		const value = ta.prop('value');
		const modelExp = ta.attr('x-model');
		const model = evaluateLater(modelExp);

		ta.on('change', function (e) {
			const that = $(this);
			const isChecked = that.is(':checked');
			let needAdd = [];
			let needDel = [];

			if (groupId && subGroupId) {
				const subGroupStatus = status($(`[data-sub-group='${subGroupId}']`));
				const subGroupTa = $(`#p${subGroupId}`);
				if (subGroupStatus === 1) {
					subGroupTa.prop('indeterminate', false).prop('checked', true);
					needAdd.push(+subGroupId);
				} else if (subGroupStatus === 0) {
					subGroupTa.prop('indeterminate', true).prop('checked', true);
					needAdd.push(+subGroupId);
				} else {
					subGroupTa.prop('indeterminate', false).prop('checked', false);
					needDel.push(+subGroupId);
				}

				const group = $(`[data-group='${groupId}']`);

				const groupStatus = status(group);
				const groupTa = $(`#p${groupId}`);

				if (groupStatus === 1) {
					groupTa.prop('indeterminate', false).prop('checked', true);
					needAdd.push(+groupId);
				} else if (groupStatus === 0) {
					groupTa.prop('indeterminate', true).prop('checked', true);
					needAdd.push(+groupId);
				} else {
					groupTa.prop('indeterminate', false).prop('checked', false);
					needDel.push(+groupId);
				}
			} else if (groupId) {
				const subGroup = $(`[data-sub-group='${value}']`);

				subGroup.prop('checked', isChecked);
				subGroup.each(function () {
					isChecked ? needAdd.push(+$(this).prop('value')) : needDel.push(+$(this).prop('value'));
				});

				const group = $(`[data-group='${groupId}']`);

				const groupStatus = status(group);
				const groupTa = $(`#p${groupId}`);

				if (groupStatus === 1) {
					groupTa.prop('indeterminate', false).prop('checked', true);
					needAdd.push(+groupId);
				} else if (groupStatus === 0) {
					groupTa.prop('indeterminate', true).prop('checked', true);
					needAdd.push(+groupId);
				} else {
					groupTa.prop('indeterminate', false).prop('checked', false);
					needDel.push(+groupId);
				}
			} else {
				const group = $(`[data-group='${value}']`);
				group.prop('checked', isChecked).prop('indeterminate', false);
				group.each(function () {
					isChecked ? needAdd.push(+$(this).prop('value')) : needDel.push(+$(this).prop('value'));
				});
			}

			model((ids) => {
				let origin = JSON.parse(JSON.stringify(ids));
				origin = origin.map(Number);

				origin = origin.concat(needAdd);
				origin = origin.filter((el) => !needDel.includes(el));

				evaluate(`${modelExp} = ${JSON.stringify([...new Set(origin)])}`);
			});
			e.preventDefault();
		});

		cleanup(() => {
			ta.off('change').remove();
		});
	});
};
