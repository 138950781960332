import Alpine from 'alpinejs';
import Controller from './controller';
import { format } from 'date-fns';
import ShortUniqueId from 'short-unique-id';
// import EditorJS from '@editorjs/editorjs';

import {
    pressList,
    pressSave,
    pressGet,
    memberAuthorList,
    tagList,
    pressPublish,
    pressHistory,
    elementImageUpload,
    pressSearch,
    pressPin,
    pressUnPin,
    pressSorter,
    categoryAll,
    categoryNew,
    categoryEdit,
    categoryDel,
    pressTag,
    tagTagSuggest,
    pressTagSave,
    subscriberList,
    shareStatus,
    shareExecute,
    intelligenceTagSuggest,
} from '../libs/api';
import mammoth from 'mammoth/mammoth.browser';

import {
    sleep,
    _uuid,
    onlyLetters,
    buttonFlow,
    slug,
    toPinyin,
    url,
    pluck,
    get,
    uploadProgress,
} from '../helpers/utils';

const TYPE_POST = 1;
const TYPE_PAGE = 3;
const TYPE_EPAPER = 4;

const tranxType = (type) => {
    switch (type) {
        case TYPE_POST:
            return 'post';
        case TYPE_PAGE:
            return 'page';
        case TYPE_EPAPER:
            return 'epaper';
    }
};

const tranxTypeName = (type) => {
    switch (type) {
        case TYPE_POST:
            return '文章';
        case TYPE_PAGE:
            return '頁面';
        case TYPE_EPAPER:
            return '電子報';
    }
};

class Press extends Controller {
    filterItems = [];

    editorLeaving() {
        const { editor: that } = Alpine.store('info');

        // const { detail } = e;

        // console.log('press::editor::leaving', e);

        if (that.data.is_draft_changed && that.autosave === true) {
            that.submit(true);
        }

        console.log(that.current.changed, that.forceQuit);

        if (
            that.data.is_draft_changed /*that.disableUpdate === false*/ &&
            that.data.status === 1 &&
            that.forceQuit === false /* && that.autosave === false*/
        ) {
            console.log('press::editor::leaving::modal');

            sendEvent('modal', {
                title: '確定要離開嗎？',
                html: '/pages/press/confirm-without-update.html',
                closeBtn: true,
            });

            //  detail.done(false);
            return false;
        }

        that.removeLeaving();

        return true;
    }

    shortcut() {
        const parent = this;
        const modal = Alpine.store('modal');

        return {
            data: { id: null },

            async do(action, id, force = false, data = {}) {
                this.data.id = id === null ? this.data.id : +id;

                if (force === false) {
                    return await this[`${action}`](this.data.id, force, data);
                }

                if (this.data.id === null) {
                    const router = Alpine.store('router');
                    return router.go('/press');
                }

                const info = Alpine.store('info');

                try {
                    await this[`${action}`](this.data.id, force, data, info.editor.data.type);

                    modal.close();
                    info.list.get();
                } catch (error) {
                    console.log(error, '<<<<----');
                }
            },

            async publish(id, force, attributes, type) {
                if (force === false) {
                    return sendEvent('modal', {
                        html: '/pages/press/confirm-publish.html',
                    });
                }

                const { code, data } = await pressPublish({ id, mode: 1 }, 'post');

                let notify = { title: tranxTypeName(type) + '發佈失敗' };

                if (code === 1) {
                    notify = {
                        title: tranxTypeName(type) + '已發佈',
                        message: `<a class="text-success text-decoration-underline" href="${
                            data.url
                        }" target="_blank" rel="noopener noreferrer">瀏覽${tranxTypeName(type)}頁面</a>`,
                        icon: 'bi-file-earmark-check',
                    };

                    const { editor: that } = Alpine.store('info');
                    that.data.is_draft_changed = false;
                }

                window.sendEvent('toast', notify);
            },

            async schedule(id, force, attributes, type) {
                if (force === false) {
                    return sendEvent('modal', {
                        html: '/pages/press/confirm-schedule.html',
                    });
                }

                const { code, data } = await pressPublish({ id, mode: 2, ...attributes }, 'post');

                let notify = { title: `${tranxTypeName(type)}排程發佈失敗` };

                if (code === 1) {
                    notify = {
                        title: `${tranxTypeName(type)}已排程發佈`,
                        icon: 'bi-calendar2-check',
                    };
                }

                console.log(parent, '<--=====');

                window.sendEvent('toast', notify);
            },

            async offline(id, force, attributes, type) {
                if (force === false) {
                    return sendEvent('modal', {
                        html: '/pages/press/confirm-offline.html',
                    });
                }

                const { code, data } = await pressPublish({ id, mode: 0 }, 'post');

                let notify = { title: `${tranxTypeName(type)}下架失敗` };

                if (code === 1) {
                    notify = {
                        title: `${tranxTypeName(type)}已下架`,
                        icon: 'bi-file-earmark-x',
                    };
                }

                window.sendEvent('toast', notify);
            },

            async cancelation(id, force, attributes, type) {
                if (force === false) {
                    return sendEvent('modal', {
                        html: '/pages/press/confirm-cancelation.html',
                    });
                }

                const { code, data } = await pressPublish({ id, mode: 0 }, 'post');

                let notify = { title: `${tranxTypeName(type)}排程取消失敗` };

                if (code === 1) {
                    notify = {
                        title: `${tranxTypeName(type)}排程已取消`,
                        icon: 'bi-calendar2-x',
                    };
                }

                window.sendEvent('toast', notify);
            },

            async deletion(id, force, attributes, type) {
                if (force === false) {
                    return sendEvent('modal', {
                        html: '/pages/press/confirm-deletion.html',
                    });
                }

                const { code, data } = await pressPublish({ id, mode: -1 }, 'post');

                let notify = { title: `${tranxTypeName(type)}刪除失敗` };

                if (code === 1) {
                    notify = {
                        title: `${tranxTypeName(type)}已刪除`,
                        icon: 'bi-trash',
                    };
                }

                window.sendEvent('toast', notify);
            },

            async goback(id, force, attributes, type) {
                const info = Alpine.store('info');
                const router = Alpine.store('router');

                console.log('press::shortcut::goback');

                info.editor.forceQuit = true;
                modal.close();

                router.go(info.editor.data.type === 1 ? '/press' : '/page');
            },

            async pin(id, force, attributes, type) {
                const info = Alpine.store('info');
                const { code, data } = await pressPin({ id });

                if (code === 1) {
                    if (typeof data !== 'undefined' && data.exceeded_limit === true) {
                        window.sendEvent('toast', {
                            title: AlpineI18n.t('press.article_pinned'),
                            message: AlpineI18n.t('press.top_articles_limit_reached'),
                            icon: 'bi-pin-angle-fill',
                        });
                    } else {
                        window.sendEvent('toast', {
                            title: AlpineI18n.t('press.article_pinned'),
                            icon: 'bi-pin-angle-fill',
                        });
                    }

                    info.list.get();
                }
            },

            async unpin(id, force, attributes, type) {
                const info = Alpine.store('info');
                const { code, data } = await pressUnPin({ id });

                if (code === 1) {
                    window.sendEvent('toast', {
                        title: AlpineI18n.t('press.article_unpinned'),
                        icon: 'bi-pin-angle',
                    });

                    info.list.get();
                }
            },

            async sorter(id, force, attributes, type) {
                console.log('press::shortcut::sorter', id, force, attributes);

                const info = Alpine.store('info');
                const { code, data } = await pressSorter({ ids: attributes });
            },
        };
    }

    data(params) {
        const shortcut = this.shortcut();
        const parent = this;

        // console.log(params.type === TYPE_EPAPER, params.type, TYPE_EPAPER, params, tranxTypeName(params.type), '{{{{{{_-------');

        if (params) {
            this.filterItems = [
                {
                    id: 1,
                    name: '文章狀態',
                    type: 'select',
                    col: 'status',
                    default: 'all',
                    items: [
                        {
                            id: 'all',
                            name: `所有${tranxTypeName(params.type)}`, // AlpineI18n.t(params.type === TYPE_EPAPER ? 'press.all_epaper' : 'press.all_articles'),
                        },
                        { id: 1, name: AlpineI18n.t(params.type === TYPE_EPAPER ? 'press.sended' : 'press.published') },
                        { id: 2, name: AlpineI18n.t('press.scheduled') },
                        { id: 0, name: AlpineI18n.t('press.draft') },
                    ],
                },

                {
                    id: 2,
                    name: '搜尋',
                    type: 'search',
                    default: '',
                    col: 'title',
                },
                {
                    id: 3,
                    name: '分類',
                    type: 'hidden',
                    col: 'category',
                    default: 'all',
                },
            ];
        }

        return {
            init() {
                const id = this.id ? +this.id : null;
                const type = this.type ? +this.type : null;

                this.editor.data.id = id;
                this.history.data.id = id;
                this.setting.data.id = id;
                this.success.data.id = id;
                this.tags.data.id = id;
                this.send.data.id = id;
                this.share.data.id = id;

                this.editor.data.type = type;
                this.history.data.type = type;
                this.setting.data.type = type;
                this.success.data.type = type;
                this.send.data.type = type;

                // console.log(window.AlpineI18n.t('press.search_title'), '[[');
            },
            title: '內容作品',
            list: {
                loaded: false,
                data: {},
                async get() {
                    const query = Alpine.store('query');
                    const parent = Alpine.store('info');
                    const t = tranxType(+parent.type); // === TYPE_POST ? 'post' : +parent.type === TYPE_PAGE ? 'page' : 'epaper';

                    parent.category.get();
                    parent.category.current = get(query.fetch(), 'query.category.value', 'all');

                    this.loaded = false;

                    const { code, data } = await pressList(t, query.fetch());
                    this.loaded = true;

                    if (data) {
                        this.data = data;

                        const meta = {
                            pos: +data.pos || 0,
                            count: +data.count || 0,
                            total: +data.total || 0,
                        };

                        query.midify(meta);
                        parent.paginate.init(meta);
                    } else {
                        this.data = {};
                    }
                },
                sorter() {
                    const { list: that } = Alpine.store('info');

                    return (event) => {
                        const { item } = event;
                        const toTop = $(item).parent().find('[data-to-top=true]');
                        const ids = $.map(toTop, (el) => $(el).data('id'));

                        console.log(that, '<--');

                        that.shortcut.do('sorter', 0, false, ids);
                    };
                },
                shortcut,
            },
            success: {
                data: {
                    id: null,
                    image: null,
                },
                async init() {
                    const id = +this.data.id;
                    if (id === 0) {
                        return false;
                    }

                    const { code, data } = await pressGet({ id: id }, 'post');

                    if (code === 1) {
                        console.log('press::editor::get', data);
                        data.type = this.data.type;
                        this.data = data;
                    }
                },
                share() {
                    sendEvent('modal', {
                        title: '分享',
                        html: '/pages/press/share-panel.html',
                        closeBtn: true,
                    });
                },
            },
            share: {
                loaded: false,
                data: {
                    id: null,
                    image: null,
                    message: '',
                    platform: { facebook: false, instagram: false },
                },
                meta: null,
                async status() {
                    const { code, data } = await shareStatus({ id: this.data.id });
                    if (code === 1) {
                        this.meta = data;
                        this.data.message = data.message;

                        this.loaded = true;
                    }
                },
                import() {
                    let that = Alpine.store('info');

                    return async (files, limit) => {
                        const result = await uploadProgress(files, limit);
                        if (result.success === true) {
                            that.share.data.image = result.path;
                        }

                        return result;
                    };
                },
                async share() {
                    const { code, data } = await shareExecute(this.data);
                    if (code === 1) {
                        Alpine.store('modal').close();
                    }
                },
            },
            setting: {
                data: { id: null, mode: 1, date: '', time: '', pin: false },
                init() {
                    const now = new Date();

                    const ms = 1000 * 60 * 30;
                    const roundedDate = new Date(Math.ceil(now.getTime() / ms) * ms);

                    this.data.date = format(now, 'yyyy-MM-dd');
                    this.data.time = format(roundedDate, 'HH:mm');
                },
                async submit() {
                    const { code, data } = await pressPublish(this.data, 'post');

                    if (code === 1) {
                        const router = Alpine.store('router');
                        const type = tranxType(this.data.type); // ? 'press' : 'page';

                        if (this.data.type === 4) {
                            window.sendEvent('toast', {
                                title: '電子報已發送',
                                icon: 'bi-send-fill',
                            });

                            router.go(`/epaper`);
                        } else {
                            router.go(`${type === 'post' ? 'press' : type}/${this.data.id}/publish/success`);
                        }
                    }

                    console.log('press::setting::submit', code, data);
                },
            },
            tags: {
                async init() {
                    await this.load();
                    await this.tag.get();
                },
                loaded: false,
                data: {
                    id: null,
                    selected: [],
                },
                async load() {
                    this.manual.loading = true;

                    const { code, data } = await pressTag({ id: this.data.id });
                    this.data.selected = data.rows ?? [];

                    // console.log(data.rows, data.suggested, '[[--');

                    // const selectedIDs = pluck(this.data.selected, 'id');
                    // if (data.suggested.exists) {
                    //     data.suggested.exists = data.suggested.exists.filter(
                    //         (tag) => !selectedIDs.includes(`${tag.id}`),
                    //     );
                    // }
                    // if (data.suggested.new) {
                    //     data.suggested.new = data.suggested.new.filter((tag) => !selectedIDs.includes(`${tag.id}`));
                    // }

                    // console.log(selectedIDs, data.suggested.new);

                    // this.manual.suggested = data.suggested;

                    this.loaded = true;

                    this.manual.loaded = true;
                    this.manual.loading = false;
                },
                async submit() {
                    const { code, data } = await pressTagSave(this.data);

                    if (code == 1) {
                        const { editor } = Alpine.store('info');
                        editor.displayPublishSetting.apply(editor);
                    }
                },
                del(id) {
                    const { tags } = Alpine.store('info');

                    const selector = Alpine.raw(tags.tag.ta);
                    let original = tags.tag.data.find((item) => +item.id == +id);

                    if (original && selector) {
                        selector.addOption({ id: id, title: original.title });
                    } else {
                        let selected = tags.suggest.selected.find((item) => item.id == id);
                        tags.suggest.selected = tags.suggest.selected.filter((item) => item.id !== id);

                        if (selected) {
                            if (selected.type === 'exists') {
                                tags.manual.suggested.exists.push({
                                    id: id,
                                    name: selected.name,
                                });
                            } else if (selected.type === 'new') {
                                tags.manual.suggested.new.push({
                                    id: id,
                                    name: selected.name,
                                });
                            }
                        }
                    }

                    this.data.selected = this.data.selected.filter((item) => item.id !== id);
                },
                manual: {
                    loading: false,
                    loaded: false,
                    suggested: [],
                    intelligence: {},
                    add() {
                        const { tags } = Alpine.store('info');
                        const generator = new ShortUniqueId();

                        return (value, item, selector) => {
                            selector.clear();
                            selector.removeOption(value);
                            selector.blur();

                            tags.data.selected.push({
                                id: generator(),
                                name: value,
                            });
                        };
                    },
                    select() {
                        const { tags } = Alpine.store('info');

                        return (value, selector) => {
                            if (value.length === 0) {
                                return false;
                            }

                            const first = value;

                            let selected = tags.tag.data.find((item) => +item.id == +first);

                            console.log(value, first, tags.tag.data, selected, 'P{PPPPP');

                            selector.clear();
                            selector.removeOption(first);
                            selector.blur();

                            tags.data.selected.push({
                                id: selected.id,
                                name: selected.title,
                            });
                        };
                    },
                    async suggest() {
                        const { tags } = Alpine.store('info');

                        this.loading = true;
                        this.loaded = false;

                        const { code, data } = await intelligenceTagSuggest({
                            type: 'press',
                            id: tags.data.id,
                            // content: item.data.content,
                        });

                        if (code === 1) {
                            this.intelligence = data.usage;
                            this.suggested = {
                                exists: data.exists,
                                new: data.additional,
                            };
                        }

                        this.loaded = true;
                        this.loading = false;
                    },
                },

                tag: {
                    ta: null,
                    data: null,
                    loaded: false,
                    async get() {
                        const {
                            code,
                            data: { rows, intelligence },
                        } = await tagList();

                        this.loaded = true;
                        this.data = rows;

                        const { tags } = Alpine.store('info');
                        tags.manual.intelligence = intelligence;
                        intelligence;
                    },
                    set(selector) {
                        let tidied = this.data.map((tag) => {
                            return { id: tag.id, title: tag.title ?? 'unnamed' };
                        });

                        this.ta = selector;

                        const { tags } = Alpine.store('info');
                        const selected = pluck(tags.data.selected, 'id');

                        tidied = tidied.filter((item) => !selected.includes(item.id));

                        console.log(selected, tidied, '{{999999');

                        selector.addOptions(tidied, false);
                    },
                },

                suggest: {
                    selected: [],
                    select(id, type) {
                        const { tags } = Alpine.store('info');

                        let selected = tags.manual.suggested[type].find((item) => item.id == id);
                        const payload = {
                            id: selected.id,
                            name: selected.name,
                            slug: selected.slug,
                            type: type,
                        };

                        tags.data.selected.push(payload);
                        tags.suggest.selected.push(payload);

                        tags.manual.suggested[type] = tags.manual.suggested[type].filter((item) => item.id !== id);
                    },
                },
            },
            editor: {
                displaySidebar: false,
                analysis: {},
                panel: null, // 'optimal',
                loaded: false,
                autosave: true,
                // disableUpdate: true,
                forceQuit: false,
                current: {
                    display: false,
                    status: null, // 0:草稿;1:發布
                    saving: false,
                    saveExec: null,
                    changed: false,
                },
                to_top: false,
                data: {
                    id: null,
                    slug: null,
                    title: '',
                    content: '',
                    author: [],
                    tag: [],
                    uuid: null,
                    abstract: '',
                    category_id: null,
                    subtitle: '',
                    status: 0,
                },
                async init() {
                    const that = this;
                    const t = tranxType(+this.data.type);
                    console.log('press::data::editor::init', this.data);

                    if (+this.data.id === 0) {
                        this.data.uuid = _uuid();
                    } else {
                        await this.get(+this.data.id);
                    }

                    if (+this.data.type === TYPE_POST) {
                        // await this.tag.get();
                        await this.author.get();
                        await this.category.get();
                    }

                    if (+this.data.type === TYPE_EPAPER && +this.data.status === 2) {
                        window.sendEvent('modal', {
                            html: '/pages/press/alert-scheduled-modify.html',
                        });
                    }

                    $(window)
                        .off('hashchange')
                        .on('hashchange', function (e) {
                            const hash = location.hash;
                            const current = hash.replace(/^#/, '') || '';

                            console.log('editor::hashchange', hash);

                            if (current !== '') {
                                that.panel = current;
                                that.displaySidebar = true;

                                location.hash = '';

                                // const uri = window.location.toString();

                                // if (uri.indexOf('#') > 0) {
                                //     const clean_uri = uri.substring(0, uri.indexOf('#'));

                                //     window.history.replaceState({}, document.title, clean_uri);
                                // }
                            }
                        });

                    // $(window).hashchange();

                    this.loaded = true;

                    const leavingCallback = function (event) {
                        const { detail } = event;
                        const result = parent.editorLeaving();

                        sleep(that.data.is_draft_changed ? 750 : 1).then(() => {
                            $(window).off('hashchange');
                            if (result === true) {
                                window.removeEventListener(`${t}.editor.leaving`, leavingCallback);
                                detail.done();
                            } else {
                                detail.done(false);
                            }
                        });
                    };

                    window.addEventListener(`${t}.editor.leaving`, leavingCallback);
                },
                async get(id) {
                    console.log('press::editor::get', id);
                    if (+id === 0) {
                        return false;
                    }

                    const { code, data } = await pressGet({ id: +id }, 'post');

                    if (code === 1) {
                        console.log('press::editor::get', data);
                        this.data = data;
                        this.current.status = +data.status;
                        this.meta.data.abstract = data.abstract;
                        // this.autosave = +data.status !== 1;

                        this.data.author = pluck(this.data.author, 'id');

                        this.meta.data.related = data.related || 1;
                        this.meta.data.custom = data.custom;
                        this.to_top = +data.to_top > 0 ? true : false;

                        this.data.is_draft_changed = !!data.is_draft_changed;

                        // if (typeof data.seo === 'object' && Object.keys(data.seo).length > 0) {
                        //  this.seo.data = data.seo;
                        // }

                        if (data.cover !== '' && data.cover !== null && typeof data.cover !== 'undefined') {
                            this.meta.data.mode = 'saved';
                            this.meta.data.saved = true;
                            this.meta.data.cover = data.cover;
                        }
                    }
                },
                async manualUpdate(button) {
                    const btn = $(button);
                    console.log('press::editor::manual::update', btn);

                    const { loading, done } = buttonFlow(btn);

                    loading();

                    // this.disableUpdate = true;
                    this.forceQuit = false;

                    this.submit(true, async () => await shortcut.do('publish', this.data.id, true));

                    done(false);
                },
                manualSubmit(button) {
                    const btn = $(button);
                    console.log('press::editor::manual::submit', btn);

                    const { loading, done } = buttonFlow(btn);
                    loading();
                    this.submit(true, done);
                },
                submit(force = false, callback = null) {
                    const that = this;
                    const router = Alpine.store('router');
                    const t = tranxType(+this.data.type);

                    that.current.changed = true;

                    that.injectLeaving();

                    clearTimeout(this.current.saveExec);

                    if (that.data.id === 0) {
                        force = true;
                    }

                    this.current.saveExec = setTimeout(
                        async () => {
                            // const abstract = that.meta.data.abstract;
                            let cover = that.data.cover;
                            if (that.meta.data.changed) {
                                const coverUrl = that.meta.data.cover;
                                cover = new URL(coverUrl).pathname;
                            }

                            const related = +that.meta.data.related;
                            let custom = [];
                            if (related === 4) {
                                if (that.meta.data.sort.length === 0) {
                                    custom = that.meta.data.custom.map((item) => +item);
                                } else if (that.meta.data.custom.length > 0) {
                                    that.meta.data.sort.map((order) => {
                                        custom.push(+that.meta.data.custom[order] ?? 0);
                                    });
                                }
                            }

                            if ($.trim(that.data.title) && !$.trim(that.data.slug)) {
                                that.data.slug = toPinyin(that.data.title);
                            }

                            that.current.display = true;
                            that.current.saving = true;

                            /*Alpine.store('info').tags.data.selected*/
                            const {
                                tags: {
                                    data: { selected },
                                },
                            } = Alpine.store('info');
                            const tag = selected;

                            // const seo = this.seo.data;

                            const {
                                code,
                                data: {
                                    id: post_id,
                                    slug: new_slug,
                                    status,
                                    is_draft_changed,
                                    draft,
                                    preview_url,
                                    url,
                                    tag: savedTag,
                                },
                            } = await pressSave(
                                {
                                    ...that.data,
                                    force,
                                    cover,
                                    /*abstract,*/ related,
                                    custom,
                                    // seo,
                                    set2top: this.to_top,
                                    tag: selected,
                                },
                                t,
                            );

                            if (code === 1) {
                                const toType = +this.data.type === TYPE_POST ? 'press' : tranxType(+this.data.type);
                                if (that.data.id === 0) {
                                    that.data.id = +post_id;
                                    router.go(`/${toType}/${post_id}`, { silent: true });

                                    if (that.data.slug === null || !`${that.data.slug}`.trim()) {
                                        that.data.title = `(${new_slug})`;
                                    }
                                }

                                that.data.slug = `${new_slug}`;
                                that.current.status = +status;
                                that.current.saving = false;
                                that.current.changed = false;
                                that.data.is_draft_changed = !!is_draft_changed;
                                that.data.preview_url = preview_url;
                                that.data.url = url;

                                // that.disableUpdate = is_draft_changed === 0;

                                console.log('slug =>', new_slug);

                                that.removeLeaving();

                                if (typeof callback === 'function') {
                                    callback.apply(null);
                                }

                                /*if (force && that.data.draft !== draft) {
                                    let notify = {
                                        title: '內容已儲存',
                                        message: `<a class="text-success text-decoration-underline" href="#history">查看歷史版本</a>`,
                                        icon: 'bi-floppy-fill',
                                    };
                                    window.sendEvent('toast', notify);
                                }*/

                                const { tags: tagsInstance } = Alpine.store('info');
                                tagsInstance.data.selected = savedTag;

                                sleep(2000).then(() => {
                                    that.current.display = false;
                                });
                            }
                        },
                        force ? 1 : 2000,
                    );
                },
                injectLeaving(set = true) {
                    $(window)
                        .off('beforeunload')
                        .on('beforeunload', function (e) {
                            return AlpineI18n.t('press.unsaved_changes_reminder');
                        });
                },
                removeLeaving() {
                    $(window).off('beforeunload');
                },
                checkExec: null,
                checkTitle(value) {
                    console.log('editor::check::title', value);
                    const that = this;
                    const original = value.replace(/(\r\n|\n|\r)/gm, '');

                    that.data.title = original;

                    clearTimeout(that.checkExec);
                    this.checkExec = setTimeout(() => {
                        const title = `${original}`.trim();
                        const slug = toPinyin(title);

                        if (that.data.slug === null || slug.includes(that.data.slug)) {
                            that.data.slug = slug;
                        }

                        that.submit();
                    }, 500);
                },
                handleEnter(e) {
                    const code = e.keyCode ? e.keyCode : e.which;
                    if (code == 13) {
                        e.preventDefault();
                        window.sendEvent('editor::focus');
                        return true;
                    }
                },
                setAbstract(value) {
                    if (typeof value !== 'object') {
                        return false;
                    }

                    console.log('setAbstract', `${this.meta.data.abstract}`);

                    if (!`${this.meta.data.abstract}`.trim() && value.blocks.length > 0) {
                        let abstract = '';
                        value.blocks.map((block) => {
                            if (block.type === 'paragraph') {
                                abstract = `${abstract}${block.data.text.trim()}`;
                            }
                        });

                        abstract = abstract.substring(0, 120);

                        if (this.data.abstract === null || abstract.includes(this.data.abstract)) {
                            this.data.abstract = abstract;
                            this.meta.data.abstract = abstract;
                        }
                    }
                },
                changed() {
                    const that = this;

                    return async (oldValue, newValue) => {
                        // that.setAbstract(newValue);
                        // that.disableUpdate = false;
                        console.log('press::editor::changed', that.data.is_draft_changed);
                        // that.data.is_draft_changed = true;
                        if (that.autosave) {
                            await that.submit();
                        }
                    };
                },
                next() {
                    if (this.data.type === 1) {
                        this.displayPublishTags();
                    } else if (this.data.type === 3) {
                        this.displayPublishSetting();
                    } else if (this.data.type === 4) {
                        this.displayEPaperPanel();
                    }
                },
                displayPublishTags() {
                    const router = Alpine.store('router');
                    const type = tranxType(this.data.type);
                    this.submit(true);

                    router.go(`${type === 'post' ? 'press' : type}/${this.data.id}/publish/tags`);
                },
                displayPublishSetting() {
                    const router = Alpine.store('router');
                    const type = tranxType(this.data.type);
                    // this.submit(true);

                    router.go(`${type === 'post' ? 'press' : type}/${this.data.id}/publish/setting`);
                },
                displayEPaperPanel() {
                    const router = Alpine.store('router');
                    const type = tranxType(this.data.type);
                    // this.submit(true);

                    router.go(`${type === 'post' ? 'press' : type}/${this.data.id}/send`);
                },
                async refresh() {
                    // const info = Alpine.store('info');
                    // await info.editor.get();
                    await this.get(+this.data.id);
                },
                author: {
                    data: null,
                    loaded: false,
                    async get() {
                        const {
                            code,
                            data: { rows: authors },
                        } = await memberAuthorList();

                        this.loaded = true;
                        this.data = authors;

                        const { user } = Alpine.store('auth');
                        const { editor } = Alpine.store('info');

                        if (editor.data.author.length === 0) {
                            editor.data.author = [user.id];
                        }
                    },
                    set(selector) {
                        const auth = Alpine.store('auth');
                        const info = Alpine.store('info');

                        const tidied = this.data.map((author) => {
                            return { id: author.id, title: author.name ?? '(unnamed)' };
                        });

                        selector.addOptions(tidied, false);

                        const authors =
                            (info.editor.data.author ?? []).length === 0
                                ? [auth.user.id]
                                : (info.editor.data.author ?? []);

                        authors.map((author) => selector.setValue(`${author}`, true));
                    },
                },
                // tag: {
                //  data: null,
                //  loaded: false,
                //  async get() {
                //      const {
                //          code,
                //          data: { rows: tags },
                //      } = await tagList();

                //      this.loaded = true;
                //      this.data = tags;
                //  },
                //  set(selector) {
                //      const tidied = this.data.map((tag) => {
                //          return { id: tag.id, title: tag.title ?? 'unnamed' };
                //      });

                //      selector.addOptions(tidied, false);
                //  },
                // },
                meta: {
                    init() {
                        const {
                            editor: {
                                data: {
                                    content: { blocks },
                                },
                            },
                        } = Alpine.store('info');

                        let images = [];

                        if (typeof blocks !== 'undefined') {
                            blocks.map((block) => {
                                if (block.type === 'image') {
                                    images.push(block.data.file.url);
                                } else if (block.type === 'album') {
                                    images = [...images, ...block.data.urls];
                                }
                            });

                            this.data.images = images;
                        }

                        this.press.get();

                        sendEvent('validation::update');
                    },
                    data: {
                        images: [],
                        mode: 'inside',
                        cover: '',
                        saved: false,
                        changed: false,
                        abstract: '',
                        related: 1,
                        custom: [],
                        sort: [],
                    },
                    filename: null,
                    filesize: null,
                    selected() {
                        const { editor: that } = Alpine.store('info');
                        return (index) => {
                            that.meta.data.cover = that.meta.data.images.at(index);
                            that.meta.data.changed = true;

                            console.log('selected', that.changed());
                            that.changed().apply(that);
                        };
                    },
                    import() {
                        let {
                            editor: that, // { meta: that, data, changed },
                        } = Alpine.store('info');
                        return async (files, limit) => {
                            const result = await uploadProgress(files, limit);
                            if (result.success === false) {
                                return result;
                            }

                            that.meta.data.cover = url(import.meta.env.VITE_CDN_BASE, result.path);
                            that.meta.data.changed = true;
                            that.meta.data.saved = true;
                            that.meta.data.mode = 'saved';

                            that.data.cover = result.path;

                            // console.log('import', that.changed());
                            that.changed().apply(that);

                            return result;
                        };
                    },
                    onSorting() {
                        const that = Alpine.store('info');
                        return (event) => {
                            const ta = $(event.target);
                            const sort = ta.find('[data-idx]').map(function (ele) {
                                return $(this).data('idx');
                            });

                            that.editor.meta.data.sort = sort.toArray();
                        };
                    },
                    press: {
                        data: null,
                        loaded: false,
                        async get() {
                            const {
                                code,
                                data: { rows },
                            } = await pressSearch();

                            this.loaded = true;
                            this.data = rows;

                            sendEvent('validation::update');
                        },
                        set(selector) {
                            const custom = Alpine.store('info').editor.meta.data.custom;
                            const tidied = this.data.map((option) => {
                                return { id: option.id, title: option.title };
                            });

                            selector.addOptions(tidied, false);

                            const idx = +$(selector.input).closest('.related--item').data('idx');

                            selector.setValue(`${custom[idx]}`, true);

                            console.log(selector.input, idx, custom, '[---------------------------------------------');
                        },
                        search() {
                            const that = Alpine.store('info').editor.meta.press;

                            return async (query, callback) => {
                                console.log(that.data, '[[--');
                                if (that.data !== null && that.data.length < 10) {
                                    callback();
                                    return false;
                                }

                                const {
                                    code,
                                    data: { rows },
                                } = await pressSearch(query);
                                const tidied = rows.map((option) => {
                                    return { id: option.id, title: option.title };
                                });

                                callback(tidied);
                            };
                        },
                    },
                },
                /*seo: {
                    type: 'general',
                    data: {
                        general: {
                            title: '',
                            content: '',
                        },
                        facebook: {
                            cover: null,
                            title: '',
                            content: '',
                        },
                        twitter: {
                            cover: null,
                            title: '',
                            content: '',
                        },
                    },
                    facebook: {
                        import() {
                            let { editor: that } = Alpine.store('info');
                            return async (files) => {
                                if (files.length === 0) {
                                    return false;
                                }

                                const file = files[0];

                                const {
                                    code,
                                    data: { path },
                                } = await elementImageUpload({ file: file });

                                that.seo.data.facebook.cover = path;
                                that.changed().apply(that);
                            };
                        },
                    },
                    twitter: {
                        import() {
                            let {
                                editor: that,
                            } = Alpine.store('info');
                            return async (files) => {
                                if (files.length === 0) {
                                    return false;
                                }

                                const file = files[0];

                                const {
                                    code,
                                    data: { path },
                                } = await elementImageUpload({ file: file });

                                that.seo.data.twitter.cover = path;
                                that.changed().apply(that);
                            };
                        },
                    },
                },*/
                category: {
                    data: null,
                    loaded: false,
                    async get() {
                        const {
                            code,
                            data: { rows: cats },
                        } = await categoryAll();

                        console.log('========================================');

                        this.loaded = true;
                        this.data = cats;
                    },
                    set(selector) {
                        const auth = Alpine.store('auth');
                        const info = Alpine.store('info');

                        const tidied = this.data.map((cat) => {
                            return { id: cat.id, title: cat.name };
                        });

                        selector.addOptions(tidied, false);

                        if (+info.editor.data.category_id > 0) {
                            selector.setValue(`${info.editor.data.category_id}`, true);
                        }
                    },
                },
                shortcut,

                tranxType: tranxType,
                tranxTypeName: tranxTypeName,
            },
            history: {
                async init() {
                    await this.get();
                },
                editor: null,
                loaded: false,
                data: { id: null, list: [], current: null, selected: null },
                show(id) {
                    this.data.current = +id;
                    console.log('editor::history::show', id);
                    const selected = this.data.list.filter((history) => +history.id === id).shift();
                    if (selected) {
                        sendEvent('editor::set::readonly');
                        sendEvent('editor::set::content', { content: selected.content });
                    }
                },
                leave(force = false) {
                    console.log('editor::history::leave', force);
                    const that = Alpine.store('info');

                    this.data.current = null;

                    sendEvent('editor::reset');

                    if (force) {
                        console.log('editor::history::leave::force');
                        that.editor.displaySidebar = false;
                    }

                    that.editor.panel = null;
                },
                async get() {
                    const {
                        code,
                        data: { rows: histories },
                    } = await pressHistory(this.data.id);

                    if (code === 1) {
                        this.data.list = histories;
                        this.loaded = true;
                    }
                },
                undo(id, force = false) {
                    if (force === true) {
                        const that = Alpine.store('info');
                        that.editor.data.content = this.data.selected.content;

                        Alpine.store('modal').close();

                        this.leave(true);

                        return false;
                    }

                    console.log('editor::history::undo', id);
                    if (id !== this.data.current) {
                        return false;
                    }

                    const selected = this.data.list.filter((history) => +history.id === id).shift();
                    if (selected) {
                        this.data.selected = selected;

                        sendEvent('modal', {
                            html: '/pages/press/confirm-undo.html',
                        });
                    }
                },
            },
            import: {
                importData: null,
                filename: null,
                filesize: null,
                enableImport: false,
                data: { content: [] },
                processImport() {
                    const that = this;

                    return (files, limit) => {
                        if (files.length === 0) {
                            return false;
                        }

                        const file = files[0];

                        if (file.size / 1024 ** 2 >= limit.filesize) {
                            return { success: false, message: '檔案大小超過限制' };
                        }

                        const reader = new FileReader();

                        that.filename = file.name;
                        that.filesize = `${Math.ceil(file.size / 1024)}KB`;

                        return new Promise(function (resolve, reject) {
                            reader.onloadend = async (event) => {
                                try {
                                    const arrayBuffer = reader.result;
                                    const result = await mammoth.convertToHtml(
                                        { arrayBuffer: arrayBuffer },
                                        {
                                            transformDocument: mammoth.transforms.run((ele) => {
                                                if (ele.bgColor) {
                                                    return {
                                                        ...ele,
                                                        styleName: 'mark',
                                                    };
                                                }

                                                return ele;
                                            }),
                                            styleMap: [
                                                `r[style-name='mark'] => span.mark`,
                                                'b => b',
                                                'i => i',
                                                'h1 => h3',
                                                'h2 => h3',
                                            ],
                                            // includeDefaultStyleMap: false,
                                        },
                                    );

                                    const parsed = new DOMParser().parseFromString(result.value, 'text/html');
                                    parsed.querySelectorAll('span.mark').forEach((e) => {
                                        const n = document.createElement('mark');
                                        n.innerHTML = e.innerHTML;
                                        e.replaceWith(n);
                                    });

                                    const transformed = parsed.body.innerHTML;

                                    console.log(transformed);

                                    // sendEvent('editor::set::content::html', { content: transformed });

                                    await sleep(10);

                                    // that.importData = transformed;
                                    that.data.content = transformed;

                                    // if (that.importData !== null) {
                                    that.enableImport = true;
                                    // }

                                    resolve({ success: true });
                                } catch (error) {
                                    console.error(error);

                                    that.filename = null;
                                    that.filesize = null;
                                    that.importData = null;
                                    that.enableImport = false;

                                    reject({ success: false, message: '轉換時發生錯誤' });
                                }
                            };

                            reader.readAsArrayBuffer(file);
                        });
                    };
                },
                async submit() {
                    const auth = Alpine.store('auth');
                    const filename = this.filename.replace(/\.[^/.]+$/, '');

                    const {
                        code,
                        data: { id: post_id },
                    } = await pressSave(
                        {
                            id: null,
                            slug: slug(toPinyin(filename)),
                            title: filename,
                            content: this.data.content,
                            author: [auth.user.id],
                            tag: [],
                            uuid: _uuid(),
                        },
                        'post',
                    );

                    const router = Alpine.store('router');
                    router.go(`press/${post_id}`);
                },
            },
            category: {
                loaded: false,
                list: [],
                data: {
                    el: null,
                    id: 0,
                    old_name: '',
                    name: '',
                    recover: () => {},
                },

                current: 'all',

                select() {
                    const { filter } = Alpine.store('info');

                    return (el) => {
                        el.toggleClass('is-active', true);
                        el.siblings('.is-active').toggleClass('is-active', false);
                        console.log('press::category::select', el, el.data('value'));

                        filter.query(3, el.data('value'));
                    };
                },

                recover() {
                    const modal = Alpine.store('modal');
                    modal.close();

                    this.data.recover.call(null);
                },
                edit() {
                    const that = this.category;
                    return (el, newValue, oldValue, recover) => {
                        that.data.name = newValue;
                        that.data.old_name = oldValue;
                        that.data.recover = recover;
                        that.data.id = +el.data('id');

                        sendEvent('modal', {
                            html: '/pages/press/edit-category.html',
                        });
                    };
                },
                async editing() {
                    const { code } = await categoryEdit({
                        id: this.data.id,
                        name: this.data.name,
                    });

                    if (code === 1) {
                        const modal = Alpine.store('modal');

                        modal.close();

                        this.get();
                    }
                },
                add() {
                    const that = this.category;
                    return () => {
                        that.data.name = '';
                        sendEvent('modal', {
                            html: '/pages/press/add-category.html',
                        });
                    };
                },
                async adding() {
                    const { code } = await categoryNew({
                        name: this.data.name,
                    });

                    if (code === 1) {
                        const modal = Alpine.store('modal');
                        modal.close();

                        this.get();
                    }
                },
                delete() {
                    const that = this.category;
                    // console.log(that.data);

                    return (el, text) => {
                        that.data.name = text;
                        that.data.id = +el.data('id');

                        sendEvent('modal', {
                            html: '/pages/press/delete-category.html',
                        });
                    };
                },
                async deleting() {
                    const { code } = await categoryDel({
                        id: this.data.id,
                    });

                    if (code === 1) {
                        const modal = Alpine.store('modal');
                        const { filter } = Alpine.store('info');

                        modal.close();

                        filter.query(3, 'all');

                        this.get();
                    }
                },

                async get() {
                    this.loaded = false;

                    const {
                        code,
                        data: { rows: data },
                    } = await categoryAll();

                    if (code === 1) {
                        this.list = data;
                        this.loaded = true;
                    }
                },
            },
            send: {
                loaded: false,
                data: {
                    count: 0,
                },
                async getList() {
                    const { code, data } = await subscriberList({});

                    if (code === 1) {
                        this.data = data;
                        this.loaded = true;
                    }
                },
            },
            shortcut,
            tranxType: tranxType,
            tranxTypeName: tranxTypeName,
        };
    }
}

export const press = new Press();
