import Alpine from 'alpinejs';
import Controller from './controller';

class Role extends Controller {
	data() {
		const auth = Alpine.store('auth');
		return {
			permissionsSet: [],
			title: '角色管理',
			basePermissions: auth?.user.base_permissions,
			role: {
				name: '',
			},
			all: this.all,
			get: this.get,
			save: this.save,
			sort: this.all,
		};
	}

	async all() {
		const query = Alpine.store('query');

		const result = await Alpine.store('api').getClient().post('place/list', { query: query.fetch() });
		this.role = result.data?.rows;

		const meta = {
			pos: +result.data.pos,
			count: +result.data.count,
			total: +result.data.total,
		};

		query.midify(meta);
		this.paginate.init(meta);
	}

	async get() {
		const info = Alpine.store('info');

		if (info.id == 0) {
			return false;
		}

		const result = await Alpine.store('api').getClient().post('place/get', { id: info.id });
		this.role = result.data;
		this.title += ' - ' + this.role.name;
	}

	async save() {
		const info = Alpine.store('info');
		const result = await Alpine.store('api')
			.getClient()
			.post('place/save', { permissions: info.permissionsSet, name: info.role.name, id: info.role._id });

		if ('code' in result && result.code === 1) {
			Alpine.store('router').go('role');

			return;
		}
	}
}

export const role = new Role();
