/*global TPDirect*/

export const registerTappayHelper = (alpine) => {
	alpine.directive('tappay', (el, {}, { cleanup }) => {
		if (typeof TPDirect === 'undefined') {
			console.error('Tappay::load failed');
			return false;
		}

		const submitButton = $(el).find('.js-submit');

		console.info('Tappay::load successed');

		TPDirect.setupSDK(
			import.meta.env.VITE_TAPPAY_APP_ID,
			import.meta.env.VITE_TAPPAY_APP_KEY,
			import.meta.env.VITE_TAPPAY_DEBUG ? 'sandbox' : 'production'
		);

		let fields = {
			number: {
				element: '#card-number',
				placeholder: '信用卡 16 碼號碼',
			},
			expirationDate: {
				element: '#card-expiration-date',
				placeholder: '月 / 年',
			},
			ccv: {
				element: '#card-ccv',
				placeholder: '驗證碼',
			},
		};

		TPDirect.card.setup({
			fields: fields,
		});
	});
};
