const filterTemplate = `
<template x-for="rule in filter.items">
    <template x-if="['select', 'search'].includes(rule.type)">
        <div class="col-auto filter__item" x-data="{selected: $get(filter.current, rule.col + '.value', rule.default)}">
            <template x-if="rule.type === 'select'">
                <select class="form-select" @change="filter.query(rule.id, $event.target.value)">
                    <template x-for="option in rule.items">
                        <option
                            :value="option.id"
                            :selected="+selected === +option.id"
                            x-text="option.name"
                        ></option>
                    </template>
                </select>
            </template>
            <template x-if="rule.type === 'search'">
                <div class="filter--text">
                    <input type="text" class="form-control" :placeholder="rule.name" @change="filter.query(rule.id, $event.target.value)" :value="selected">
                </div>
            </template>
        </div>
    </template>
</template>
`;

export const registerFilterHelper = (alpine) => {
    alpine.directive(
        'filter',
        (el, { value, modifiers, expression }, { Alpine, evaluate, evaluateLater, effect, cleanup }) => {
            const ta = $(el);
            const wrapper = $('<div class="filter--wrapper"></div>');
            const toggler = $('<button type="button" class="btn btn-light">篩選器</button>');
            const togglerWrapper = $('<div class="col-12 filter__toggler"></div>');

            ta.toggleClass('row filter g-2', true);
            el.innerHTML = filterTemplate;

            ta.wrap(wrapper);
            togglerWrapper.append(toggler);
            ta.prepend(togglerWrapper);

            toggler.on('click', function () {
                const wrapper = ta.parent('.filter--wrapper');

                wrapper.toggleClass('show');
                console.log(ta, wrapper, toggler, togglerWrapper);
            });
        },
    );
};
