import menu from '../libs/menu.json';

export const registerMenuHelper = (alpine) => {
	alpine.data('menu', () => ({
		permissions: [],
		items: menu,
		init() {
			this.permissions = alpine.store('auth')?.user.permissions;
		},
	}));
};
