import { bisector, sleep } from '../helpers/utils';

export default function (Alpine) {
    Alpine.directive('category-bar', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);

        const callbackEdit = ta.data('on-edit');
        const callbackAdd = ta.data('on-add');
        const callbackDelete = ta.data('on-delete');
        const callbackSelect = ta.data('on-click');

        const wrapper = ta.find('.category--item-wrapper');
        const prev = ta.find('.category--action__prev');
        const next = ta.find('.category--action__next');
        const add = ta.find('.category--action__add');

        const bis = bisector((d) => parseInt(d)).left;

        let timerExec = null;
        let current = 0;
        let positions = [];
        let selected = false;

        const selectContent = (target) => {
            const range = document.createRange();
            range.selectNodeContents(target);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        };

        const setEditEvent = () => {
            const itemsCanSelect = wrapper.find('.category--item:not(.category--action)');
            const items = wrapper.find('.category--item:not(.category--action):has(.category--action__delete)');

            items.each(function () {
                const ta = $(this);
                const text = ta.find('.category--item__text');
                const del = ta.find('.category--action__delete');

                del.off('click').on('click', () => {
                    if (!$.trim(callbackDelete)) {
                        return false;
                    }

                    const cb = evaluate(callbackDelete);
                    if (typeof cb === 'function') {
                        cb.call(null, ta, text.text());
                    }
                });
            });

            const onSelect = (ta) => {
                if (ta.is('.is-active')) {
                    return false;
                }

                if ($.trim(callbackSelect)) {
                    const cb = evaluate(callbackSelect);
                    if (typeof cb === 'function') {
                        cb.call(null, ta);
                    }
                }
            };

            itemsCanSelect.off('click').on('click', function () {
                const ta = $(this);

                onSelect(ta);

                return false;
            });

            items.off('click').on('click', function () {
                const ta = $(this);
                const text = ta.find('.category--item__text');
                const originText = text.text().trim();

                text.prop('contenteditable', true);
                text.toggleClass('is-active', true);

                onSelect(ta);

                setTimeout(function () {
                    if (!text.is(':focus')) {
                        text.prop('contenteditable', false);
                        text.toggleClass('is-active', false);
                    } else if (selected === false) {
                        console.log(`selected`, selected);
                        selectContent(text.get(0));
                        selected = true;
                    }
                }, 300);

                const proc = (save = true) => {
                    const newText = text.text().trim();
                    text.prop('contenteditable', false);
                    text.toggleClass('is-active', false);
                    text.off('keydown').off('blur');

                    if (!$.trim(callbackEdit) || save === false) {
                        text.text(originText);
                        return false;
                    }

                    const cb = evaluate(callbackEdit);
                    if (typeof cb === 'function' && newText !== originText) {
                        cb.call(null, ta, newText, originText, () => {
                            proc.call(null, false);
                        });
                    }
                };

                text.one('blur', () => {
                    clearTimeout(timerExec);
                    timerExec = setTimeout(() => {
                        text.off('keydown').off('blur');
                        proc.call(null);
                    }, 100);
                });
                text.off('keydown').on('keydown', function (e) {
                    const isEnter = e.which == 13;
                    const isEsc = e.which == 27;

                    console.log('category::bar::item::text::keydown', isEnter, isEsc);

                    if (isEnter || isEsc) {
                        e.preventDefault();

                        text.off('keydown').off('blur');

                        clearTimeout(timerExec);
                        timerExec = setTimeout(() => {
                            proc.call(null, isEnter === true);
                        }, 100);
                    }
                });

                return false;
            });

            // console.log(itemsCanSelect.find('[data-value="all"]'));

            // if (itemsCanSelect.find('.is-active').length === 0) {
            //     itemsCanSelect.find('[data-value="all"]').toggleClass('is-active', true);
            // }
        };

        const setPositions = () => {
            const items = wrapper.find('.category--item:not(.category--action)');

            positions = [];

            items.each(function () {
                const el = $(this);
                const pos = parseInt(wrapper.scrollLeft() + (el.offset().left - wrapper.offset().left));
                positions.push(pos);
            });
        };

        const calc = () => {
            const top = parseInt(wrapper.scrollLeft());
            const bottom = parseInt(wrapper.get(0).scrollWidth - (top + wrapper.innerWidth()));

            setPositions();

            current = bis(positions, top);

            prev.css('display', top <= 0 ? 'none' : 'flex');
            next.css('display', bottom <= 0 ? 'none' : 'flex');
        };

        wrapper.on('scroll', calc);

        next.on('click', () => {
            wrapper.scrollLeft(positions[current + 1] - 30);
        });

        prev.on('click', () => {
            const p = positions[current - 1 < 0 ? 0 : current - 1];
            wrapper.scrollLeft(p + (p === 0 ? 0 : -30));
        });

        add.on('click', () => {
            if (!$.trim(callbackAdd)) {
                return false;
            }

            const cb = evaluate(callbackAdd);
            if (typeof cb === 'function') {
                cb.call(null);
            }
        });

        calc();
        sleep(400).then(() => {
            setEditEvent();
        });

        cleanup(() => {
            // $(window).off('resize');
            wrapper.off('scroll');
            next.off('click');
            prev.off('click');
            add.off('click');
            // ta.off('change');
        });
    });
}
