export const registerLinkHelper = (alpine) => {
	const body = $('body');

	alpine.directive('link', (el, {}, { cleanup }) => {
		const ta = $(el);
		ta.on('click', function (e) {
			const href = ta.attr('href');
			alpine.store('router').go(href);
			e.preventDefault();
		});

		body.toggleClass('sidebar-enable', false);

		cleanup(() => {
			ta.off('click');
		});
	});
};
