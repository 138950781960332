import { sleep } from './utils';

export default function (Alpine) {
    Alpine.directive('auto-height', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);
        const defs = expression || '36px';

        const autoSize = () => {
            ta.height(defs);
            ta.height(`${ta.prop('scrollHeight')}px`);
        };

        sleep(450).then(() => {
            autoSize();
        });

        ta.on('input change', () => {
            autoSize();
        });

        cleanup(() => {
            ta.off('input change');
        });
    });
}
