import Alpine from 'alpinejs';
import Controller from './controller';
import ShortUniqueId from 'short-unique-id';

import { menuSave, menuGet, pressList } from '../libs/api';
import { sleep } from '../helpers/utils';

class Menu extends Controller {
    // filterItems = [{ id: 1, name: '編號', type: 'int', col: 'id' }];

    data(params) {
        return {
            title: '選單設定',
            type: 1, // 1:main;2:sub;
            loaded: false,
            init() {},
            data: { 1: [], 2: [] },
            order: { 1: [], 2: [] },
            isChanged: false,

            pages: [],

            init() {
                this.get();
                this.getPages();

                const modal = Alpine.store('modal');

                let done = () => {};

                const leavingCallback = function (e) {
                    const { detail } = e;
                    const { isChanged } = Alpine.store('info');

                    done = detail.done;

                    console.log(isChanged, e, '<-----------');

                    if (isChanged === true) {
                        sendEvent('modal', {
                            html: '/pages/setting/menu-confirm-leave.html',
                        });

                        detail.done(false);

                        return false;
                    }

                    leavingConfirmCallback();
                };

                const leavingConfirmCallback = function () {
                    modal.close();

                    window.removeEventListener('page.setting.menu.leaving', leavingCallback);
                    window.removeEventListener('page.setting.menu.leaving.confirm', leavingConfirmCallback);

                    done();
                };

                window.addEventListener('page.setting.menu.leaving', leavingCallback);
                window.addEventListener('page.setting.menu.leaving.confirm', leavingConfirmCallback);
            },

            async get() {
                const { code, data } = await menuGet(this.data);

                if (code === 1) {
                    this.data = data.rows;
                    this.loaded = true;
                }
            },

            async save() {
                const { code, data } = await menuSave({ menus: Alpine.raw(this.data) });
                this.data = {};
                let notify = { title: '失敗' };

                if (code === 1) {
                    notify = {
                        title: '成功',
                        icon: 'bi-file-earmark-check',
                    };

                    sleep(10).then(() => {
                        this.data = data.rows;

                        window.sendEvent('validation::done');
                    });

                    this.isChanged = false;
                }

                window.sendEvent('toast', notify);
            },

            async getPages() {
                const { code, data } = await pressList('page', {
                    query: {
                        status: {
                            col: 'status',
                            type: 'hidden',
                            value: 1,
                        },
                    },
                });

                if (code === 1) {
                    this.pages = data.rows;
                }
            },

            changed() {
                const that = this;

                return () => {
                    const info = Alpine.store('info');
                    info.isChanged = true;
                };
            },

            newElement(type, parent_id = null) {
                const generator = new ShortUniqueId();
                const id = generator();

                const current = this.data[this.type];
                let parent_sort = current.length; // (current.length === 0 ? 1 : Math.max(...current.map((o) => o.sort))) + 1;

                if (parent_id !== null) {
                    const result = current.find((o) => o.id === parent_id);
                    parent_sort = result.children.length;
                }

                if (type === 1) {
                    return {
                        id: id,
                        type: 1,
                        name: '',
                        link: '',
                        parent_id: parent_id,
                        is_new: true,
                        sort: parent_sort,
                    };
                } else {
                    const childID = generator();

                    return {
                        id: id,
                        type: 2,
                        name: '',
                        is_new: true,
                        parent_id: null,
                        sort: parent_sort,
                        children: [
                            {
                                id: childID,
                                type: 1,
                                name: '',
                                link: '',
                                parent_id: id,
                                is_new: true,
                                sort: 0,
                            },
                        ],
                    };
                }
            },

            add(type, parent_id = null) {
                const current = this.data[this.type] || [];

                if (typeof this.data[this.type] === 'undefined') {
                    this.data[this.type] = [];
                }

                if (parent_id === null) {
                    this.data[this.type].push(this.newElement(type));
                } else {
                    const taIdx = current.findIndex((m) => m.id == parent_id);
                    this.data[this.type][taIdx].children.push(this.newElement(type, parent_id));
                }
            },

            del(id, parent_id = null) {
                const current = this.data[this.type];

                if (parent_id === null) {
                    this.data[this.type] = current.filter((m) => m.id != id);
                } else {
                    const parentIdx = current.findIndex((m) => m.id == parent_id);
                    const children = this.data[this.type][parentIdx].children;

                    this.data[this.type][parentIdx].children = children.filter((child) => child.id != id);
                }
            },

            sort() {
                return (event) => {
                    const target = $(event.target);

                    const { data, type } = Alpine.store('info');
                    const current = data[type];

                    const is_parent = target.is('.is-parent');
                    const is_children = target.is('.is-children');

                    const sortOrder = target
                        .children('[data-id]')
                        .map(function () {
                            return parseInt($(this).data('id'));
                        })
                        .get();

                    if (is_parent) {
                        current.map((item) => (item.sort = sortOrder.indexOf(item.id)));
                    }

                    if (is_children) {
                        const parent = target.closest('[data-id]');
                        const parent_id = parent.data('id');

                        const idx = current.findIndex((item) => item.id === parent_id);

                        current[idx].children.map((child) => (child.sort = sortOrder.indexOf(child.id)));
                    }

                    console.log(sortOrder);
                };
            },
        };
    }
}

export const menu = new Menu();
