import Alpine from 'alpinejs';
import Controller from './controller';
import ShortUniqueId from 'short-unique-id';
import timezones from 'timezones-list';

import { siteSettingSave, siteSettingProfile, elementImageUpload } from '../libs/api';
import { sleep, uploadProgress } from '../helpers/utils';

class General extends Controller {
    // filterItems = [{ id: 1, name: '編號', type: 'int', col: 'id' }];

    data(params) {
        return {
            title: '一般設定',
            type: 1, // 1:main;2:sub;
            loaded: false,

            init() {
                this.get();
            },

            data: {
                title: '',
                description: '',
                tracking: '',
                timezone: '',
                language: '',
            },

            async get() {
                const {
                    code,
                    data: { site, meta },
                } = await siteSettingProfile();

                if (code === 1) {
                    const auth = Alpine.store('auth');

                    this.data.title = site.name;
                    this.data.description = site.content;
                    this.data.tracking = site.tracking;

                    this.data.timezone = !meta.timezone
                        ? Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Taipei'
                        : meta.timezone;

                    this.data.language = auth.meta.language || 'zh-tw' || navigator.language || navigator.userLanguage;

                    if (meta.logo) {
                        this.logo.data.cover = meta.logo || null;
                    }

                    if (meta.seo.cover) {
                        this.seo.data.cover = meta.seo.cover || null;
                    }

                    if (meta.seo.title) {
                        this.seo.data.title = meta.seo.title || '';
                    }

                    if (meta.seo.description) {
                        this.seo.data.description = meta.seo.description || '';
                    }

                    this.loaded = true;
                }
            },

            async save() {
                let payload = { ...this.data };
                payload.logo = this.logo.data.cover || null;
                payload.seo = this.seo.data || {};
                let notify = { title: '失敗' };

                const { code } = await siteSettingSave(payload);

                if (code === 1) {
                    notify = {
                        title: '成功',
                        icon: 'bi-file-earmark-check',
                    };

                    window.sendEvent('toast', notify);

                    const auth = Alpine.store('auth');
                    await auth.refresh.apply(auth);
                }

                window.sendEvent('validation::done');
            },

            timezone: {
                list: timezones,
            },

            logo: {
                data: {
                    cover: null,
                },
                import() {
                    let that = Alpine.store('info');

                    return async (files, limit) => {
                        const result = await uploadProgress(files, limit);
                        if (result.success === false) {
                            return result;
                        }

                        that.logo.data.cover = result.path;

                        return result;
                    };
                },
            },

            seo: {
                data: {
                    cover: null,
                    title: '',
                    description: '',
                },
                import() {
                    let that = Alpine.store('info');
                    return async (files, limit) => {
                        const result = await uploadProgress(files, limit);
                        if (result.success === false) {
                            return result;
                        }

                        that.seo.data.cover = result.path;

                        return result;
                    };
                },
            },
        };
    }
}

export const general = new General();
