import {} from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('confirm', (modal) => ({
        init() {
            console.log('confirm::init', modal, '<<<<');
        },

        confirm: {
            ['@click']() {
                modal.onConfirm.apply(null, this.$el);

                modal.close();
            },
        },

        cancel: {
            ['@click']() {
                modal.close();
            },
        },

        message: {
            ['x-text']() {
                return modal.message || '';
            },
        },
    }));
}
