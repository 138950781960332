import { themeList, themeApply } from '../libs/api';
import { uploadProgress, buttonFlow } from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('theme', () => ({
        list: {
            loaded: false,
            data: { rows: [] },
            async fetch() {
                const { code, data } = await themeList();

                if (code === 1) {
                    this.data.rows = data.rows;
                    this.loaded = true;
                }
            },

            async apply(id) {
                const { code } = await themeApply({ id });
                if (code === 1) {
                    await this.fetch();
                }
            },
        },
        init() {
            this.list.fetch();
        },

        apply: {
            ['@click']() {
                this.list.apply(this.theme.id);
            },
        },
        preview: {
            [':href']() {
                return 'https://' + this.$site.current.domain + '/theme/' + this.theme.id + '/preview';
            },
        },

        themeUpload: {
            ['@click']() {
                $(this.$refs.themeFile).click();
            },
        },

        themeFile: {
            [':class']() {
                return 'hidden';
            },
            ['x-show']() {
                return false;
            },
            [':accept']() {
                return '.zip';
            },
            async ['@change']() {
                const ta = this.$event.target;
                const btn = buttonFlow($(this.$refs.themeUpload));
                const files = ta.files;

                btn.loading();

                if (files.length === 0) {
                    console.log(files, 'stop');
                    btn.reset();
                    return false;
                }

                const result = await uploadProgress(files, { filesize: 20 });
                if (result.success === true) {
                    this.list.fetch();
                }

                window.sendEvent('toast', { title: result.message });

                btn.done();
                ta.value = '';
            },
        },

        item: {
            [':class']() {
                return this.theme.owner === false ? '' : 'custom';
            },
        },

        setting: {
            ['@click']() {
                sendEvent('modal', {
                    title: `主題樣式 - ${this.theme.name}`,
                    html: '/pages/page/theme/detail.html',
                    closeBtn: true,
                    data: { id: this.theme.id },
                });
            },
        },
    }));
}
