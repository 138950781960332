const toggle = (ele, menu, forceClose = false) => {
	const isShown = ele.is('.show');

	ele.toggleClass('show', forceClose ? false : !isShown);
	menu.toggleClass('show', forceClose ? false : !isShown);
};

export const registerDropdownHelper = (alpine) => {
	alpine.directive('dropdown', (el, { expression }, { cleanup }) => {
		const ta = $(el);
		const parent = ta.parent();
		const menu = parent.find(`${expression}`);

		ta.off('click').on('click', function (e) {
			e.preventDefault();
			toggle(ta, menu);
		});

		parent.off('mouseleave').on('mouseleave', () => {
			toggle(ta, menu, true);
		});
		
		parent.off('blur').on('blur', () => {
			toggle(ta, menu, true);
		});

		cleanup(() => {
			ta.off('click');
			parent.off('mouseleave blur');
		});
	});
};
