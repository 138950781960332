import Alpine from 'alpinejs';
import Controller from './controller';

import { memberChange } from '../libs/api';
// import { sleep } from '../helpers/utils';

class Password extends Controller {
    filterItems = [];

    data() {
        this.filterItems = [];

        return {
            type: { current: false, new: false, rtn: false },
            model: { current: '', new: '', rtn: '' },
            init() {},

            async submit() {
                const { code } = await memberChange({
                    current: this.model.current,
                    new: this.model.new,
                });

                if (code === 1) {
                    const router = Alpine.store('router');
                    router.go('setting/account');
                }
            },
        };
    }
}

export const password = new Password();
