export default function (Alpine) {
    Alpine.data('sidebar', () => ({
        noplan: {
            ['@click']() {
                sendEvent('modal', {
                    title: '尚未訂閱方案',
                    html: '/pages/plan/confirm-noplan.html',
                    closeBtn: true,
                });
            },
        },

        notpublished: {
            ['@click']() {
                sendEvent('modal', {
                    title: '立即發布網站',
                    html: '/pages/plan/confirm-publish.html',
                    closeBtn: true,
                });
            }
        }
    }));
}
