import Alpine from 'alpinejs';
import Controller from './controller';
import menu from '../libs/menu.json';

class Design extends Controller {
	data() {
		return {
			title: '網站設計',
			design: {},
			themes: {
				status: false,
				list: [],
				async get() {
					const result = await Alpine.store('api').getClient().post('theme/collection');
					if (result.data.rows.length > 0) {
						this.list = result.data.rows;
					}

					this.status = true;
				},
				async active(id) {
					const result = await Alpine.store('api').getClient().post('theme/apply', {
						id: id,
					});
					if (result.code === 1) {
						window.sendEvent('toast', {
							title: '網站設計已切換',
							message: ``,
						});

						const current = Alpine.store('router').current();
						console.log(current, '<--')
						Alpine.store('auth').status(current, true);
					}
				},
			},
		};
	}
}

export const design = new Design();
