// import {} from '../libs/api';
// import {} from '../helpers/utils';

export default function (Alpine) {
    const query = Alpine.store('query');
    const paginateTemplate = `
        <div class="btn-group-paginate">
            <button
                type="button"
                class="btn btn-white btn-sm"
                x-bind="prev"
            >
                <i class="bi bi-chevron-left feather-sm"></i>
            </button>

            <template x-for="i in $paginate((meta.pos + 1), 5, meta.count)">
                <button
                    type="button"
                    class="btn btn-link btn-sm"
                    x-bind="page"
                ></button>
            </template>

            <button
                type="button"
                class="btn btn-white btn-sm"
                x-bind="next"
            >
                <i class="bi bi-chevron-right feather-sm"></i>
            </button>
        </div>
    `;

    Alpine.data('paginate', (meta, callback = () => {}) => ({
        init() {
            this.meta = meta;

            $(this.$el).html(paginateTemplate);

            Alpine.initTree(this.$el.querySelector('.btn-group-paginate'));

            // this.$el.innerHTML = paginateTemplate;

            // Alpine.initTree(paginateTemplate)
        },

        meta: {
            pos: 0,
            count: 1,
            total: 0,
        },

        changePos(pos) {
            this.meta.pos = +pos;

            query.midify({ pos: this.meta.pos });

            callback.apply(null);
        },

        prev: {
            ['@click.prevent']() {
                this.changePos(this.meta.pos - 1);
            },
            [':disabled']() {
                return this.meta.pos == 0;
            },
        },

        next: {
            ['@click.prevent']() {
                this.changePos(this.meta.pos + 1);
            },
            [':disabled']() {
                return this.meta.pos + 1 == this.meta.count;
            },
        },

        page: {
            ['x-text']() {
                return this.i;
            },
            [':class']() {
                return {
                    active: this.i - 1 === this.meta.pos,
                    static: this.i === '...',
                };
            },
            [':disabled']() {
                return this.i - 1 === this.meta.pos || this.i === '...';
            },
            ['@click.prevent']() {
                this.changePos(this.i - 1);
            },
        },
    }));
}
