import SimpleBar from 'simplebar';
import feather from 'feather-icons';

export const registerSidebarHelper = (alpine) => {
	alpine.directive('sidebar', (el, {}, { cleanup }) => {
		const sidebar = new SimpleBar(el);

		cleanup(() => {
			sidebar.unMount();
		});
	});
};
