import Iodine from '@caneara/iodine';

import { memberWriterVerify, profileSave } from '../libs/api';
import { sleep, uploadProgress } from '../helpers/utils';

export default function (Alpine) {
    const goBackToProfile = () => {
        Alpine.store('router').go('profile');
    };

    Alpine.data('profile', () => ({
        init() {
            const {
                user: { is_verified, email, nickname, account, avatar },
            } = Alpine.store('auth');

            const that = this;

            this.data.is_verified = +is_verified === 1 ? true : false;
            this.data.email = email;
            this.data.nickname = nickname;
            this.data.account = account;
            this.data.avatar = avatar;
            this.data.language = 'zh-tw' || navigator.language || navigator.userLanguage;

            this.$watch('data.email', (value) => {
                if (email === value) {
                    that.data.is_verified = true;
                } else {
                    that.data.is_verified = false;
                }
            });
        },

        data: {
            account: '',
            nickname: '',
            email: '',
            is_verified: false,
            is_new: false,
            token: null,
            verify: null,
            avatar: null,
        },

        upload() {
            const that = this;
            return async (files, limit) => {
                const result = await uploadProgress(files, limit);
                if (result.success === true) {
                    that.data.avatar = result.path;

                    const { code } = await profileSave({ avatar: result.path });
                    if (code === 1) {
                        window.sendEvent('toast', { title: '儲存成功', icon: 'bi-floppy' });
                        Alpine.store('auth').refresh();
                    }
                }

                return result;
            };
        },

        profile: {
            ['@click']() {
                Alpine.store('router').go('profile/edit');
            },
        },
        password: {
            ['@click']() {
                Alpine.store('router').go('setting/password');
            },
        },
        account: {
            [':value']() {
                return this.data.account;
            },
        },
        nickname: {
            [':value']() {
                return this.data.nickname;
            },
        },
        language: {
            async ['@change']() {
                const ta = $(this.$el);
                const { code } = await profileSave({ language: ta.find(':selected').val() });
                if (code === 1) {
                    window.sendEvent('toast', { title: '儲存成功', icon: 'bi-floppy' });
                    Alpine.store('auth').refresh();
                }
            },
        },
        verify: {
            async ['@click']() {
                if (this.data.is_verified === true) {
                    return false;
                }

                const that = this;
                const iodine = new Iodine();
                const result = iodine.assert(this.data.email, ['required', 'email']);

                if (result.valid === false) {
                    window.sendEvent('toast', { title: '無效的電子信箱', icon: 'bi-envelope-slash' });
                    return false;
                }

                const { code, data } = await memberWriterVerify({
                    email: this.data.email,
                    token: this.data.token,
                });

                if (code === 1) {
                    sendEvent('altcha::verify');

                    const verify = Alpine.store('verify');
                    verify.display(
                        data.token,
                        data.ts,
                        {
                            email: this.data.email,
                        },
                        '信箱驗證',
                        (data) => {
                            that.data.verify = data.token;
                            that.data.is_verified = true;
                            that.data.is_new = true;
                        },
                    );
                }
            },
            [':disabled']() {
                return this.data.is_verified === true;
            },
            ['x-text']() {
                return this.data.is_verified === false ? '發送驗證信' : '已完成驗證';
            },
        },

        async save() {
            const {
                user: { email: oldEmail, nickname: oldNickName },
            } = Alpine.store('auth');
            const { nickname, email, is_verified, is_new, token, verify } = this.data;

            if (is_verified === false) {
                window.sendEvent('toast', { title: '請先驗證電子信箱', icon: 'bi-envelope-slash' });
                window.sendEvent('validation::reset');
                return false;
            }

            let payload = { token };

            if (oldNickName != nickname) {
                payload.nickname = nickname;
            }

            if (oldEmail != email && verify != null && is_verified === true) {
                payload.email = email;
                payload.verify = verify;
            }

            const { code } = await profileSave(payload);
            if (code === 1) {
                window.sendEvent('toast', { title: '儲存成功', icon: 'bi-floppy' });
                Alpine.store('auth').refresh();
                goBackToProfile();
            }
        },
    }));
}
