import Alpine from 'alpinejs';
import Controller from './controller';
import ShortUniqueId from 'short-unique-id';

import { ordersList, orderGet, orderSave /*logisticsSave, logisticsGet*/ } from '../libs/api';
// import {  } from '../helpers/utils';

class Orders extends Controller {
    filterItems = [];

    data() {
        return {
            init() {
                const id = +this.id;
                if (id !== 0) {
                    this.item.data.id = id;
                    // this.item.mode = 'edit';
                }
            },
            list: {
                loaded: false,
                data: {},
                async init() {
                    await this.get();
                },
                async get() {
                    const query = Alpine.store('query');

                    const { code, data } = await ordersList({ query: query.fetch() });

                    if (code === 1) {
                        this.data = data;
                        this.loaded = true;
                    }
                },
            },

            item: {
                async init() {
                    await this.load();
                },

                loaded: false,
                data: { id: null },

                async load() {
                    const { code, data } = await orderGet({ id: this.data.id });

                    if (code === 1) {
                        this.data = data;
                    }

                    this.loaded = true;
                },

                async save() {
                    const { code } = await orderSave({
                        id: this.data.id,
                        status: parseInt(this.data.status),
                        code: this.data.logistic.code,
                    });

                    window.sendEvent('validation::done');

                    if (code === 1) {
                        const generator = new ShortUniqueId();
                        Alpine.store('router').go(`/orders/${this.data.id}?t=${generator()}`);
                    }
                },
            },
        };
    }
}

export const orders = new Orders();
