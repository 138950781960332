import Alpine from 'alpinejs';
import Controller from './controller';
import { groupBy } from '../helpers/utils';

class Cate extends Controller {
	preload(_$routeParams) {
		if (!_$routeParams?.type) {
			Alpine.store('router').go('/file/index');
			return false;
		}

		Alpine.store('info.cate', this.data(_$routeParams));
	}
	data() {
		return {
			title: '檔案管理',
			file: {},
			cate: [],
			cates: this.cates,
		};
	}

	async cates() {
		const cachedCate = Alpine.store('file.cate');
		if (cachedCate) {
			this.cate = Alpine.store('file.cate');
			return false;
		}

		const result = await Alpine.store('api').getClient().post('page/cate');
		const group = groupBy(result.data?.rows, 'type');
		this.cate = group;

		Alpine.store('file.cate', group);
	}
}

class File extends Controller {
	type = null;
	preload(_$routeParams) {
		this.type = _$routeParams.type;
		Alpine.store('info.file', this.data(_$routeParams));
	}

	data(params) {
		return {
			page: {
				file: [],
				file_id: 0,
			},
			files: null,
			tabs: [],
			all: this.all,
			active: this.active,
			tabList: this.tabList,
			setTab: this.setTab,
			getTab: this.getTab,
			checkKid: this.checkKid,
			kid: '',
			tabData: {
				name: '',
				slug: '',
			},

			...params,
		};
	}

	checkKid() {
		if (this.kid === '' && this.tabs.length > 0) {
			this.kid = this.tabs[0].slug;
			Alpine.store('router').go(`/file/${this.type}/${this.kid}`);
			// this.all(this.kid);
		} else if (this.kid) {
			this.all(this.kid);
		}
	}

	async all(type) {
		this.kid = type ?? this.type;

		const result = await Alpine.store('api').getClient().post('page/list', {
			type: this.kid,
		});

		this.page = result.data ?? {
			file: [],
			file_id: 0,
		};
	}

	async tabList() {
		const cachedCate = Alpine.store(`file.tab.${this.type}`);
		if (cachedCate) {
			this.tabs = Alpine.store(`file.tab.${this.type}`);

			this.checkKid();
			return false;
		}

		const result = await Alpine.store('api').getClient().post('page/children', {
			type: this.type,
		});
		this.tabs = result.data?.rows;

		this.checkKid();

		Alpine.store(`file.tab.${this.type}`, result.data?.rows);
	}

	async setTab() {
		const result = await Alpine.store('api')
			.getClient()
			.post('page/set_tab', {
				type: this.type,
				...this.tabData,
			});

		Alpine.store(`file.tab.${this.type}`, false);
		await this.tabList();
	}

	async getTab(name, file = false) {
		if (name !== '') {
			const result = await Alpine.store('api').getClient().post('page/get_tab', {
				type: this.type,
			});

			this.tabData = result.data;
		}

		window.sendEvent('modal', {
			html: file ? '/pages/file/add-file.html' : '/pages/file/add-child.html',
			title: name !== '' ? '編輯頁面' : '新增頁面',
		});
	}

	async active(file_id) {
		const type = this.kid ?? this.type;
		const result = await Alpine.store('api').getClient().post('page/active', {
			file_id: file_id,
			type: type,
		});

		this.all(type);

		window.sendEvent('toast', {
			title: '成功',
			message: '成功完成啟用樣板',
		});
	}
}

class Editor extends Controller {
	data(params) {
		return {
			title: '編輯器',
			page: {
				content: '',
			},
			get: this.get,
			save: this.save,

			...params,
		};
	}

	async get() {
		if (+this.id === 0) {
			return false;
		}

		const result = await Alpine.store('api').getClient().post('page/get', {
			id: this.id,
			type: this.type,
			action: this.action,
		});

		this.page = result?.data;
	}

	async save() {
		const type = this.kid ?? this.type;
		const result = await Alpine.store('api').getClient().post('page/save', {
			id: this.id,
			type: type,
			action: this.action,
			content: this.page.content,
		});

		if ('code' in result && result.code === 1) {
			Alpine.store('router').go(`file/${this.type}` + (this.kid ? `/${this.kid}` : ''));

			return;
		}
	}
}

export const file = new File();
export const cate = new Cate();
export const editor = new Editor();
