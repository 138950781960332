import urlcat from 'urlcat';
import pinyin from 'tiny-pinyin';
import axios from 'axios';

import { elementImageUpload, elementThemeUpload } from '../libs/api';

export const groupBy = function (arr, f) {
    return arr.reduce((out, val) => {
        let by = typeof f === 'function' ? '' + f(val) : val[f];
        (out[by] = out[by] || []).push(val);
        return out;
    }, {});
};

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const pluck = (arr, key) => arr.map((i) => i[key]);

export const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
};

export const jsonParse = (string, def = {}) => {
    let data = null;
    try {
        data = JSON.parse(string);
    } catch (e) {
        data = def;
    }

    return data;
};

export const _uuid = () => {
    let d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};

export const onlyLetters = (str) => {
    return str.replace(/^[\s\uFEFF\xA0\u3000]+|[\s\uFEFF\xA0\u3000]+$/g, '').replace(/[！-～;；：，。？““"〃]+/g, '');
};

export const url = (baseUrl, pathTemplate = '', params = {}) => {
    return urlcat(baseUrl, pathTemplate, params);
};

export const slug = (text) =>
    text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[-]+/g, '-')
        .replace(/[_]+/g, '-')
        .replace(/[^\w-]+/g, '');

export const validSlug = (str) => /^[a-z][-a-z0-9]*$/.test(str);

export const getImageSize = (file) =>
    new Promise(function (resolve) {
        const reader = new FileReader();

        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
                const { height, width } = image;

                resolve({ height, width });
            };
        };

        reader.readAsDataURL(file);
    });

export const isImage = (file) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    return validImageTypes.includes(file['type']);
};

export const uploadProgress = async (files, limit, additions = {}) => {
    if (files.length === 0) {
        return false;
    }

    const file = files[0];
    const is_image = isImage(file);

    const payload = { ...additions, file: file };

    if (file.size / 1024 ** 2 >= limit.filesize) {
        return { success: false, message: '檔案大小超過限制' };
    }

    if (is_image) {
        payload.limit = JSON.stringify(limit);
        // const { height, width } = await getImageSize(file);
        // if (width > limit.imagesize.w || height > limit.imagesize.h) {
        //     return { success: false, message: `檔案尺寸(${width}x${height})超過限制` };
        // }

        // const ratio = Math.round((width / height + Number.EPSILON) * 100) / 100;
        // if (limit.ratio != 0 && limit.ratio !== ratio) {
        //     return { success: false, message: `檔案比例(${ratio})超過限制` };
        // }
    }

    try {
        const {
            code,
            data: { path },
        } = is_image ? await elementImageUpload(payload) : await elementThemeUpload(payload);

        return { success: true, message: '檔案上傳成功。', path };
    } catch (error) {
        return { success: false, message: '檔案上傳失敗。' };
    }
};

export const toPinyin = (text) => {
    if (pinyin.isSupported() === false) {
        console.log('Does not support automatic generation of slugs.');
        return text;
    }

    const structs = pinyin.parse(text);
    let slug = '';

    structs.map((struct) => {
        const { type, target } = struct;
        const letter = `${target.toLowerCase()}`;
        const last = slug.at(-1);

        if (type === 2) {
            slug = `${slug}${letter}-`;
        } else if (type === 1) {
            if (/^[a-z0-9]$/.test(letter)) {
                slug = `${slug}${letter}`;
            } else if (/^[a-z0-9]$/.test(last)) {
                slug = `${slug}-`;
            }
        }
    });

    return slug.trim().replace(/-$/, '');
};

export const pick = (obj, keys) => Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]]));

export const get = (obj, path, def = undefined) => {
    let paths = path.split('.'),
        current = obj,
        i;

    if (obj === undefined) {
        return def;
    }

    for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
            return def;
        } else {
            current = current[paths[i]];
        }
    }

    return current;
};

export const buttonFlow = (btn) => {
    if (btn instanceof $ === false) {
        return false;
    }

    const loadingIcon = `<span class="spinner-border spinner-border-sm me-2 js-spinner" role="status" aria-hidden="true"></span>`;
    const finishIcon = `<span class="js-finish"><i class="bi bi-check-circle me-2 "></i></span>`;

    let button = btn;
    if (button.is('button') === false) {
        button = button.closest('button');
    }

    // console.log('utils::button::flow', btn, button);

    return {
        lock() {
            button.prop('disabled', true);
            button.find('i').show();
            button.find('.js-finish,.js-spinner').remove();
        },
        loading() {
            button.find('i').hide();
            button.prop('disabled', true);
            button.prepend(loadingIcon);
        },
        done(force = true) {
            const that = this;

            button.find('.js-spinner').remove();
            button.prepend(finishIcon);

            sleep(2000).then(() => {
                force && button.prop('disabled', false);
                button.find('.js-finish').remove();
                button.find('i').show();
            });
        },
        reset() {
            button.prop('disabled', false);
            button.find('.js-finish,.js-spinner').remove();
            button.find('i').show();
        },
    };
};

const templates = new Map();
const injectedClass = 'inited';

export const routeHandler = (navigo, template, taElementSelector, controller, params) =>
    new Promise((resolve) => {
        const taElement = document.querySelector(taElementSelector);
        // const isWrapper = taElement.id === 'wrapper';

        if (taElement) {
            const eleObserver = new MutationObserver((mutations) => {
                mutations.forEach((_mutation) => {
                    if (controller) {
                        eleObserver.disconnect();
                        navigo.updatePageLinks();
                        controller.run(params);
                        resolve();
                    }
                });
            });

            // TODO: optimizing screen flickering issues.
            // if (isWrapper && taElement.classList.contains(injectedClass)) {
            //  resolve();
            //  return false;
            // }

            // taElement.innerHTML = '';

            sleep(100).then(() => {
                eleObserver.observe(taElement, { childList: true });
                controller.preload(params);

                const content = templates.get(template);
                if (content) {
                    taElement.innerHTML = content;
                } else {
                    fetch(template).then((response) =>
                        response.text().then((content) => {
                            templates.set(template, content);
                            taElement.innerHTML = content;
                        }),
                    );
                }

                // if (isWrapper) {
                //  taElement.classList.add(injectedClass);
                // }
            });
        }
    });

export const bisector = (accessor) => {
    return {
        left: function (a, x, lo = 0, hi = a.length) {
            while (lo < hi) {
                var mid = (lo + hi) >>> 1;
                if (accessor(a[mid]) < x) lo = mid + 1;
                else hi = mid;
            }
            return lo;
        },
        right: function (a, x, lo = 0, hi = a.length) {
            while (lo < hi) {
                var mid = (lo + hi) >>> 1;
                if (accessor(a[mid]) > x) hi = mid;
                else lo = mid + 1;
            }
            return lo;
        },
    };
};

export const checkService = function (item) {
    const auth = window.Alpine.store('auth');
    const service = auth.site.current.service;

    if (service.hasOwnProperty('admin-full-power')) {
        return true;
    }

    return service.hasOwnProperty(item);
};

export const getServiceParam = function (item) {
    const auth = window.Alpine.store('auth');
    const service = auth.site.current.service;

    if (service.hasOwnProperty('admin-full-power')) {
        return true;
    }

    return service.hasOwnProperty(item) ? service[item] : null;
};

export const CacheCollection = function (key, expire = 1200) {
    const collection = {
        key: key,
        expire: expire,
        items: [],

        store(data) {
            localStorage.setItem(this.key, JSON.stringify(data));
            localStorage.setItem(this.key + ':ts', Date.now());
        },

        get() {
            let stored = localStorage.getItem(this.key);
            // console.log(stored);
            return this.isExpired() || !stored ? null : JSON.parse(stored);
        },

        isExpired() {
            let whenCached = localStorage.getItem(this.key + ':ts');
            let age = (Date.now() - whenCached) / 1000;
            // console.log('[CachedColletion] is Expired', age > this.expire);
            if (age > this.expire) {
                this.clear();
                return true;
            } else {
                return false;
            }
        },
        clear() {
            localStorage.removeItem(this.key);
            localStorage.removeItem(this.key + ':ts');
        },
    };

    return collection;
};

export const i18n = async () => {
    let userLang = 'zh-tw';

    if (window.navigator) {
        // userLang = navigator.language || navigator.userLanguage;
    }

    // userLang = localStorage.getItem('i18n::saved') || userLang;

    // console.log('The language is: ' + userLang);

    const cache = new CacheCollection(`i18n::lang`, 1 * 24 * 60 * 60);
    let cached = cache.get();

    if (cached === null || window.i18nVersion !== cached.version) {
        const { data } = await axios.get(`/i18n/lang.json`);
        cache.store(data);

        cached = data;
    }

    window.AlpineI18n.fallbackLocale = 'en';
    window.AlpineI18n.create(userLang.toLowerCase(), cached);
};

export const isValidHttpUrl = (str) => {
    let url;

    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const removeEmpty = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== ''));

export const reverseMapping = (o) =>
    Object.keys(o).reduce((r, k) => Object.assign(r, { [o[k]]: (r[o[k]] || []).concat(k) }), {});

// export const ascending = (a, b) => (a == null || b == null ? NaN : a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN);

// export const bisector = (func) => {
//  const compare1 = ascending;
//  const compare2 = (d, x) => ascending(f(d), x);

//  return {
//      right: (a, x, lo = 0, hi = a.length) => {
//          if (lo < hi) {
//              if (compare1(x, x) !== 0) return hi;
//              do {
//                  const mid = (lo + hi) >>> 1;
//                  if (compare2(a[mid], x) <= 0) lo = mid + 1;
//                  else hi = mid;
//              } while (lo < hi);
//          }
//          return lo;
//      },
//  };
// };
