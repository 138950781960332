import {
	memberStatus,
	memberWriterRegister,
	memberWriterLogin,
	memberLogout,
	memberWriterLoginByToken,
} from '../libs/api';

import TimeAgo from '@marcreichel/alpine-timeago';
import { zhTW, enUS, ja } from 'date-fns/locale';
import { checkService } from '../helpers/utils';

export const registerAuthService = (alpine) => {
	alpine.magic('user', () => {
		const auth = alpine.store('auth');
		return auth.user;
	});

	alpine.magic('site', () => {
		const auth = alpine.store('auth');
		return auth.site;
	});

	alpine.magic('site_current', () => {
		const auth = alpine.store('auth');
		return auth.site.current;
	});

	alpine.magic('access', () => {
		return checkService;
	});

	alpine.store('auth', {
		isAuthenticated: false,

		user: {},
		site: {},
		role: {},
		meta: {},
		// verify: { token: null, ts: 0 },

		approve(meta) {
			// console.log('auth::service::approve', meta);
			this.isAuthenticated = meta.isLogined === 1;
			this.user = meta.user;
			this.site = meta.site;
			this.role = meta.role;
			this.meta = meta.meta;

			if (meta.remeber) {
				let saved = localStorage.getItem('rmbr');
				saved = saved === null ? '{}' : saved;
				saved = JSON.parse(saved);

				saved[meta.remeber.site] = meta.remeber;

				localStorage.setItem('rmbr', JSON.stringify(saved));
			}

			if (this.user.meta && ['zh-tw', 'jp', 'en'].includes(this.user.meta.language)) {
				if (this.user.meta.language === 'zh-tw') {
					TimeAgo.configure({ locale: zhTW });
				} else if (this.user.meta.language === 'jp') {
					TimeAgo.configure({ locale: ja });
				} else {
					TimeAgo.configure({ locale: enUS });
				}

				AlpineI18n.locale = this.user.meta.language;
			}
		},

		reject() {
			this.isAuthenticated = false;
		},

		isWhitelisted(match) {
			return (
				match.url === 'auth/login' ||
				match.url === 'auth/logout' ||
				match.url === 'auth/register' ||
				match.url === 'auth/forgot' ||
				match.url === 'auth/forgot/success' ||
				match.url === 'auth/reset' ||
				match.url === 'auth/invite'
			);
		},

		async refresh() {
			const { code, data: status } = await memberStatus();

			this.approve(status);
		},

		async status(match, force = false) {
			const that = this;
			// console.log('auth::service::status', match, force);

			if (this.isAuthenticated && force === false) {
				return false;
			}

			const { code, data: status } = await memberStatus();
			if (!!status.isLogined === false) {
				// console.log('auth::service::status::redirectToLogin');
				// if (that.isWhitelisted(match) === false) {
				// 	router.go('/auth/login', { silent: true });
				// }

				return false;
			}

			this.approve(status);

			const router = alpine.store('router');
			const current = router.current();

			if (current.params) {
				if (current.params.goback) {
					router.go(current.params.goback);

					return true;
				}
			}

			if (match.url === 'auth/login') {
				router.go('/');
			}

			if (status.isManaged === false) {
				location.href = `https://${status.site.current.domain}`;
			}

			return true;
		},

		async signIn(data, type = 'user') {
			// console.log('AuthService::signin');
			let { code, data: status } = type === 'writer' ? await memberWriterLogin(data) : {};
			if (code === 1) {
				this.approve(status);
				this.status({ url: 'auth/login' }, true);

				return true;
			}

			return this.reject();
		},

		async signInByToken(data, type = 'user') {
			let { code, data: status } = type === 'writer' ? await memberWriterLoginByToken(data) : {};
			if (code === 1) {
				this.approve(status);
				this.status({ url: 'auth/login' }, true);

				return true;
			}

			return this.reject();
		},

		async signOut() {
			let { code } = await memberLogout();
			if (code === 1) {
				this.reject();
				alpine.store('router').go('/auth/login');
			}
		},

		async register(data, type = 'user', callback = () => {}) {
			// console.log('AuthService::register');
			let { code, data: token } = type === 'writer' ? await memberWriterRegister(data) : {};

			if (code === 1) {
				callback.apply(null, [data]);
				// const verify = alpine.store('verify');
				// return verify.display(token.token, token.ts, data, '信箱驗證', callback);
			}

			return this.reject();
		},
	});
};
