import { authorList, authorShow, authorSave, authorDelete } from '../libs/api';
import { buttonFlow, uploadProgress } from '../helpers/utils';

export default function (Alpine) {
    const query = Alpine.store('query');
    let authorData = Alpine.reactive({
        loaded: false,
        data: {
            rows: {},
        },
        get isEmpty() {
            return this.data.count === 0;
        },
        get meta() {
            return {
                pos: +this.data.pos || 0,
                count: +this.data.count || 0,
                total: +this.data.total || 0,
            };
        },
    });

    Alpine.data('author', (id = null) => ({
        init() {
            this.id = this.$store.info.id || null;

            console.log(this.id, id, '====><');

            if (this.id !== null && id === null) {
                sendEvent('modal', {
                    title: this.id == 0 ? '新增作者' : `編輯作者`,
                    html: '/pages/author/issue.html',
                    closeBtn: true,
                    data: { id: this.id },
                    onClose: () => {
                        Alpine.store('router').go('/author');
                    },
                });
            } else if (this.id !== null && id >= 0) {
                this.author.id = +id;
                this.author.load();
            } else if (this.id === null && id === null) {
                this.list.load();
            }
        },
        id: null,
        list: {
            // loaded: false,
            async load() {
                const { code, data } = await authorList({
                    query: query.fetch(),
                });

                authorData.loaded = true;

                if (code === 1 && data !== undefined) {
                    authorData.data = data;

                    query.midify(authorData.meta);
                }
            },
            get loaded() {
                return authorData.loaded;
            },
            get meta() {
                return authorData.meta;
            },
            get isEmpty() {
                return authorData.isEmpty;
            },
            get rows() {
                console.log(authorData, '<--');
                return authorData.data.rows;
            },
        },
        author: {
            id: null,
            mode: 'new',
            loaded: false,
            data: {
                name: '',
                title: '',
                avatar: null,
            },
            async load() {
                if (this.id === 0) {
                    this.loaded = true;

                    return false;
                }

                this.mode = 'edit';

                const { code, data } = await authorShow({
                    id: this.id,
                });

                this.loaded = true;

                if (code === 1) {
                    this.data.name = data.name;
                    this.data.title = data.title;
                    this.data.avatar = data.avatar;

                    if (data.member_id !== null) {
                        Alpine.store('router').go(`/author`);
                    }
                }
            },
            avatar() {
                const that = this;
                return async (files, limit) => {
                    const result = await uploadProgress(files, limit);
                    if (result.success === true) {
                        // console.log(result, that, '@@@');
                        that.author.data.avatar = result.path;

                        // const { code } = await profileSave({ avatar: result.path });
                        // if (code === 1) {
                        //     window.sendEvent('toast', { title: '儲存成功', icon: 'bi-floppy' });
                        //     Alpine.store('auth').refresh();
                        // }
                    }

                    return result;
                };
            },
        },
        edit: {
            ['@click']() {
                const id = this?.author?.id || 0;

                Alpine.store('router').go(`/author/${id}`);
            },
        },
        action: {
            ['x-show']() {
                return this.author.member_id === null;
            },
        },
        message: {
            ['x-show']() {
                return this.author.member_id !== null;
            },
            ['x-text']() {
                return this.author.member_id === null ? '' : '請透過 「我的帳號」 編輯。';
            },
        },
        save: {
            ['x-text']() {
                return this.author.mode === 'new' ? '新增' : '編輯';
            },
            async ['@click']() {
                const ta = $(this.$el);
                const btn = buttonFlow(ta);

                try {
                    btn.loading();
                    const { code, data } = await authorSave({ ...this.author.data, id: this.author.id });
                    if (code === 1) {
                        window.sendEvent('toast', {
                            title: `作者 「${this.author.data.name}」 已${this.author.mode === 'new' ? '新增' : '更新'}。`,
                        });
                        this.m.close();
                    }

                    btn.done();
                } catch (error) {
                    console.error('Error issue author:', error);
                    btn.reset();
                }
            },
        },

        trash: {
            async ['@click']() {
                const that = this;
                sendEvent('modal', {
                    title: `刪除作者`,
                    html: '/pages/global/confirm.html',
                    closeBtn: true,
                    message: `請確認是否刪除作者 「${this.author.name}」 ?`,
                    async onConfirm($el) {
                        const ta = $($el);
                        const btn = buttonFlow(ta);
                        try {
                            btn.loading();
                            const { code, data } = await authorDelete({ id: that.author.id });
                            if (code === 1) {
                                that.list.load();

                                window.sendEvent('toast', { title: `作者 「${this.author.name}」 已刪除。` });
                            }
                            btn.done();
                        } catch (error) {
                            console.error('Error delete author:', error);
                            btn.reset();
                        }
                    },
                });
            },
        },
    }));
}
