import Alpine from 'alpinejs';
import Controller from './controller';

import { siteMembers, profileGet, tagList, profileSaveTags, profileSaveNote, siteMembersExport } from '../libs/api';
import { pluck } from '../helpers/utils';

class Member extends Controller {
    filterItems = [
        {
            id: 1,
            name: '搜尋會員名字',
            type: 'search',
            default: '',
            col: 'name',
        },
    ];

    data() {
        return {
            init() {
                const id = this.id ? +this.id : null;

                this.profile.data.id = id;
            },
            list: {
                loaded: false,
                data: {},
                init() {
                    this.get();
                },
                async get() {
                    const query = Alpine.store('query');
                    const parent = Alpine.store('info');

                    this.loaded = false;

                    const { code, data } = await siteMembers(query.fetch());

                    this.loaded = true;

                    if (code === 1) {
                        this.data = data;

                        const meta = {
                            pos: +data.pos || 0,
                            count: +data.count || 0,
                            total: +data.total || 0,
                        };

                        query.midify(meta);
                        parent.paginate.init(meta);
                    }
                },
                async export() {
                    const { code, data: token } = await siteMembersExport();
                    if (code === 1) {
                        const verify = Alpine.store('verify');
                        return verify.display(
                            token.token,
                            token.ts,
                            {
                                email: 'test',
                            },
                            '身份驗證',
                            (data) => {
                                if (data.url) {
                                    window.open(data.url, '_blank');
                                }
                            },
                        );
                    }
                },
            },
            profile: {
                async init() {
                    await this.get();
                    await this.tag.get();
                },
                loaded: false,
                data: {},
                tags: [],
                async get() {
                    const { code, data } = await profileGet({ id: this.data.id });

                    this.loaded = true;
                    if (code === 1) {
                        this.data = data;
                    }
                },

                async saveTags() {
                    const ids = pluck(this.data.tags, 'id');
                    const { code, data } = await profileSaveTags({ id: this.data.id, tags: ids });
                    if (code === 1) {
                    }
                },

                async saveNote() {
                    const { code, data } = await profileSaveNote({ id: this.data.id, note: this.data.meta.note });
                    if (code === 1) {
                    }
                },

                tag: {
                    ta: null,
                    data: null,
                    loaded: false,
                    async get() {
                        const {
                            code,
                            data: { rows: tags },
                        } = await tagList();

                        this.loaded = true;
                        this.data = tags;
                    },
                    set(selector) {
                        let tidied = this.data.map((tag) => {
                            return { id: tag.id, title: tag.title ?? 'unnamed' };
                        });

                        this.ta = selector;

                        const { profile } = Alpine.store('info');
                        const selected = pluck(profile.data.tags, 'id');

                        tidied = tidied.filter((item) => !selected.includes(`${item.id}`));

                        selector.addOptions(tidied, false);
                    },
                },

                manual: {
                    select() {
                        const { profile } = Alpine.store('info');

                        return (value, selector) => {
                            if (value.length === 0) {
                                return false;
                            }

                            const first = value[0];

                            let selected = profile.tag.data.find((item) => +item.id == +first);

                            selector.clear();
                            selector.removeOption(first);
                            selector.blur();

                            profile.data.tags.push({
                                id: selected.id,
                                name: selected.title,
                            });
                        };
                    },

                    del(id) {
                        const { profile } = Alpine.store('info');

                        const selector = Alpine.raw(profile.tag.ta);
                        let original = profile.tag.data.find((item) => +item.id == +id);

                        if (original && selector) {
                            selector.addOption({ id: id, title: original.title });
                        }

                        profile.data.tags = profile.data.tags.filter((item) => item.id !== id);
                    },
                },
            },
        };
    }
}

export const member = new Member();
