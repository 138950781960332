import { themeGet } from '../libs/api';
import { uploadProgress, buttonFlow } from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('themeUploader', (uid = '', callback = () => {}) => ({
        hidden: {
            [':class']() {
                return 'hidden';
            },
            ['x-show']() {
                return false;
            },
            [':accept']() {
                return '.zip';
            },
            async ['@change']() {
                const ta = this.$event.target;
                const btn = buttonFlow($(this.$refs.uploader));
                const files = ta.files;
                let additions = {};

                btn.loading();

                if (files.length === 0) {
                    console.log(files, 'stop');
                    btn.reset();
                    return false;
                }

                if (uid !== '') {
                    additions['uid'] = uid;
                }

                const result = await uploadProgress(files, { filesize: 20 }, additions);
                if (result.success === true) {
                    if (typeof callback === 'function') {
                        callback.apply();
                    }
                }

                window.sendEvent('toast', { title: result.message });

                btn.done();
                ta.value = '';
            },
        },
        uploader: {
            ['@click']() {
                $(this.$refs.hidden).click();
            },
        },
    }));
}
