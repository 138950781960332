import Alpine from 'alpinejs';
import Controller from './controller';
import ShortUniqueId from 'short-unique-id';

import { goodsList, goodsSave, goodsGet, tagList, intelligenceTagSuggest } from '../libs/api';
import { uploadProgress, pluck } from '../helpers/utils';

class Goods extends Controller {
    filterItems = [];

    data() {
        this.filterItems = [
            {
                id: 2,
                name: '搜尋商品關鍵字',
                type: 'search',
                default: '',
                col: 'title',
            },
            {
                id: 1,
                name: '商品狀態',
                type: 'select',
                col: 'status',
                default: 'all',
                items: [
                    { id: 'all', name: '所有狀態' },
                    { id: 1, name: '已上架' },
                    { id: 0, name: '已下架' },
                ],
            },
        ];

        return {
            init() {
                const id = +this.id;
                if (id !== 0) {
                    this.item.data.id = id;
                    this.item.mode = 'edit';
                } else {
                    this.item.tags.loaded = true;

                    this.item.tags.manual.loaded = true;
                    this.item.tags.manual.loading = false;
                }
            },
            list: {
                loaded: false,
                data: {},
                async init() {
                    await this.get();
                },
                async get() {
                    const parent = Alpine.store('info');
                    const query = Alpine.store('query');

                    const { code, data } = await goodsList({ query: query.fetch() });

                    if (code === 1) {
                        this.data = data;

                        const meta = {
                            pos: +data.pos || 0,
                            count: +data.count || 0,
                            total: +data.total || 0,
                        };

                        query.midify(meta);
                        parent.paginate.init(meta);

                        this.loaded = true;
                    }
                },
            },
            item: {
                mode: 'new',
                loaded: false,
                data: {
                    id: null,
                    content: '',
                    cover: null,
                    name: '',
                    slug: '',
                    intro: '',
                    status: 'offline',
                },

                async init() {
                    if (this.mode === 'new') {
                        const generator = new ShortUniqueId();

                        this.loaded = true;
                        this.data.id = generator();
                    } else {
                        await this.load();
                    }
                },

                async load() {
                    const { code, data } = await goodsGet({ id: this.data.id });

                    if (code === 1) {
                        this.data = data;

                        this.tags.data.selected = data.tags;
                        this.tags.loaded = true;

                        this.tags.manual.loaded = true;
                        this.tags.manual.loading = false;

                        this.sku.list = data.sku;

                        this.tags.manual.intelligence = data.intelligence;

                        // const selectedIDs = pluck(data.tags, 'id');

                        // if (data.suggested.additional) {
                        //     data.suggested.additional = data.suggested.additional.filter(
                        //         (tag) => !selectedIDs.includes(`${tag.id}`),
                        //     );
                        // }

                        // if (data.suggested.exists) {
                        //     data.suggested.exists = data.suggested.exists.filter(
                        //         (tag) => !selectedIDs.includes(`${tag.id}`),
                        //     );
                        // }

                        // this.tags.manual.suggested = {
                        //     exists: data.suggested.exists,
                        //     new: data.suggested.additional,
                        // };
                    }

                    this.loaded = true;
                },

                import() {
                    let that = Alpine.store('info');

                    return async (files, limit) => {
                        const result = await uploadProgress(files, limit);
                        if (result.success === true) {
                            that.item.data.cover = result.path;
                        }

                        return result;
                    };
                },

                async save() {
                    const {
                        code,
                        data: { id },
                    } = await goodsSave({
                        ...this.data,
                        tags: [...this.tags.data.selected],
                        sku: [...this.sku.list],
                    });

                    window.sendEvent('validation::done');

                    if (code === 1) {
                        const generator = new ShortUniqueId();

                        Alpine.store('router').go(`/goods/${id}?t=${generator()}`);
                    }
                },

                sku: {
                    list: [],

                    tmp: {
                        mode: 'new',
                        name: '',
                        code: '',
                        price: 0,
                        qty: 0,
                        shift: 0,
                        status: true,
                    },
                    open(id = null) {
                        const that = this;
                        const generator = new ShortUniqueId();

                        console.log('goods::item::open', id, that.list);

                        if (id === null) {
                            this.tmp.id = generator();
                            this.tmp.mode = 'new';
                        } else {
                            that.tmp = that.list.find((itm) => `${itm.id}` === `${id}`);
                        }

                        sendEvent('modal', {
                            title: '商品規格',
                            html: '/pages/ecommerce/goods/add-items.html',
                            closeBtn: true,
                        });
                    },
                    save() {
                        this.list = this.list.filter((itm) => `${itm.id}` !== `${this.tmp.id}`);

                        this.list.push(this.tmp);

                        this.tmp = {
                            name: '',
                            code: '',
                            price: 0,
                            qty: 0,
                            shift: 0,
                            status: 1,
                        };

                        Alpine.store('modal').close();
                    },
                },

                tags: {
                    async init() {
                        // await this.load();
                        await this.tag.get();
                    },
                    loaded: false,
                    data: {
                        id: null,
                        selected: [],
                    },
                    async load() {
                        this.manual.loading = true;

                        const { item } = Alpine.store('info');

                        if (item.mode !== 'new') {
                            const { code, data } = await pressTag({ id: this.data.id });
                            this.data.selected = data.rows ?? [];

                            console.log(data.rows, data.suggested, '[[--');

                            const selectedIDs = pluck(this.data.selected, 'id');
                            if (data.suggested.exists) {
                                data.suggested.exists = data.suggested.exists.filter(
                                    (tag) => !selectedIDs.includes(`${tag.id}`),
                                );
                            }
                            if (data.suggested.new) {
                                data.suggested.new = data.suggested.new.filter(
                                    (tag) => !selectedIDs.includes(`${tag.id}`),
                                );
                            }

                            console.log(selectedIDs, data.suggested.new);

                            this.manual.suggested = data.suggested;
                        }
                        this.loaded = true;

                        this.manual.loaded = true;
                        this.manual.loading = false;
                    },
                    async submit() {
                        const { code, data } = await pressTagSave(this.data);

                        if (code == 1) {
                            const { editor } = Alpine.store('info');
                            editor.displayPublishSetting.apply(editor);
                        }
                    },
                    del(id) {
                        const {
                            item: { tags },
                        } = Alpine.store('info');

                        const selector = Alpine.raw(tags.tag.ta);
                        let original = tags.tag.data.find((item) => +item.id == +id);

                        if (original && selector) {
                            selector.addOption({ id: id, title: original.title });
                        } else {
                            let selected = tags.suggest.selected.find((item) => item.id == id);
                            tags.suggest.selected = tags.suggest.selected.filter((item) => item.id !== id);

                            if (selected) {
                                if (selected.type === 'exists') {
                                    tags.manual.suggested.exists.push({
                                        id: id,
                                        name: selected.name,
                                    });
                                } else if (selected.type === 'new') {
                                    tags.manual.suggested.new.push({
                                        id: id,
                                        name: selected.name,
                                    });
                                }
                            }
                        }

                        this.data.selected = this.data.selected.filter((item) => item.id !== id);
                    },
                    manual: {
                        loading: false,
                        loaded: false,
                        suggested: [],
                        intelligence: {},
                        add() {
                            const {
                                item: { tags },
                            } = Alpine.store('info');
                            const generator = new ShortUniqueId();

                            return (value, item, selector) => {
                                selector.clear();
                                selector.removeOption(value);
                                selector.blur();

                                tags.data.selected.push({
                                    id: generator(),
                                    name: value,
                                });
                            };
                        },
                        select() {
                            const {
                                item: { tags },
                            } = Alpine.store('info');

                            return (value, selector) => {
                                if (value.length === 0) {
                                    return false;
                                }

                                const first = value;

                                let selected = tags.tag.data.find((item) => +item.id == +first);

                                console.log(value, first, tags.tag.data, selected, 'P{PPPPP');

                                selector.clear();
                                selector.removeOption(first);
                                selector.blur();

                                tags.data.selected.push({
                                    id: selected.id,
                                    name: selected.title,
                                });
                            };
                        },
                        async suggest() {
                            const { item } = Alpine.store('info');

                            this.loading = true;
                            this.loaded = false;

                            const { code, data } = await intelligenceTagSuggest({
                                type: 'product',
                                id: item.data.id,
                                content: item.data.content,
                            });

                            if (code === 1) {
                                this.intelligence = data.usage;
                                this.suggested = {
                                    exists: data.exists,
                                    new: data.additional,
                                };
                            }

                            this.loaded = true;
                            this.loading = false;
                        },
                    },

                    tag: {
                        ta: null,
                        data: null,
                        loaded: false,
                        async get() {
                            const {
                                code,
                                data: { rows: tags },
                            } = await tagList();

                            this.loaded = true;
                            this.data = tags;
                        },
                        set(selector) {
                            let tidied = this.data.map((tag) => {
                                return { id: tag.id, title: tag.title ?? 'unnamed' };
                            });

                            this.ta = selector;

                            const {
                                item: { tags },
                            } = Alpine.store('info');
                            const selected = pluck(tags.data.selected, 'id');

                            tidied = tidied.filter((item) => !selected.includes(item.id));

                            console.log(selected, tidied, '{{999999');

                            selector.addOptions(tidied, false);
                        },
                    },

                    suggest: {
                        selected: [],
                        select(id, type) {
                            const {
                                item: { tags },
                            } = Alpine.store('info');

                            let selected = tags.manual.suggested[type].find((item) => item.id == id);
                            const payload = {
                                id: selected.id,
                                name: selected.name,
                                slug: selected.slug,
                                type: type,
                            };

                            tags.data.selected.push(payload);
                            tags.suggest.selected.push(payload);

                            tags.manual.suggested[type] = tags.manual.suggested[type].filter((item) => item.id !== id);
                        },
                    },
                },
            },
        };
    }
}

export const goods = new Goods();
