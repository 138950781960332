import { themeGet, themeDownload, themeEnable } from '../libs/api';
import {} from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('themeDetail', () => ({
        loaded: false,
        init() {
            this.id = +this.m.data.id;

            this.get();
        },

        id: null,
        data: {},

        async get() {
            const { code, data } = await themeGet({ id: this.id });
            if (code === 1) {
                this.data = data;
            }

            this.loaded = true;
        },

        cover: {
            [':src']() {
                return this.data.cover;
            },
        },

        version: {
            ['x-text']() {
                return this.data.version;
            },
        },
        last: {
            ['x-text']() {
                return this.$timeago(new Date(this.data.last_ts), null, null, { strict: true });
            },
        },

        update() {
            const that = this;
            return () => {
                that.get();
            };
        },

        download: {
            async ['@click']() {
                console.log(this.data.id, this?.history?.id, '<---');
                const history = this?.history?.id || 'latest';

                const { code, data } = await themeDownload({
                    id: this.data.id,
                    version: history,
                });

                if (code === 1) {
                    window.open(data.url, 'Download');
                    // window.location.assign(data.url);
                }

                console.log(code, data);
            },
        },

        enable: {
            async ['@click']() {
                const history = this?.history?.id || undefined;

                const { code, data } = await themeEnable({
                    id: this.data.id,
                    version: history,
                });

                if (code === 1) {
                    this.get();
                }
            },
        },
    }));
}
