function getTrackingCode() {
    const metas = document.querySelectorAll('[property*=t_code]');
    let codes = [];

    for (let i = 0; i < metas.length; i++) {
        codes.push(metas[i].content);
    }

    return codes;
}

function _globalTracking(event) {
    const filetypes = /\.(avi|doc.*|dmg|exe|flv|mov|mp3|pdf|ppt.*|rar|txt|wav|wma|wmv|xls.*|xlt.*|zip)$/i;

    let el = event.srcElement || event.target;
    let track = false;
    let evCat = '';
    let evAction = '';
    let evLabel = '';

    while (el && (typeof el.tagName == 'undefined' || el.tagName.toLowerCase() != 'a' || !el.href)) el = el.parentNode;

    if (el && el.href) {
        /* jump-to bookmark anchor for internal link */
        if (el.hash !== '' && el.href.indexOf(location.host) != -1) {
            evCat = 'Interact';
            /* NOTE: Ensure all clickable page elements have ID attributes for this to log meaningful data */
            evAction = 'Jump to ' + el.hash;
            if (el.id) {
                evAction = evAction + ' from #' + el.id;
            }
            evLabel = 'Page: ' + document.location.pathname.toLowerCase();
            track = true;
        } else if (el.href.match(filetypes)) {
            /* file download */
            /* REVIEW: Determine if file downloads on the same server should be tracked as virtual page views, events, or both. */
            const extension = el.href.substr(el.href.lastIndexOf('.') + 1);
            evCat = 'Download';
            evAction = 'Download-' + extension.toUpperCase();
            evLabel = 'File: ' + el.href.toLowerCase() + '; Page: ' + document.location.pathname.toLowerCase();
            track = true;
        } else if (el.href.match(/^mailto\:/i)) {
            /* mailto:  */
            evCat = 'Contact';
            evAction = 'Email Click';
            evLabel = el.href + '; Page: ' + document.location.pathname.toLowerCase();
            track = true;
        } else if (el.href.match(/^tel\:/i)) {
            /* tel: click-to-call */
            evCat = 'Contact';
            evAction = 'Click-to-Call';
            evLabel = el.href + '; Page: ' + document.location.pathname.toLowerCase();
            track = true;
        } else if (el.href.indexOf(location.host) == -1) {
            /* external link */
            evCat = 'Outgoing Links';
            evAction = el.href;
            evLabel = 'Page: ' + document.location.pathname.toLowerCase();
            track = true;
        }

        console.log(el, el.href, el.hash, { evCat, evAction, evLabel, track }, '{{-----');

        /* Log the event */
        if (track) {
            window.gtag('event', 'tryto', {
                event_category: evCat,
                event_label: evAction,
                value: evLabel,
            });
            /* if target not set then delay opening of window by 0.5s to allow time for tracking */
            if (!el.target || el.target.match(/^_(self|parent|top)$/i)) {
                setTimeout(
                    function () {
                        document.location.href = el.href;
                    }.bind(el),
                    500,
                );
                /* Prevent standard click */
                event.preventDefault ? event.preventDefault() : (event.returnValue = !1);
            }
        }
    }
}

export default function (Alpine) {
    window.dataLayer = window.dataLayer || [];
    window.gtag =
        window.gtag ||
        function () {
            window.dataLayer.push(arguments);
        };

    window.gtag('js', new Date());

    const codes = getTrackingCode();
    for (const code of codes) {
        window.gtag('config', code);
    }

    const w = window;
    w.addEventListener
        ? w.addEventListener(
              'load',
              function () {
                  document.body.addEventListener('click', _globalTracking, !1);
              },
              !1,
          )
        : w.attachEvent &&
          w.attachEvent('onload', function () {
              document.body.attachEvent('onclick', _globalTracking);
          });
}
