import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';
import TimeAgo from '@marcreichel/alpine-timeago';
import persist from '@alpinejs/persist';
import { zhTW } from 'date-fns/locale';
// import storage from 'alpinejs-persist-extended';
import Tooltip from '@ryangjchandler/alpine-tooltip';
import Clipboard from '@ryangjchandler/alpine-clipboard';
import validation from './libs/validation';
// import HawkCatcher from '@hawk.so/javascript';
import component from 'alpinejs-component';
import AlpineI18n from 'alpinejs-i18n';

import 'altcha';

import '../styles/style.scss';

import { initRouter } from './router/index.js';

// import { registerApiService } from './services/apiService.js';
import { registerAuthService } from './services/authService.js';
import { registerVerifyService } from './services/verifyService.js';

// import { registerSelectorComponent } from './components/selector.js';

import { registerSubmitHelper } from './helpers/submit.js';
import { registerMenuHelper } from './helpers/menu.js';
import { registerIconHelper } from './helpers/icon.js';
import { registerLinkHelper } from './helpers/link.js';
import { registerSelectHelper } from './helpers/select.js';
import { registerSidebarHelper } from './helpers/sidebar.js';
import { registerCheckboxHelper } from './helpers/checkbox.js';
import { registerDropdownHelper } from './helpers/dropdown.js';
import { registerCodeEditorHelper } from './helpers/codeEditor.js';
import { registerUploadHelper } from './helpers/upload.js';
import { registerEditorHelper } from './helpers/editor.js';
// import { registerSortHelper } from './helpers/sort.js';
import { registerQueryHelper } from './helpers/query.js';
import { registerPaginateHelper } from './helpers/paginate.js';
import { registerImageHelper } from './helpers/image.js';
import { registerFilterHelper } from './helpers/filter.js';
import { i18n } from './helpers/utils.js';
import eventSender from './helpers/event';
import modal from './helpers/modal';
import { registerMagic } from './helpers/magic';
import { registerTappayHelper } from './helpers/tappay';
import dropzone from './libs/dropzone';
import selector from './helpers/selector';
import autoHeight from './helpers/autoHeight.js';
import swiper from './libs/swiper.js';
import textarea from './libs/textarea.js';
import sortable from './libs/sortable.js';
import sort from './libs/sort.js';
import tippy from './libs/tippy.js';
import categorybar from './libs/categorybar.js';
import tracking from './libs/tracking.js';
import collapse from './libs/collapse.js';
import toggleMenu from './libs/toggleMenu.js';
import input from './libs/input.js';
import altcha from './libs/altcha.js';
import sidebar from './libs/sidebar.js';
import plan from './libs/plan.js';
import profile from './libs/profile.js';
import setting from './libs/setting.js';
import dashboard from './libs/dashboard.js';
import label from './libs/label.js';
import filter from './libs/filter.js';
import paginate from './libs/paginate.js';
import intelligence from './libs/intelligence.js';
import invite from './libs/invite.js';
import homeSettings from './libs/homeSettings.js';
import queryItems from './libs/queryItems.js';
import theme from './libs/theme.js';
import themeDetail from './libs/themeDetail.js';
import themeUploader from './libs/themeUploader.js';
import author from './libs/author.js';
import confirm from './libs/confirm.js';
import subscription from './libs/subscription.js';
import draft from './libs/draft.js';

// registerApiService(Alpine);
registerAuthService(Alpine);
registerVerifyService(Alpine);

registerSubmitHelper(Alpine);
registerMenuHelper(Alpine);
registerIconHelper(Alpine);
registerLinkHelper(Alpine);
registerSelectHelper(Alpine);
registerSidebarHelper(Alpine);
registerCheckboxHelper(Alpine);
registerDropdownHelper(Alpine);
registerCodeEditorHelper(Alpine);
registerUploadHelper(Alpine);
registerEditorHelper(Alpine);
registerMagic(Alpine);
// registerSortHelper(Alpine);
registerQueryHelper(Alpine);
registerPaginateHelper(Alpine);
registerImageHelper(Alpine);
registerFilterHelper(Alpine);
registerTappayHelper(Alpine);

Alpine.plugin(AlpineI18n);
await i18n();

initRouter(Alpine);

Alpine.store('modal', modal);

Alpine.plugin(sort);
Alpine.plugin(sortable);
Alpine.plugin(textarea);
Alpine.plugin(swiper);
Alpine.plugin(autoHeight);
Alpine.plugin(selector);
Alpine.plugin(dropzone);
Alpine.plugin(validation);
Alpine.plugin(Tooltip);
Alpine.plugin(mask);
Alpine.plugin(persist);
Alpine.plugin(component);
Alpine.plugin(tippy);
Alpine.plugin(categorybar);
Alpine.plugin(Clipboard);
Alpine.plugin(tracking);
Alpine.plugin(collapse);
Alpine.plugin(toggleMenu);
Alpine.plugin(input);
Alpine.plugin(altcha);
Alpine.plugin(sidebar);
Alpine.plugin(plan);
Alpine.plugin(profile);
Alpine.plugin(setting);
Alpine.plugin(dashboard);
Alpine.plugin(label);
Alpine.plugin(filter);
Alpine.plugin(paginate);
Alpine.plugin(intelligence);
Alpine.plugin(invite);
Alpine.plugin(homeSettings);
Alpine.plugin(queryItems);
Alpine.plugin(theme);
Alpine.plugin(themeDetail);
Alpine.plugin(themeUploader);
Alpine.plugin(author);
Alpine.plugin(confirm);
Alpine.plugin(subscription);
Alpine.plugin(draft);

// Alpine.plugin(storage);
Alpine.plugin(
	TimeAgo.configure({
		locale: zhTW,
	}),
);

// window.hawk = new HawkCatcher({ token: import.meta.env.VITE_HAWK_KEY });

window.sendEvent = eventSender;
window.modal = [];
window.modelClose = () => {
	const modal = window.modal.pop();
	modal.close();
};

window.i18nVersion = '1.0.8';
window.Alpine = Alpine;
window.addEventListener('error', (event) => {
	console.error(`${event.type}: ${event.message}\n`, event);
});

document.addEventListener('alpine-i18n:locale-change', () => {
	localStorage.setItem('i18n::saved', window.AlpineI18n.locale.toLowerCase());
});
document.addEventListener('alpine-i18n:ready', i18n);

queueMicrotask(() => {
	Alpine.start();

	var Tawk_API = Tawk_API || {},
		Tawk_LoadStart = new Date();
	(function () {
		var s1 = document.createElement('script'),
			s0 = document.getElementsByTagName('script')[0];
		s1.async = true;
		s1.src = 'https://embed.tawk.to/65a8d8c40ff6374032c1a5f8/1hkdotg3f';
		s1.charset = 'UTF-8';
		s1.setAttribute('crossorigin', '*');
		s0.parentNode.insertBefore(s1, s0);
	})();
});
