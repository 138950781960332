import './paid-section.css';

export default class PaidSection {
    constructor({ api, block }) {
        this.api = api;
        this.block = block;
        this.defaultType = 'flex';
    }

    static get isReadOnlySupported() {
        return true;
    }

    static get enableLineBreaks() {
        return true;
    }

    static get toolbox() {
        return {
            title: '付費區域',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><g fill="none" stroke="#000000" stroke-width="1.5"><path d="M2 14c0-3.771 0-5.657 1.172-6.828S6.229 6 10 6h4c3.771 0 5.657 0 6.828 1.172S22 10.229 22 14s0 5.657-1.172 6.828S17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172S2 17.771 2 14Zm14-8c0-1.886 0-2.828-.586-3.414S13.886 2 12 2s-2.828 0-3.414.586S8 4.114 8 6"></path><path stroke-linecap="round" d="M12 17.333c1.105 0 2-.746 2-1.666S13.105 14 12 14s-2-.746-2-1.667c0-.92.895-1.666 2-1.666m0 6.666c-1.105 0-2-.746-2-1.666m2 1.666V18m0-8v.667m0 0c1.105 0 2 .746 2 1.666"></path></g></svg>',
        };
    }

    render() {
        const display = document.createElement('p');
        display.textContent = this.api.i18n.t('以下為付費內容');

        display.classList.add('subscription-block');

        const { num, first } = this.count();

        if (num > 0 && first.id !== this.block.id) {
            return '';
        }

        return display;
    }

    save() {
        return {};
    }

    count() {
        let count = 0;
        let first = null;
        let others = [];

        for (var i = 0; i < this.api.blocks.getBlocksCount(); i++) {
            const item = this.api.blocks.getBlockByIndex(i);

            if (item.name === 'PaidSection') {
                if (first === null) {
                    first = item;
                } else {
                    others.push(item);
                }

                count += 1;
            }
        }

        return { num: count, first, others };
    }

    validate(savedData) {
        const { num, first } = this.count();
        let toolEl = document.querySelector(`[data-item-name="PaidSection"]`);

        if (num <= 1) {
            toolEl.style.display = 'none';
            return true;
        }

        toolEl.style.display = first.id === this.block.id ? 'none' : this.defaultType;

        return first.id === this.block.id;
    }

    destroy() {
        const { num, first } = this.count();
        let toolEl = document.querySelector(`[data-item-name="PaidSection"]`);

        if (num - 1 <= 1) {
            toolEl.style.display = this.defaultType;
        } else {
            toolEl.style.display = 'none';
        }

        console.log('PaidSection::destroy', toolEl, num, first);
    }
}
