import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';

const jar = new CookieJar();

let headers = { 'Content-Type': 'application/json' };
axios.defaults.withCredentials = true;
axios.defaults.timeout = 3000;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-Requested-From'] = `${window.location.hostname}`;

let instance = wrapper(
    axios.create({
        baseURL: import.meta.env.VITE_API_BASE,
        jar: jar,
    })
);

// axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         console.log('error::', error, '<--');
//         window.sendEvent('toast', {
//             title: '糟糕！網路發生異常',
//             message: '網頁載入速度緩慢或完全無法開啟',
//         });
//         Promise.reject(error.response);
//     }
// );

const errorTranx = {
    2001: '找不到使用者。',
    2006: '登錄嘗試次數過多。請稍後再試。',
    2007: '無法重複使用舊密碼。',
    13000: '無法偵測到有效的 CNAME 紀錄，請稍後再試。',
    19001: '本月已更換過訂閱方案，無法再次更改。',
};

const _request = function (path, params = {}, timeout = 10 * 1000, method = 'post') {
    let tryCount = 0;
    return new Promise((resolve, reject) => {
        console.group('api::post', path, params);
        instance
            .post(path, params, {
                headers: headers,
                timeout: timeout,
            })
            .then((res) => {
                if (res.data.code !== 1) {
                    if (res.data.code === 1005) {
                        sendEvent('altcha::verify');
                    }

                    window.sendEvent('toast', {
                        message: errorTranx[res.data.code] || res.data.msg || '糟糕！發生錯誤',
                    });

                    sendEvent('validation::reset');
                }
                console.table(res.data);
                resolve(res.data);
            })
            .catch((error) => {
                sendEvent('validation::reset');

                if (error.response) {
                    const { status, data: response } = error.response;

                    if (status === 500) {
                        window.sendEvent('toast', {
                            title: '糟糕！網路發生異常',
                            message: '網頁載入速度緩慢或完全無法開啟',
                        });
                    } else if (status === 403) {
                        if (tryCount === 0) {
                            Alpine.store('auth').refresh();
                            tryCount += 1;
                        }
                    } else {
                        window.sendEvent('toast', {
                            title: errorTranx[response.code] || response.msg, // `錯誤 ${response.code}`,
                            // message: ,
                        });
                    }

                    console.error('Api::post error', response);
                } else {
                    console.log(error);
                    // const { status } = error.response;

                    // if (status === 403) {
                    if (tryCount === 0) {
                        Alpine.store('auth').refresh();
                        tryCount += 1;
                    }
                    // }
                }

                reject(error);
            })
            .finally(() => {
                console.groupEnd('api::post', path);
            });
    });
};

const _upload = function (path, params = {}) {
    const formHeaders = Object.assign({}, JSON.parse(JSON.stringify(headers))); // deep copy
    Object.assign(formHeaders, { 'Content-Type': 'multipart/form-data' }); // overwrite Content-Type

    const formData = new FormData();
    Object.keys(params).forEach((key, value) => {
        formData.append(key, params[key]);
    });

    return new Promise((resolve, reject) => {
        console.group('api::upload', path, params);
        instance
            .post(path, formData, {
                headers: formHeaders,
                timeout: 1000 * 30,
            })
            .then((res) => {
                if (res.data.code !== 1) {
                    window.sendEvent('toast', {
                        message: res.data.msg,
                    });
                }
                console.table(res.data);
                resolve(res.data);
            })
            .catch((error) => {
                const { status, data } = error.response;

                if (status === 500) {
                    window.sendEvent('toast', {
                        title: '糟糕！網路發生異常',
                        message: '網頁載入速度緩慢或完全無法開啟',
                    });
                } else {
                    window.sendEvent('toast', {
                        title: errorTranx[data.code] || data.msg, // `錯誤 ${response.code}`,
                        // message: ,
                    });
                }

                console.error('Api::post error', data);

                reject(error);
            })
            .finally(() => {
                console.groupEnd('api::post', path);
            });
    });
};

const role = 'writer';

export const memberStatus = () => _request(`/api/member/status?t=${role}`);
export const memberWriterVerify = (data) => _request(`/api/member/verify?t=${role}`, data);
export const memberWriterRegister = (data) => _request(`/api/member/register?t=${role}`, data);
export const memberWriterLogin = (data) => _request(`/api/member/login?t=${role}`, data);
export const memberWriterLoginByToken = (data) => _request(`/api/member/token_login?t=${role}`, data);
export const memberLogout = (data) => _request('/api/member/logout', data);
export const memberForgot = (data) => _request('/api/member/forgot', data);
export const memberReset = (data) => _request('/api/member/reset', data);

export const memberChange = (data) => _request('/api/member/change', data);
export const memberProfile = (data) => _request('/api/member/get', data);

export const memberAuthorList = () => _request('/api/member/author');

export const verifyOTP = (data) => _request('/api/verify/verifying', data);
export const resendOTP = (data) => _request('/api/verify/resend', data);

export const pressList = (type, query) => _request(`/api/press/list?t=${type}`, { query });
export const pressSave = (data, type) => _request(`/api/press/save?t=${type}`, data);
export const pressGet = (data, type) => _request(`/api/press/get?t=${type}`, data);
export const pressPublish = (data, type) => _request(`/api/press/publish?t=${type}`, data, 120 * 1000);
export const pressHistory = (id) => _request(`/api/press/history`, { id });
export const pressSearch = (query) => _request(`/api/press/search`, { query });
export const pressPin = (data) => _request(`/api/press/pin`, data);
export const pressUnPin = (data) => _request(`/api/press/unpin`, data);
export const pressSorter = (data) => _request(`/api/press/sorter`, data);
export const pressTag = (data) => _request(`/api/press/tag`, data);
export const pressTagSave = (data) => _request(`/api/press/save_tag`, data);

export const tagList = () => _request('/api/tag/all');
export const tagTagSuggest = (data) => _request('/api/tag/suggest', data, 120 * 1000);

export const intelligenceTagSuggest = (data) => _request('/api/intelligence/tags', data, 120 * 1000);
export const intelligenceSlug = (data) => _request('/api/intelligence/slug', data, 120 * 1000);
export const intelligenceStatus = (data) => _request('/api/intelligence/status', data);

export const categoryAll = () => _request('/api/category/all');
export const categoryEdit = (data) => _request('/api/category/edit', data);
export const categoryDel = (data) => _request('/api/category/del', data);
export const categoryNew = (data) => _request('/api/category/new', data);

export const siteAvailableCheck = (slug) => _request('/api/site/available', { slug });
export const siteMembers = (query) => _request('/api/site/members', { query });
export const siteMembersExport = (data) => _request(`/api/member/export`, data);

export const siteAdmins = (query) => _request('/api/site/staff', { query });
export const siteAdminNew = (query) => _request('/api/member/invite', query);
export const siteAdminToken = (query) => _request('/api/member/invite_token', query);
export const siteAdminJoin = (query) => _request('/api/member/join', query);

export const elementImageUpload = (data) => _upload('/api/element/upload/image', data);
export const elementThemeUpload = (data) => _upload('/api/element/upload/theme', data);

export const menuSave = (data) => _request(`/api/navigation/save`, data);
export const menuGet = (data) => _request(`/api/navigation/get`, data);

export const themeList = () => _request(`/api/theme/list`);
export const themeApply = (data) => _request(`/api/theme/apply`, data);
export const themeGet = (data) => _request(`/api/theme/get`, data);
export const themeDownload = (data) => _request(`/api/theme/download`, data);
export const themeEnable = (data) => _request(`/api/theme/enable`, data);

export const siteSettingSave = (data) => _request(`/api/site/save`, data);
export const siteSettingProfile = (data) => _request(`/api/site/profile`, data);
export const siteStatus = () => _request('/api/site/status');
export const siteBindDomain = (data) => _request(`/api/site/bind`, data);
export const siteValidDomain = (data) => _request(`/api/site/valid`, data, 20 * 1000);

export const siteHome = () => _request(`/api/site/home`);
export const siteHomeSave = (data) => _request(`/api/site/set_home`, data);

export const customerRequestList = (query) => _request(`/api/customer/list`, { query });
export const customerRequestGet = (data) => _request(`/api/customer/get`, data);

export const profileGet = (data) => _request('/api/profile/get', data);
export const profileSave = (data) => _request(`/api/member/save_profile`, data);
export const profileSaveTags = (data) => _request(`/api/profile/tags`, data);
export const profileSaveNote = (data) => _request(`/api/profile/note`, data);

export const profileSaveRoles = (data) => _request(`/api/profile/roles`, data);
export const profileRemove = (data) => _request(`/api/profile/remove`, data);

export const subscriberList = (query) => _request(`/api/subscribers/list`, { query });

export const dashboardStatistics = (data, query) => _request(`/api/dashboard/statistics`, { query, ...data });

export const facebookConnectStatus = () => _request(`/api/facebook/status`);
export const facebookDisconnect = () => _request(`/api/facebook/disconnect`);
export const facebookConnect = (data) => _request(`/api/facebook/connect`, data);
export const facebookUnconnect = (data) => _request(`/api/facebook/unconnect`, data);

export const shareStatus = (data) => _request(`/api/share/status`, data);
export const shareExecute = (data) => _request(`/api/share/execute`, data);

export const goodsList = (data) => _request(`/api/product/list`, data);
export const goodsSave = (data) => _request(`/api/product/save`, data);
export const goodsGet = (data) => _request(`/api/product/get`, data);

export const logisticsList = (data) => _request(`/api/logistics/list`, data);
export const logisticsSave = (data) => _request(`/api/logistics/save`, data);
export const logisticsGet = (data) => _request(`/api/logistics/get`, data);

export const ordersList = (data) => _request(`/api/order/list`, data);
export const orderGet = (data) => _request(`/api/order/get`, data);
export const orderSave = (data) => _request(`/api/order/save`, data);

export const planChoose = (data) => _request(`/api/plan/choose`, data);
export const planChange = (data) => _request(`/api/plan/change`, data);
export const planUnsubscribe = (data) => _request(`/api/plan/unsubscribe`, data);
export const planSubscribe = (data) => _request(`/api/plan/subscribe`, data);
export const sitePayment = (data) => _request(`/api/site/payment`, data);
export const sitePublish = (data) => _request(`/api/site/publish`, data);
export const siteRetry = (data) => _request(`/api/site/retry`, data);
export const siteSkip = (data) => _request(`/api/site/skip`, data);

export const labelList = (data) => _request(`/api/label/index`, data);
export const labelShow = (data) => _request(`/api/label/show`, data);
export const labelSave = (data) => _request(`/api/label/edit`, data);
export const labelDel = (data) => _request(`/api/label/delete`, data);
export const labelSearch = (query) => _request(`/api/label/search`, { query });

export const authorList = (data) => _request(`/api/author/list`, data);
export const authorShow = (data) => _request(`/api/author/get`, data);
export const authorSave = (data) => _request(`/api/author/issue`, data);
export const authorDelete = (data) => _request(`/api/author/delete`, data);

export const subscriptionInfo = (data) => _request(`/api/subscription/info`, data);
export const subscriptionPlanSave = (data) => _request(`/api/subscription/save_plan`, data);
export const subscriptionContentSave = (data) => _request(`/api/subscription/save_content`, data);
export const subscriptionPlanGet = (data) => _request(`/api/subscription/get`, data);
export const subscriptionStatus = (data) => _request(`/api/subscription/status`, data);
