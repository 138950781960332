import Pristine from 'pristinejs';

export const registerSubmitHelper = (alpine) => {
	const lockButton = (ta) => {
		setTimeout(() => {
			ta.prop('disabled', true);
			ta.html(
				'<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
			);
		}, 1);
	};
	const unLockButton = (ta, originalContent) => {
		setTimeout(() => {
			ta.prop('disabled', false);
			ta.html(originalContent);
		}, 650);
	};

	alpine.directive(
		'submit',
		(el, { expression, value }, { evaluate, evaluateLater, effect, cleanup }) => {
			const ta = $(el);
			const taContent = ta.html();
			const form = ta.closest('form');
			const turnstileElement = form.find('.cf-turnstile');
			const withTurnstile = turnstileElement.length > 0 && typeof turnstile !== 'undefined';
			const tappayElement = form.find('[x-tappay]');
			const withTappay = tappayElement.length > 0 && typeof TPDirect !== 'undefined';
			// const afterAction = ta.attr('x-after');

			lockButton(ta);

			let turnstileId = null;
			const validator = new Pristine(
				form.get(0),
				{
					classTo: 'input-group',
					errorClass: 'has-danger',
					successClass: 'has-success',
					errorTextParent: 'input-group',
					errorTextTag: 'div',
					errorTextClass: 'invalid-feedback',
				},
				false
			);

			if (withTurnstile) {
				turnstileId = turnstile.render(turnstileElement.get(0), {
					sitekey: import.meta.env.VITE_TURNSTILE_SITE_KEY,
					theme: 'light',
					size: 'normal',
					action: value,
					callback: (token) => {
						const expData = form.attr('x-data');
						evaluate(`${expData}.token = '${token}'`);
						unLockButton(ta, taContent);
					},
				});
			} else {
				unLockButton(ta, taContent);
			}

			ta.on('click', function (e) {
				let tappayStatus = null;
				lockButton(ta);

				if (withTappay) {
					tappayStatus = TPDirect.card.getTappayFieldsStatus();
				}

				if (
					!validator.validate(null, false) ||
					(withTurnstile && !$.trim(turnstile.getResponse(turnstileId))) ||
					(withTappay && !tappayStatus.canGetPrime)
				) {
					unLockButton(ta, taContent);
					console.log(validator.getErrors());
					// validator.reset();
					return false;
				}

				evaluate(`${expression}()`);

				alpine.store('modal').close();

				validator.reset();

				if (withTurnstile) {
					setTimeout(() => {
						turnstile.reset(turnstileId);
					}, 1000);
				}

				unLockButton(ta, taContent);
			});

			form.on('submit', function (e) {
				e.preventDefault();
			});

			cleanup(() => {
				ta.off('click');
				form.off('submit');
				validator.destroy();

				if (withTurnstile) {
					turnstile.remove(turnstileId);
				}
			});
		}
	);
};
