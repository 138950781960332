import Iodine from '@caneara/iodine';
import { DateTime } from 'luxon';
import { sleep, buttonFlow, isValidHttpUrl, validSlug } from '../helpers/utils';
import timezones from 'timezones-list';
import { isValidPhoneNumber } from 'libphonenumber-js';

let forms = new Map();
const iodine = new Iodine();
const injectClass = 'validation-injected';

iodine.rule('tel', (value, country = 'TW') => isValidPhoneNumber(value.replace(/\s/g, ''), country));
iodine.rule('slug', (value) => validSlug(value));
iodine.rule('countMin', (value, param) => (Array.isArray(value) && value.length >= +param ? true : false));
iodine.rule('hasChecked', (value, param) => document.querySelector('input[name="' + param + '"]:checked') != null);
iodine.rule('slugCheck', (value, param) => document.querySelector('input[name="' + param + '"]:checked') != null);
iodine.rule('password', (value, param) => /^(?=.*[0-9])(?=.*[a-z]).{8,}$/.test(value));
iodine.rule('passwordEquals', (value, param) => document.querySelector(`input${param}`).value === value);
iodine.rule('passwordNotEquals', (value, param) => document.querySelector(`input${param}`).value !== value);
iodine.rule('domain', (value, param) => /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(value));
iodine.rule('tagsCheck', (value, param) => {
    const l = value.split(',').length;
    return l <= +param && l > 0;
});

iodine.rule('dateFuture', (value, fmt) => {
    const now = DateTime.now();
    const input = DateTime.fromFormat(value, fmt);
    return (
        input.isValid &&
        input.startOf('year') >= now.startOf('year') &&
        input.startOf('month') >= now.startOf('month') &&
        input.startOf('day') >= now.startOf('day')
    ); // input >= now;
});
iodine.rule('time', (value) => DateTime.fromFormat(value, 'HH:mm').isValid);
iodine.rule('equals', (value, param) => `${value}`.trim() === $(param).val().trim());
iodine.rule('link', (value) =>
    isValidHttpUrl(value)
        ? true
        : value.replace(/^\/|\/$/g, '') !== ''
          ? true
          : validSlug(value.replace(/^\/|\/$/g, '')),
);
iodine.rule('timezone', (value) => timezones.filter((t) => t.tzCode === value).length === 1);
iodine.rule(
    'duplicateCheck',
    (value, param) =>
        $(param)
            .map(function () {
                return $(this).val();
            })
            .get()
            .filter((v) => v.trim() == value.trim()).length <= 1,
);

iodine.rule('required_if', (value, args) => {
    const [name, ...values] = args.split(',');
    const targetVal = $(`[name=${name}]:checked,[name=${name}] :selected`).val();
    console.log(name, targetVal, values, '[[[[0000000');
    return (values.includes(`${targetVal}`) || values.includes(+targetVal)) && !!value.trim();
});

iodine.setErrorMessage('tel', '電話格式錯誤');
iodine.setErrorMessage('required', '此欄位為必填');
iodine.setErrorMessage('slug', '格式錯誤');
iodine.setErrorMessage('countMin', '至少需要填入ㄧ個');
iodine.setErrorMessage('hasChecked', '至少需要選擇ㄧ個');
iodine.setErrorMessage('slugCheck', '[PARAM] 已被使用');
iodine.setErrorMessage('maxLength', '超過最大字數（[PARAM]）限制');
iodine.setErrorMessage('minLength', '請輸入最少 [PARAM] 字數');
iodine.setErrorMessage('dateFuture', '請選擇未來的時間');
iodine.setErrorMessage('time', '時間格式錯誤');
iodine.setErrorMessage('password', '密碼格式錯誤');
iodine.setErrorMessage('email', '無效的電子信箱格式');
iodine.setErrorMessage('equals', '此欄位內容輸入錯誤');
iodine.setErrorMessage('passwordEquals', '輸入的密碼沒有相同');
iodine.setErrorMessage('passwordNotEquals', '輸入的密碼請勿與舊密碼相同');
iodine.setErrorMessage('duplicateCheck', '此欄位重複');
iodine.setErrorMessage('link', '連結錯誤');
iodine.setErrorMessage('timezone', '時區錯誤');
iodine.setErrorMessage('tagsCheck', '已達到 [PARAM] 個標籤上限');
iodine.setErrorMessage('domain', '網域格式錯誤');
iodine.setErrorMessage('required_if', '此欄位為必填');
iodine.setErrorMessage('regexMatch', '此欄位格式錯誤');

const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

const buildRulesCollection = (inputs) => {
    let collection = {};
    inputs.each(function () {
        const input = $(this);
        const name = input.prop('name');
        const rule = input.data('rules').split('|');

        collection[name] = rule;
    });

    return collection;
};

const buildValuesCollection = (inputs) => {
    let collection = {};
    inputs.each(function () {
        const input = $(this);
        const name = input.prop('name');

        if (name.includes('[]')) {
            const value = input.is(':checked') ? input.val() : null;
            if (Array.isArray(collection[name]) === false) {
                collection[name] = [];
            }

            value != null && collection[name].push(value);
        } else {
            const value = input.val();
            collection[name] = value;
        }
    });

    return collection;
};

const toggleInputValidStatus = (input, result) => {
    if (result.valid === false) {
        input.toggleClass('is-invalid', true);
        input.toggleClass('is-valid', false);
    } else {
        input.toggleClass('is-invalid', false);
        input.toggleClass('is-valid', true);
    }

    if (input.is('.no-valid')) {
        input.toggleClass('is-valid', false);
    }
};

const validFormValue = (inputs, rules, btn = null) => {
    const values = buildValuesCollection(inputs);
    const result = iodine.assert(values, rules);

    console.log(values, rules, '<--');

    if (btn !== null) {
        if (result.valid === true) {
            btn.reset();
        } else {
            btn.lock();
        }
        // btn.prop('disabled', !(result.valid === true));
    }

    return result;
};

export default function (Alpine) {
    Alpine.directive('validation', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        forms.set(el, { fields: {}, valid: null });

        const ta = $(el);
        ta.toggleClass(injectClass, true);

        let inputs = ta.find('[data-rules]');
        let rules = buildRulesCollection(inputs);

        const submit = ta.find('button[type=submit],.js-submit');
        const turnstileElement = ta.find('.cf-turnstile');
        const withTurnstile = turnstileElement.length > 0 && typeof turnstile !== 'undefined';
        const onChange = evaluate(el.dataset.onChange ?? '() => () => false');
        const beforeSubmit = evaluate(el.dataset.beforeSubmit ?? '() => (done) => done()'); // ta.data('before-submit');

        const altchaElement = ta.find('[x-altcha]');
        const withAltcha = altchaElement.length > 0;
        const altchaInput = altchaElement.find('[name="altcha"]');

        const eleObserver = new MutationObserver((mutations) => {
            inputs = ta.find('[data-rules]');
            inputs.off('change').on('change', onchange);
            rules = buildRulesCollection(inputs);
        });

        const button = buttonFlow(submit);
        let changeBuffer = null;

        button.lock();
        eleObserver.observe(ta.get(0), { childList: true, subtree: true });

        let turnstileId = null;
        let withToken = false;

        if (withTurnstile) {
            let options = {
                sitekey: import.meta.env.VITE_TURNSTILE_SITE_KEY,
                theme: 'light',
                size: 'normal',
                // action: value,
                callback: (token) => {
                    const expData = ta.attr('x-data');
                    withToken = true;
                    evaluate(`${turnstileElement.data('token')} = '${token}'`);
                },
                'expired-callback': () => {
                    withToken = false;
                },
                'timeout-callback': () => {
                    withToken = false;
                },
            };

            if (value) {
                options.action = value;
            }

            turnstileId = turnstile.render(turnstileElement.get(0), options);
        }

        const onchange = function (event) {
            console.log('validation::onchange');
            clearTimeout(changeBuffer);
            changeBuffer = setTimeout(() => {
                event.preventDefault();

                const result = validFormValue(inputs, rules, button);

                const input = $(this);
                const name = input.prop('name');

                console.log('form::valid::result', result);

                forms.set(el, result);

                ta.trigger(`validationMessage::${name}`);

                if (typeof onChange === 'function') {
                    onChange();
                }

                toggleInputValidStatus(input, result.fields[name]);
            }, 500);
        };

        inputs.off('change input').on('change input', onchange);

        ta.on('submit', function (e) {
            e.preventDefault();

            const result = validFormValue(inputs, rules, button);
            button.loading();

            console.log('form::valid::result', result);

            forms.set(el, result);

            const done = async (success = true) => {
                if (success) {
                    await evaluate(expression);
                    // await execCallback.apply(null);

                    sleep(20000).then(() => {
                        button.done();
                    });
                } else {
                    button.reset();
                }
            };

            if (
                result.valid !== true ||
                (withTurnstile && withToken === false) ||
                (withAltcha && altchaInput.length > 0 && $.trim(altchaInput.val()))
            ) {
                for (const name in result.fields) {
                    if (result.fields[name].valid !== true) {
                        ta.trigger(`validationMessage::${name}`);
                    }
                }

                button.reset();

                return false;
            }

            if (withTurnstile) {
                withToken = false;
                turnstile.reset(turnstileId);
            }

            if (typeof beforeSubmit === 'function') {
                beforeSubmit(done);
            } else {
                done();
            }
        });

        $(window)
            .on('validation::update', () => {
                // inputs = ta.find('[data-rules]');
                // inputs.off('change blur').on('change blur', onchange);
                // rules = buildRulesCollection(inputs);
            })
            .on('validation::reset', () => {
                if (withTurnstile) {
                    withToken = false;
                    turnstile.reset(turnstileId);
                }

                if (withAltcha) {
                    sendEvent('altcha::verify');
                }

                button.done();
            })
            .on('validation::on::change', () => {
                if (typeof onChange === 'function') {
                    onChange();
                }
            })
            .on('validation::done', () => {
                button.done();
            });

        effect(() => {
            console.log('validation::effect');
        });

        sleep(400).then(() => {
            validFormValue(inputs, rules, button);
        });

        cleanup(() => {
            ta.off('submit');
            inputs.off('change');

            $(window).off('validation::update validation::on::change');

            forms.delete(el);
            eleObserver.disconnect();
        });
    });

    Alpine.directive(
        'validation-message',
        (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
            const ta = $(el);
            const form = ta.closest(`.${injectClass}`);
            const name = expression.includes('+') ? evaluate(expression) : expression;

            form.on(`validationMessage::${name}`, () => {
                const result = forms.get(form.get(0));
                const valid = result.fields && result.fields[name] ? result.fields[name].valid : true;

                if (result.fields && result.fields[name]) {
                    if (valid === false) {
                        ta.html(result.fields[name].error).show();
                    } else {
                        ta.html('').hide();
                    }
                }
            });

            cleanup(() => {
                form.off(`validationMessage::${name}`);
            });
        },
    );
}
