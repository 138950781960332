import Alpine from 'alpinejs';
import Controller from './controller';
import ShortUniqueId from 'short-unique-id';
import timezones from 'timezones-list';

import { subscriberList } from '../libs/api';
import { sleep, uploadProgress } from '../helpers/utils';

class Follower extends Controller {
    data(params) {
        return {
            loaded: false,
            data: {},

            init() {
                this.load();
            },

            async load() {
                const query = Alpine.store('query');

                const { code, data } = await subscriberList(query.fetch());

                if (code === 1) {
                    this.data = data;
                    this.loaded = true;
                }
            },
        };
    }
}

export const follower = new Follower();
