import Alpine from 'alpinejs';
import Controller from './controller';

import { siteStatus, siteBindDomain, siteValidDomain } from '../libs/api';
import { sleep, buttonFlow } from '../helpers/utils';

class Domain extends Controller {
    // filterItems = [{ id: 1, name: '編號', type: 'int', col: 'id' }];

    validation() {
        return {
            async valid(success, failure) {
                const { code, data } = await siteValidDomain();

                if (code === 1) {
                    success.call();
                } else {
                    failure.call();
                }
            },
        };
    }

    data(params) {
        const validation = this.validation();

        return {
            title: '網域設定',
            status: {
                data: {},
                loaded: false,
                init() {
                    this.load();
                },
                async load() {
                    const { code, data } = await siteStatus();

                    this.data = data;
                    this.loaded = true;
                },

                async valid(btn) {
                    const button = buttonFlow($(btn));
                    button.loading();
                    await validation.valid(
                        async () => {
                            await this.load();
                            button.done();
                        },
                        () => {
                            button.done();
                        },
                    );
                },
            },
            custom: {
                step: 1,
                data: {
                    domain: '',
                },
                async bind() {
                    const { code, data } = await siteBindDomain({ domain: this.data.domain });

                    if (code === 1) {
                        this.data = data;
                        this.step = 2;
                    }
                },

                async valid() {
                    await validation.valid(
                        () => {
                            const router = Alpine.store('router');
                            router.go(`page/domain`);
                        },
                        () => {
                            const router = Alpine.store('router');
                            router.go(`page/domain`);
                        },
                    );
                },

                // async valid() {
                //     console.log(DohResolver);

                // const resolver = new DohResolver('https://cloudflare-dns.com/dns-query');

                // lookup the A records for example.com
                // resolver
                //     .query(this.data.domain, 'CNAME')
                //     .then((response) => {
                //         console.log(response);
                // response.answers.forEach((ans) => console.log(ans.data));
                // })
                // .catch((err) => console.error(err));

                // const result = makeQuery(this.data.domain, 'CNAME');

                // console.log(this.data, 'CNAME', result);

                // const timeout = 1000;

                // const resolver = new DohResolver('https://dns.google/dns-query');
                // resolver
                //     .query(this.data.domain, 'CNAME', 'POST', null, timeout)
                //     .then((response) => {
                //         console.log(response.answers);
                //     })
                //     .catch((err) => console.error(err));
                // },
            },
        };
    }
}

/*
cloudflare
gandi
godaddy
hinet
pchome
cloudmax
hosting
netchinese
*/

export const domain = new Domain();
