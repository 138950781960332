import Alpine from 'alpinejs';
import Controller from './controller';

class Service extends Controller {
	data() {
		return {
			title: '訂閱管理',
		};
	}
}

export const service = new Service();
