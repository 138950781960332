import Alpine from 'alpinejs';
import Controller from './controller';

import { profileSave } from '../libs/api';
import { removeEmpty } from '../helpers/utils';

class Profile extends Controller {
    data() {
        return {
            title: '個人資訊',
            data: {
                name: '',
                email: '',
                intro: '',
            },

            init() {
                const { user } = Alpine.store('auth');
                this.data = {
                    name: user.nickname,
                    email: user.email,
                    intro: user.info || '',
                };
            },
            async submit() {
                const payload = removeEmpty(Alpine.raw(this.data));

                const { code } = await profileSave(payload);
                if (code === 1) {
                    const auth = Alpine.store('auth');
                    await auth.refresh.apply(auth);

                    window.sendEvent('validation::done');
                }
            },
        };
    }
}

export const profile = new Profile();
