import feather from 'feather-icons';

export const registerIconHelper = (alpine) => {
	alpine.directive('icon', (el, {}, { cleanup }) => {
		const ta = $(el);
		const name = ta.data('feather');
		const className = ta.prop('class');

		const svg = feather.icons[name].toSvg({ width: '18', height: '18' });

		ta.replaceWith($(svg).toggleClass(className, true));

		cleanup(() => {
			ta.remove();
		});
	});
};
