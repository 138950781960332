// import Alpine from 'alpinejs';
import Navigo from 'navigo';

import { routeHandler, sleep } from '../helpers/utils';

import { app } from '../controllers/app';
import { auth } from '../controllers/auth';
import { role } from '../controllers/role';
import { site } from '../controllers/site';
// import { staff } from '../controllers/staff';
import { admin } from '../controllers/admin';
import { press } from '../controllers/press';
import { page } from '../controllers/page';
import { cate, file, editor } from '../controllers/file';
import { plan } from '../controllers/plan';
// import { theme } from '../controllers/theme';
import { siteInit } from '../controllers/siteInit';
import { design } from '../controllers/design';
import { service } from '../controllers/service';
import { dashboard } from '../controllers/dashboard';
import { setting } from '../controllers/setting';
import { member } from '../controllers/member';
import { profile } from '../controllers/profile';
import { menu } from '../controllers/menu';
import { general } from '../controllers/general';
import { account } from '../controllers/account';
import { password } from '../controllers/password';
import { customer } from '../controllers/customer';
import { goods } from '../controllers/goods';
import { logistics } from '../controllers/logistics';
import { domain } from '../controllers/domain';
import { theme } from '../controllers/theme';
import { follower } from '../controllers/follower';
import { orders } from '../controllers/orders';
import { label } from '../controllers/label';
import { author } from '../controllers/author';
import { subscription } from '../controllers/subscription';

const router = new Navigo('/', { hash: false });

const appRoute = () => routeHandler(router, '/pages/base.html', '#wrapper', app);
const notFoundRoute = () => routeHandler(router, '/pages/notfound.html', '#wrapper', app);

const dashboardRoute = () => routeHandler(router, '/pages/dashboard.html', '#content', dashboard);

const authLoginRoute = () => routeHandler(router, '/pages/auth/login.html', '#wrapper', auth);
const authRegisterRoute = () => routeHandler(router, '/pages/auth/register.v2.html', '#wrapper', auth);
const authLogoutRoute = () => routeHandler(router, '/pages/auth/logout.html', '#wrapper', auth);
const authForgotRoute = () => routeHandler(router, '/pages/auth/forgot.html', '#wrapper', auth);
const authForgotSuccessRoute = () => routeHandler(router, '/pages/auth/forgot-success.html', '#wrapper', auth);
const authInviteRoute = () => routeHandler(router, '/pages/auth/invite.html', '#wrapper', auth);
const authResetRoute = () => routeHandler(router, '/pages/auth/reset.html', '#wrapper', auth);

const roleListRoute = () => routeHandler(router, '/pages/role/list.html', '#content', role);
const roleEditorRoute = (params) => routeHandler(router, '/pages/role/editor.html', '#content', role, params);

const siteListRoute = () => routeHandler(router, '/pages/site/list.html', '#content', site);
const siteEditorRoute = (params) => routeHandler(router, '/pages/site/editor.html', '#content', site, params);
const siteInitRoute = (params) => routeHandler(router, '/pages/site/init.html', '#wrapper', siteInit, params);

// const staffListRoute = () => routeHandler(router, '/pages/staff/list.html', '#content', staff);
// const staffEditorRoute = (params) => routeHandler(router, '/pages/staff/editor.html', '#content', staff, params);

const fileListRoute = (params) => routeHandler(router, '/pages/file/index.html', '#content', cate, params);
const filePanelRoute = (params) => {
	routeHandler(router, `/pages/file/cate-${params.type}.html`, '#right-panel', file, params);
};
const fileCreateRoute = (params) => routeHandler(router, '/pages/file/page-create.html', '#content', editor, params);

const pressListRoute = () => routeHandler(router, '/pages/press/list.html', '#content', press, { type: 1 });
const pressEditorRoute = (params) => routeHandler(router, '/pages/press/editor.html', '#wrapper', press, params);
const ePaperSendRoute = (params) => routeHandler(router, '/pages/press/epaper-send.html', '#wrapper', press, params);
const pressPublishSettingRoute = (params) =>
	routeHandler(router, '/pages/press/publish-setting.html', '#wrapper', press, params);
const pressPublishSuccessRoute = (params) =>
	routeHandler(router, '/pages/press/publish-success.html', '#wrapper', press, params);
const pressPublishTagsRoute = (params) =>
	routeHandler(router, '/pages/press/publish-tags.html', '#wrapper', press, params);
const pressImportRoute = (params) => routeHandler(router, '/pages/press/import.html', '#wrapper', press, params);

const pageListRoute = () => routeHandler(router, '/pages/press/list.html', '#content', press, { type: 3 });
const pageHomeRoute = () => routeHandler(router, '/pages/press/home_config.html', '#content', press, { type: 3 });

const epaperListRoute = () => routeHandler(router, '/pages/press/list.html', '#content', press, { type: 4 });
// const pageEditorRoute = (params) => routeHandler(router, '/pages/page/editor.html', '#content', page, params);
const pageDomainIndexRoute = (params) =>
	routeHandler(router, '/pages/page/domain/index.html', '#content', domain, params);
const pageDomainSubRoute = (params) =>
	routeHandler(router, '/pages/page/domain/setting.html', '#content', domain, params);
const pageDomainCustomRoute = (params) =>
	routeHandler(router, '/pages/page/domain/custom.html', '#content', domain, params);

const pageThemeIndexRoute = (params) => routeHandler(router, '/pages/page/theme/index.html', '#content', theme, params);

// const planListRoute = () => routeHandler(router, '/pages/plan/list.html', '#content', plan);
// const planEditorRoute = (params) => routeHandler(router, '/pages/plan/editor.html', '#content', plan, params);
const planIndexRoute = () => routeHandler(router, '/pages/plan/index.html', '#content', plan);
const planDetailRoute = (params) => routeHandler(router, '/pages/plan/choose.html', '#content', plan, params);
const planCurrentRoute = () => routeHandler(router, '/pages/plan/current.html', '#content', plan);

// const planSuccessRoute = (params) => routeHandler(router, '/pages/plan/payment-success.html', '#wrapper', plan, params);
// const planFailedRoute = (params) => routeHandler(router, '/pages/plan/payment-failed.html', '#wrapper', plan, params);

// const themeListRoute = () => routeHandler(router, '/pages/theme/list.html', '#content', theme);
// const themeEditorRoute = (params) => routeHandler(router, '/pages/theme/editor.html', '#content', theme, params);

const designListRoute = () => routeHandler(router, '/pages/design/config.html', '#content', design);
const serviceConfigRoute = () => routeHandler(router, '/pages/service/config.html', '#content', service);

const settingIndexRoute = () => routeHandler(router, '/pages/setting/index.html', '#content', setting);
const settingMenuRoute = () => routeHandler(router, '/pages/setting/menu.html', '#content', menu);
const settingGeneralRoute = () => routeHandler(router, '/pages/setting/general.html', '#content', general);
const settingAccountRoute = () => routeHandler(router, '/pages/setting/account.html', '#content', account);
const settingPasswordRoute = () => routeHandler(router, '/pages/setting/password.html', '#wrapper', password);

const memberIndexRoute = () => routeHandler(router, '/pages/member/index.html', '#content', member);
const memberProfileRoute = (params) => routeHandler(router, '/pages/member/profile.html', '#content', member, params);

const adminIndexRoute = () => routeHandler(router, '/pages/staff/index.html', '#content', admin);
const adminProfileRoute = (params) => routeHandler(router, '/pages/staff/profile.html', '#content', admin, params);

const profileIndexRoute = () => routeHandler(router, '/pages/profile/index.html', '#content', profile);
const profileEditRoute = () => routeHandler(router, '/pages/profile/edit.html', '#wrapper', profile);

const customerIndexRoute = () => routeHandler(router, '/pages/customer/index.html', '#content', customer);
const customerMessageRoute = (params) =>
	routeHandler(router, '/pages/customer/message.html', '#content', customer, params);

const goodsIndexRoute = () => routeHandler(router, '/pages/ecommerce/goods/index.html', '#content', goods);
const goodsDetailRoute = (params) =>
	routeHandler(router, '/pages/ecommerce/goods/item.html', '#content', goods, params);

const logisticsIndexRoute = () => routeHandler(router, '/pages/ecommerce/logistics/index.html', '#content', logistics);
const logisticsDetailRoute = (params) =>
	routeHandler(router, '/pages/ecommerce/logistics/item.html', '#content', logistics, params);

const ordersIndexRoute = () => routeHandler(router, '/pages/ecommerce/orders/index.html', '#content', orders);
const ordersDetailRoute = (params) =>
	routeHandler(router, '/pages/ecommerce/orders/item.html', '#content', orders, params);

const followerIndexRoute = () => routeHandler(router, '/pages/follower/index.html', '#content', follower);

const labelListRoute = (params) => routeHandler(router, '/pages/label/list.html', '#content', label, params);
const authorListRoute = (params) => routeHandler(router, '/pages/author/index.html', '#content', author, params);

const subscriptionIndexRoute = (params) => routeHandler(router, '/pages/subscription/index.html', '#content', subscription, params);

export const initRouter = (alpine) => {
	const auth = alpine.store('auth');

	router.hooks({
		before: async (done, match) => {
			console.info('router::before', match);
			await auth.status(match);

			Alpine.store('modal').close();

			if (auth.isAuthenticated === false && auth.isWhitelisted(match) === false) {
				router.navigate(`/auth/login?goback=/${match.url}`, {
					updateBrowserURL: true,
					callHandler: true,
				});
				done(false);
				return;
			} else if (auth.isAuthenticated === true && auth.isWhitelisted(match) === true) {
				router.navigate('/', {
					updateBrowserURL: true,
					callHandler: true,
				});
				done(false);
				return;
			} else {
				// if (auth.isWhitelisted(match) === true) {
				// 	router.navigate('/', {
				// 		updateBrowserURL: true,
				// 		callHandler: true,
				// 	});
				// 	done(false);
				// 	return;
				// }
				done();
			}
		},
	});

	router.on(/epaper\/(?<id>\d+)\/send/, (args) => {
		args.data.type = 4;
		ePaperSendRoute(args.data);
	});

	router.on(/(?<type>press|page)\/(?<id>\d+)\/publish\/setting/, (args) => {
		args.data.type = args.data.type === 'press' ? 1 : 3;
		pressPublishSettingRoute(args.data);
	});

	router.on(/(?<type>press|page)\/(?<id>\d+)\/publish\/success/, (args) => {
		args.data.type = args.data.type === 'press' ? 1 : 3;
		pressPublishSuccessRoute(args.data);
	});

	router.on(/(?<type>press|page)\/(?<id>\d+)\/publish\/tags/, (args) => {
		args.data.type = args.data.type === 'press' ? 1 : 3;
		pressPublishTagsRoute(args.data);
	});

	router.on(/press\/import/, () => {
		pressImportRoute();
	});

	router.on(/plan\/(?<id>\d+)/, (args) => {
		appRoute().then(() => {
			planDetailRoute(args.data);
		});
	});

	router.on(
		/press\/(?<id>\d+)$/,
		(args) => {
			args.data.type = 1;
			pressEditorRoute(args.data);
		},
		{
			leave(done) {
				console.log('post::editor::leaving');
				window.sendEvent('post.editor.leaving', { done });
			},
		},
	);

	router.on(
		/page\/(?<id>\d+)$/,
		(args) => {
			args.data.type = 3;
			pressEditorRoute(args.data);
		},
		{
			leave(done) {
				console.log('page::editor::leaving');
				window.sendEvent('page.editor.leaving', { done });
			},
		},
	);

	router.on(
		/epaper\/(?<id>\d+)$/,
		(args) => {
			args.data.type = 4;
			pressEditorRoute(args.data);
		},
		{
			leave(done) {
				console.log('epaper::editor::leaving');
				window.sendEvent('epaper.editor.leaving', { done });
			},
		},
	);

	router.on({
		'/auth/login': () => {
			authLoginRoute();
		},
		'/auth/logout': () => {
			authLogoutRoute();
		},
		'/auth/register': () => {
			authRegisterRoute();
		},
		'/auth/reset': () => {
			authResetRoute();
		},
		'/auth/forgot': () => {
			authForgotRoute();
		},
		'/auth/forgot/success': () => {
			authForgotSuccessRoute();
		},
		'/auth/invite': () => {
			authInviteRoute();
		},
		'/role': () => {
			appRoute().then(() => {
				roleListRoute();
			});
		},
		'/role/:id': (args) => {
			appRoute().then(() => {
				roleEditorRoute(args.data);
			});
		},
		'/init': () => {
			siteInitRoute();
		},
		'/site': () => {
			appRoute().then(() => {
				siteListRoute();
			});
		},
		'/site/:id': (args) => {
			appRoute().then(() => {
				siteEditorRoute(args.data);
			});
		},
		// '/staff': () => {
		// 	appRoute().then(() => {
		// 		staffListRoute();
		// 	});
		// },
		// '/staff/:id': (args) => {
		// 	appRoute().then(() => {
		// 		staffEditorRoute(args.data);
		// 	});
		// },
		'/file': () => {
			appRoute().then(() => {
				fileListRoute();
			});
		},
		'/file/:type': (args) => {
			appRoute().then(() => {
				fileListRoute(args.data).then(() => {
					filePanelRoute(args.data);
				});
			});
		},
		'/file/:type/:kid': (args) => {
			appRoute().then(() => {
				fileListRoute(args.data).then(() => {
					filePanelRoute(args.data);
				});
			});
		},
		'/file/:type/:id/:action': (args) => {
			appRoute().then(() => {
				fileCreateRoute(args.data);
			});
		},
		'/file/:type/:kid/:id/:action': (args) => {
			appRoute().then(() => {
				fileCreateRoute(args.data);
			});
		},
		'/press': () => {
			appRoute().then(() => {
				pressListRoute();
			});
		},
		'/page': () => {
			appRoute().then(() => {
				pageListRoute();
			});
		},
		'/page/home': () => {
			appRoute().then(() => {
				pageHomeRoute();
			});
		},
		'/epaper': () => {
			appRoute().then(() => {
				epaperListRoute();
			});
		},
		'/setting/domain': () => {
			appRoute().then(() => {
				pageDomainIndexRoute();
			});
		},
		'/setting/domain/sub': () => {
			appRoute().then(() => {
				pageDomainSubRoute();
			});
		},
		'/setting/domain/custom': () => {
			appRoute().then(() => {
				pageDomainCustomRoute();
			});
		},
		'/setting/theme': () => {
			appRoute().then(() => {
				pageThemeIndexRoute();
			});
		},
		'/plan/current': () => {
			appRoute().then(() => {
				planCurrentRoute();
			});
		},
		'/plan': () => {
			appRoute().then(() => {
				planIndexRoute();
			});
		},

		// '/plan': () => {
		// 	appRoute().then(() => {
		// 		planListRoute();
		// 	});
		// },
		// '/plan/:id': (args) => {
		// 	appRoute().then(() => {
		// 		planEditorRoute(args.data);
		// 	});
		// },
		// '/theme': () => {
		// 	appRoute().then(() => {
		// 		themeListRoute();
		// 	});
		// },
		// '/theme/:id': (args) => {
		// 	appRoute().then(() => {
		// 		themeEditorRoute(args.data);
		// 	});
		// },
		'/design': () => {
			appRoute().then(() => {
				designListRoute();
			});
		},
		'/service': () => {
			appRoute().then(() => {
				serviceConfigRoute();
			});
		},
		'/setting': () => {
			appRoute().then(() => {
				settingIndexRoute();
			});
		},
		'/setting/menu': {
			uses: () => {
				appRoute().then(() => {
					settingMenuRoute();
				});
			},
			hooks: {
				leave: (done) => {
					window.sendEvent('page.setting.menu.leaving', { done });
				},
			},
		},
		'/setting/general': () => {
			appRoute().then(() => {
				settingGeneralRoute();
			});
		},
		'/setting/account': () => {
			appRoute().then(() => {
				settingAccountRoute();
			});
		},
		'/setting/password': () => {
			appRoute().then(() => {
				settingPasswordRoute();
			});
		},
		'/member': () => {
			appRoute().then(() => {
				memberIndexRoute();
			});
		},
		'/member/:id': (args) => {
			appRoute().then(() => {
				memberProfileRoute(args.data);
			});
		},
		'/admin': () => {
			appRoute().then(() => {
				adminIndexRoute();
			});
		},
		'/admin/:id': (args) => {
			appRoute().then(() => {
				adminProfileRoute(args.data);
			});
		},
		'/profile': () => {
			appRoute().then(() => {
				profileIndexRoute();
			});
		},
		'/profile/edit': () => {
			appRoute().then(() => {
				profileEditRoute();
			});
		},
		'/customer': () => {
			appRoute().then(() => {
				customerIndexRoute();
			});
		},
		'/customer/:id': (args) => {
			appRoute().then(() => {
				customerMessageRoute(args.data);
			});
		},
		'/follower': () => {
			appRoute().then(() => {
				followerIndexRoute();
			});
		},
		'/goods': () => {
			appRoute().then(() => {
				goodsIndexRoute();
			});
		},
		'/goods/:id': (args) => {
			appRoute().then(() => {
				goodsDetailRoute(args.data);
			});
		},
		'/logistics': () => {
			appRoute().then(() => {
				logisticsIndexRoute();
			});
		},
		'/logistics/:id': (args) => {
			appRoute().then(() => {
				logisticsDetailRoute(args.data);
			});
		},
		'/orders': () => {
			appRoute().then(() => {
				ordersIndexRoute();
			});
		},
		'/orders/:id': (args) => {
			appRoute().then(() => {
				ordersDetailRoute(args.data);
			});
		},
		'/label': (args) => {
			appRoute().then(() => {
				labelListRoute(args.data);
			});
		},
		'/label/:id': (args) => {
			appRoute().then(() => {
				labelDetailRoute(args.data);
			});
		},
		'/author': (args) => {
			appRoute().then(() => {
				authorListRoute(args.data);
			});
		},
		'/author/:id': (args) => {
			appRoute().then(() => {
				authorListRoute(args.data);
			});
		},
		'/subscription': (args) => {
			appRoute().then(() => {
				subscriptionIndexRoute(args.data);
			});
		},
		'/': () => {
			appRoute().then(() => {
				console.log('router::/::dashboard');
				dashboardRoute();
			});
		},
	});

	router.notFound(() => {
		notFoundRoute();
	});

	alpine.store('router', {
		router: router,
		goback() {
			history.back();
		},
		go(url, options = {}) {
			options.updateBrowserURL = true;
			options.callHandler = true;
			options.callHooks = true;
			// options.force = true;

			if (typeof options.silent !== 'undefined' && options.silent === true) {
				delete options.silent;
				options.callHandler = false;
				options.callHooks = false;

				console.log('router::go with silent');
			}

			console.log(url, options, '((------------------------------------------------------------');

			router.navigate(url, options);
		},
		refresh() {
			history.go(0);
		},
		current() {
			let current = router.getCurrentLocation();
			current.url = current.url.replace('#/', '');
			return current;
		},
		isCurrent(url) {
			const current = this.current();

			if (url === '' && current.url === '') {
				return true;
			} else if (url === '') {
				return false;
			}

			return current.url.includes(url);
		},
	});
	router.resolve();
};
