const template = `
<div x-data="{ paginate: $store.info.paginate }">
    <div class="btn-group-paginate">
        <button
            type="button"
            class="btn btn-white btn-sm"
            :disabled="paginate.meta.pos == 0"
            @click.prevent="paginate.changePos(paginate.meta.pos - 1)"
        >
            <i class="bi bi-chevron-left feather-sm"></i>
        </button>

        <template x-for="i in $paginate((paginate.meta.pos + 1), 5, paginate.meta.count)">
            <button
                type="button"
                class="btn btn-link btn-sm"
                x-text="i"
                @click.prevent="paginate.changePos(i - 1)"
                :class="{ 'active': i - 1 == paginate.meta.pos, 'static': i === '...' }"
                :disabled="i - 1 == paginate.meta.pos || i === '...'"
            ></button>
        </template>

        <button
            type="button"
            class="btn btn-white btn-sm"
            :disabled="paginate.meta.pos + 1 == paginate.meta.count"
            @click.prevent="paginate.changePos(paginate.meta.pos + 1)"
        >
            <i class="bi bi-chevron-right feather-sm"></i>
        </button>
    </div>
</div>`;

export const registerPaginateHelper = (alpine) => {
    alpine.directive(
        'paginate',
        (el, { value, modifiers, expression }, { Alpine, evaluate, evaluateLater, effect, cleanup }) => {
            const count = evaluate('$store.info.paginate?.meta?.count');

            // console.log('paginate::init', evaluate('$store.info.paginate'));

            if (typeof count === 'undefined') {
                return false;
            }

            el.innerHTML = template;

            cleanup(() => {
                el.parentNode.removeChild(el);
            });
        }
    );
};
