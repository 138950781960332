import Alpine from 'alpinejs';
import Controller from './controller';

import { siteAdmins, profileGet, siteAdminNew, profileSaveRoles, profileRemove } from '../libs/api';
import { pluck, getServiceParam } from '../helpers/utils';

class Admin extends Controller {
    filterItems = [
        {
            id: 1,
            name: '搜尋會員名字',
            type: 'search',
            default: '',
            col: 'name',
        },
    ];

    data() {
        const data = {
            init() {
                const id = this.id ? +this.id : null;

                this.profile.data.id = id;
            },
            list: {
                loaded: false,
                data: {},
                init() {
                    this.get();
                },
                async get() {
                    const query = Alpine.store('query');
                    const parent = Alpine.store('info');

                    this.loaded = false;

                    const { code, data } = await siteAdmins(query.fetch());

                    this.loaded = true;

                    if (code === 1) {
                        this.data = data;

                        const meta = {
                            pos: +data.pos || 0,
                            count: +data.count || 0,
                            total: +data.total || 0,
                        };

                        query.midify(meta);
                        parent.paginate.init(meta);
                    }
                },

                display: {
                    add() {
                        const config = getServiceParam('staff_count');
                        if (data.list.data.count < config['count']) {
                            sendEvent('modal', {
                                title: '新增帳號',
                                html: '/pages/staff/add.html',
                                closeBtn: true,
                            });
                        } else {
                            sendEvent('modal', {
                                title: '帳號已達上限',
                                html: '/pages/staff/exceeded_limit.html',
                                closeBtn: true,
                            });
                        }
                    },
                },
                staff: {
                    add() {},
                },
            },
            add: {
                data: {
                    email: '',
                    nickname: '',
                    roles: [],
                },
                async save() {
                    const { code } = await siteAdminNew(this.data);
                    if (code === 1) {
                        await data.list.get();
                        Alpine.store('modal').close();
                    }
                },
            },
            profile: {
                async init() {
                    await this.get();
                    // await this.tag.get();
                },
                loaded: false,
                data: {},
                tags: [],
                block: false,
                async get() {
                    const { code, data } = await profileGet({ id: this.data.id });

                    this.loaded = true;
                    if (code === 1) {
                        this.data = data;

                        if (Alpine.store('auth').user.id === data.id) {
                            this.block = true;
                        }
                    }
                },
                display: {
                    delete() {
                        sendEvent('modal', {
                            title: '新增帳號',
                            html: '/pages/staff/delete.html',
                            closeBtn: true,
                        });
                    },
                },
                async delete() {
                    const { code } = await profileRemove({
                        id: this.data.id,
                    });

                    if (code === 1) {
                        window.sendEvent('toast', {
                            title: '帳號已刪除。',
                            icon: 'bi-trash',
                        });

                        Alpine.store('router').go(`/admin`);
                    }
                },
                async saveRoles() {
                    const { code } = await profileSaveRoles({
                        id: this.data.id,
                        roles: this.data.roles,
                    });

                    if (code === 1) {
                        window.sendEvent('toast', {
                            title: '帳號角色已儲存。',
                            icon: 'bi-floppy',
                        });
                    } else {
                        window.sendEvent('toast', {
                            title: '帳號角色儲存失敗。',
                            icon: 'bi-exclamation-circle',
                        });
                    }
                },
            },
        };

        return data;
    }
}

export const admin = new Admin();
