import { siteAdminToken, siteAdminJoin } from '../libs/api';
import { buttonFlow, sleep } from '../helpers/utils';

export default function (Alpine) {
    Alpine.data('invite', () => ({
        async init() {
            const urlParams = new URLSearchParams(window.location.search.split('?')[1]);
            const v = urlParams.get('v');

            this.token.data = v;
            this.data.v = v;
            await this.token.load();

            this.data.nickname = this.token.meta.name;
        },

        data: {
            token: null,
            v: null,
            nickname: '',
            password: '',
        },

        token: {
            loaded: false,
            data: null,
            meta: {},
            async load() {
                const { code, data } = await siteAdminToken({ token: this.data });
                this.loaded = true;
                if (code === 1) {
                    this.meta = data;
                }
            },
        },

        account: {
            [':value']() {
                return this.token.meta.account;
            },
            [':readonly']() {
                return 'readonly';
            },
            [':disabled']() {
                return 'disabled';
            },
        },

        role_tranx: { 2: '管理員', 3: '編輯', 4: '電商人員' },

        roles: {
            [':value']() {
                return this.token.meta.roles.map((role) => this.role_tranx[role]).join('、');
            },
            [':readonly']() {
                return 'readonly';
            },
            [':disabled']() {
                return 'disabled';
            },
        },

        pass: {
            display: false,
        },

        password: {
            [':type']() {
                return this.pass.display === true ? 'text' : 'password';
            },
        },

        password_btn: {
            ['@click']() {
                this.pass.display = !this.pass.display;
            },
        },

        async submit() {
            const { code } = await siteAdminJoin(this.data);
            if (code === 1) {
                Alpine.store('router').go('auth/login');
            }
        },
    }));
}
