import { parser as htmlParser } from '@lezer/html';
import { parser as jsParser } from '@lezer/javascript';
import { parseMixed } from '@lezer/common';

import { EditorState, StateEffect } from '@codemirror/state';
import { EditorView, keymap, lineNumbers } from '@codemirror/view';
import { defaultKeymap, history, historyKeymap, indentWithTab } from '@codemirror/commands';
import {
	foldNodeProp,
	foldInside,
	indentNodeProp,
	defaultHighlightStyle,
	syntaxHighlighting,
	LRLanguage,
} from '@codemirror/language';

const format = (html) => {
	if (!$.trim(html)) {
		return html;
	}

	var tab = '\t';
	var result = '';
	var indent = '';

	html.split(/>\s*</).forEach(function (element) {
		if (element.match(/^\/\w/)) {
			indent = indent.substring(tab.length);
		}

		result += indent + '<' + element + '>\r\n';

		if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
			indent += tab;
		}
	});

	return result.substring(1, result.length - 3);
};

export const registerCodeEditorHelper = (alpine) => {
	alpine.directive('code-editor', (el, { value, modifiers, expression }, { evaluate, cleanup, effect }) => {
		const mixedHTMLParser = htmlParser.configure({
			wrap: parseMixed((node) => {
				return node.name == 'ScriptText' ? { parser: jsParser } : null;
			}),
		});

		let loaded = false;

		let extensions = [
			keymap.of([...defaultKeymap, ...historyKeymap, indentWithTab]),
			EditorView.lineWrapping,
			lineNumbers(),
			history(),
			syntaxHighlighting(defaultHighlightStyle),
			LRLanguage.define({ parser: mixedHTMLParser }),
		];

		if (value === 'readonly') {
			extensions.push(EditorView.editable.of(false));
		}

		let state = EditorState.create({
			doc: '\n'.repeat(10),
			extensions: extensions,
		});

		let view = new EditorView({
			state: state,
			parent: el,
		});

		let updateListenerExtension = EditorView.updateListener.of((update) => {
			if (update.docChanged) {
				evaluate(`${expression} = \`${update.state.doc.toString()}\``);
			}
		});

		effect(() => {
			if (loaded) {
				return false;
			}

			const html = evaluate(expression);
			console.log(typeof html, expression, '<-------');
			// if (html) {
			view.dispatch({
				changes: {
					from: 0,
					to: view.state.doc.length,
					insert: !$.trim(html) ? '\n'.repeat(10) : format(html),
				},
			});

			view.dispatch({
				effects: [StateEffect.appendConfig.of(updateListenerExtension)],
			});

			if ($.trim(html)) {
				loaded = true;
			}
			// }
		});

		cleanup(() => {
			view.destroy();
			// state.destroy();

			loaded = false;

			el.remove();
		});
	});
};
