export default function (Alpine) {
    Alpine.directive('toggle_menu', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const btn = $(el);
        const body = $('body');

        btn.on('click', function (e) {
            e.preventDefault();

            body.toggleClass('sidebar-enable');

            // var sidebarSize = self.body.attr('data-sidebar-size');
            // if (self.window.width() >= 993) {
            //     if (sidebarSize === 'condensed') {
            //         self.changeSize(defaultSidebarSize !== 'condensed' ? defaultSidebarSize : 'default');
            //     } else {
            //         self.changeSize('condensed');
            //     }
            // } else {
            //     self.changeSize(defaultSidebarSize);
            //     self.body.toggleClass('sidebar-enable');
            // }
        });

        // tippy(el, { interactive: true });
    });
}
