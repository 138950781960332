// const imageError = (e) => {
// 	console.error('Image::error', e);
// 	e.target.src = 'https://placehold.jp/30/333333/ffffff/300x150.png?text=圖片失效';
// };

export const registerImageHelper = (alpine) => {
	alpine.directive(
		'image',
		(el, { value, modifiers, expression }, { Alpine, evaluate, evaluateLater, effect, cleanup }) => {
			effect(() => {
				const url = evaluate(expression);

				el.onerror = (e) => {
					console.error('Image::error', url);
					e.target.src = 'https://placehold.jp/30/333333/ffffff/300x150.png?text=圖片載入錯誤';
				};
				el.src = url;

				console.log('Image::load', url);
			});
		}
	);
};
