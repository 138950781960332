import Alpine from 'alpinejs';
import Controller from './controller';

import { facebookConnectStatus, facebookDisconnect, facebookConnect, facebookUnconnect } from '../libs/api';
import { capitalizeFirstLetter } from '../helpers/utils';

class Account extends Controller {
    filterItems = [];

    data() {
        this.filterItems = [];

        return {
            async init() {
                await this.facebook.status();
            },

            type: null,

            facebook: {
                loaded: false,
                data: {},
                async status() {
                    const { code, data } = await facebookConnectStatus();
                    if (code === 1) {
                        this.data = data;
                        this.loaded = true;
                    }
                },
            },

            chosen: {
                type: null,
                page: null,
                async save() {
                    if (this.page === null) {
                        return false;
                    }

                    const { type, facebook } = Alpine.store('info');

                    const { code } = await facebookConnect({ page: this.page, type });
                    if (code === 1) {
                        const modal = Alpine.store('modal');

                        facebook.loaded = false;
                        await facebook.status();

                        modal.close();
                    }
                },
                async disconnect() {
                    const { facebook } = Alpine.store('info');
                    const { code, data } = await facebookDisconnect();
                    if (code === 1) {
                        const modal = Alpine.store('modal');

                        facebook.loaded = false;
                        await facebook.status();

                        modal.close();
                    }
                },
            },

            link(type) {
                const that = this;
                const status = this.facebook.data;
                type = type.trim().toLowerCase();

                if (status.status !== 'connected') {
                    const strWindowFeatures = 'location=yes,height=570,width=520,scrollbars=yes,status=yes';
                    const url = `${import.meta.env.VITE_MEMBER_BASE}auth/facebook`;
                    const win = window.open(url, '_blank', strWindowFeatures);

                    const messageCallback = function (e) {
                        const data = e.originalEvent.data; // Should work.
                        if (data && data.isLoggedin) {
                            win.close();

                            $(window).off('message', messageCallback);

                            const { facebook } = Alpine.store('info');

                            facebook.loaded = false;
                            facebook.status();

                            that.link(type);
                        }
                    };

                    $(window).off('message').on('message', messageCallback);
                } else if (status[type].status !== 'connected') {
                    this.type = type;
                    this.chosen.type = type;
                    console.log(type, status[type], 'L---');
                    if (typeof status[type].pages !== 'undefined' && status[type].pages.length > 0) {
                        this.chosen.page = status[type].pages[0].id;
                    }

                    sendEvent('modal', {
                        title: `選擇要連結的 ${capitalizeFirstLetter(type)} 專頁`,
                        html: '/pages/setting/account-facebook-link.html',
                        closeBtn: true,
                    });
                }
            },

            async unlink(type) {
                const { code } = await facebookUnconnect({ type });
                if (code === 1) {
                    this.facebook.loaded = false;
                    await this.facebook.status();
                }
            },
        };
    }
}

export const account = new Account();
