import Alpine from 'alpinejs';
import Controller from './controller';

class SiteInit extends Controller {
	data() {
		return {
			current: 1,
			data: {
				slug: '',
				plan: null,
				discount: 0,
				name: '',
			},
			services: {
				status: false,
				current: null,
				list: [],
			},
			toNext(next) {
				this.current = +next;
			},
			changePlan(id) {
				this.data.plan = id;
				this.services.current =
					this.services.list.filter((service) => +service._id === +id)[0] ?? null;
				this.toNext(3);
			},
			pay() {
				console.log('SiteInit::Pay');
				if (!$.trim(this.data.slug) || +this.plan === 0) {
					return false;
				}

				if (typeof TPDirect === 'undefined') {
					console.error('SiteInit::load failed');
					return false;
				}

				const tappayStatus = TPDirect.card.getTappayFieldsStatus();
				if (tappayStatus.canGetPrime === false) {
					console.log(tappayStatus);
					return false;
				}

				TPDirect.card.getPrime(async (result) => {
					if (result.status !== 0) {
						return false;
					}

					const payload = { ...this.data, token: result.card.prime };

					const status = await Alpine.store('api').getClient().post('site/init', payload);
					if (status.code === 1) {
						Alpine.store('auth').status({ url: '' }, true);
					}
				});
			},
			async checkSlug() {
				const result = await Alpine.store('api')
					.getClient()
					.post('site/check', { slug: this.data.slug });
				if (result.data.can_use) {
					this.toNext(2);
				} else {
					window.sendEvent('toast', {
						title: '輸入的網址重複',
						message: `${this.data.slug}.nings.app 已被使用`,
					});
				}
			},
			async getServices() {
				const result = await Alpine.store('api').getClient().post('service/collection');
				if (result.data.rows.length > 0) {
					this.services.list = result.data.rows;
				}

				this.services.status = true;
			},
		};
	}

	run(_$routeParams) {
		const auth = Alpine.store('auth');
		const router = Alpine.store('router');

		if (auth.site.list.length > 0) {
			router.go('');
		}
	}
}

export const siteInit = new SiteInit();
