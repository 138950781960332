import Sortable from 'sortablejs';

export default function (Alpine) {
    Alpine.directive('sortable', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);

        let settings = {
            handle: expression,
            swapThreshold: 1,
            animation: 150,
        };

        if (ta.is('[data-on-change]')) {
            settings.onUpdate = evaluate(ta.data('on-change'));
        }

        const sortable = Sortable.create(el, settings);
    });
}
