export default function (Alpine) {
    Alpine.directive('dropzone', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const container = $(el);
        const input = container.find('input[type=file]');
        const loading = container.find('.dropzone__loading');

        let limit = { filesize: 5, imagesize: { w: 9999, h: 9999 }, ratio: 0 };

        if (container.data('filesize')) {
            limit.filesize = +container.data('filesize');
        }

        if (container.data('imagesize')) {
            const [w, h] = container.data('imagesize').split('x');
            limit.imagesize.w = +w;
            limit.imagesize.h = +h;
        }

        if (container.data('aspect-ratio')) {
            const [w, h] = container.data('aspect-ratio').split(':');
            limit.ratio = Math.round((+w / +h + Number.EPSILON) * 100) / 100;
        }

        input.off('dragover').off('dragleave').off('drop').off('change');

        input.on('dragover', function () {
            loading.toggleClass('is-active', false);
            container.toggleClass('is-active', true);
        });

        input.on('dragleave', function () {
            loading.toggleClass('is-active', false);
            container.toggleClass('is-active', false);

            container.toggleClass('with-error', false);
        });

        input.on('drop', function () {
            loading.toggleClass('is-active', false);
            container.toggleClass('is-active', false);

            container.toggleClass('with-error', false);
        });

        input.on('change', function (e) {
            const file = e.target.files;

            loading.toggleClass('is-active', true);

            if (file.length === 0) {
                loading.toggleClass('is-active', false);
                return false;
            }

            evaluateLater(expression)(async (callback) => {
                const error = container.find('.dropzone__message--with-error');
                const result = await callback(file, limit);

                if (result.success === false) {
                    error.html(result.message);
                    container.toggleClass('with-error', true);
                } else if (result.success === true) {
                    error.html('');
                    container.toggleClass('is-uploaded', true);
                    container.toggleClass('with-error', false);
                }

                loading.toggleClass('is-active', false);
            });
        });

        cleanup(() => {
            input.off('dragover').off('dragleave').off('drop').off('change');
        });
    });
}
