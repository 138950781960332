export const registerQueryHelper = (alpine) => {
	alpine.store('query', {
		init() {
			if (!localStorage) {
				window.sendEvent('toast', {
					title: `Error!!`,
					message: 'localStorage not supported',
				});
			}
		},
		key() {
			const route = Alpine.store('router').current();
			return `query_${route.url}`;
		},
		fetch() {
			let saved = localStorage.getItem(this.key());
			saved = saved === null ? '{}' : saved;

			saved = JSON.parse(saved);

			return saved;
		},
		midify(value) {
			let obj = this.fetch();
			obj = { ...obj, ...value };
			this.update(obj);
		},
		update(value) {
			localStorage.setItem(this.key(), JSON.stringify(value));
		},
		watch(...args) {
			console.log(args);
		},
	});
};
