import Alpine from 'alpinejs';
import Controller from './controller';

import { dashboardStatistics } from '../libs/api';

class Dashboard extends Controller {
    filterItems = [
        {
            id: 1,
            name: '搜尋',
            type: 'search',
            default: '',
            col: 'title',
        },
    ];

    data() {
        return {
            loaded: false,
            data: {
                type: 0,
            },

            statistics: {},

            posts: {},

            init() {
                this.load();
            },

            meta: {
                pos: 0,
                count: 0,
                total: 0,
            },

            changeType() {
                const Dashboard = Alpine.store('info');
                Dashboard.load();
            },

            async load() {
                const query = Alpine.store('query');
                const Dashboard = Alpine.store('info');

                const {
                    code,
                    data: { posts, total },
                } = await dashboardStatistics(Dashboard.data, query.fetch());
                if (code === 1) {
                    Dashboard.statistics = total;
                    Dashboard.posts = posts;

                    Dashboard.meta = {
                        pos: +posts.pos || 0,
                        count: +posts.count || 0,
                        total: +posts.total || 0,
                    };

                    // query.midify(meta);
                    // Dashboard.paginate.init(meta);

                    Dashboard.loaded = true;
                }
            },

            list: {
                get() {
                    const Dashboard = Alpine.store('info');
                    Dashboard.load();
                },
            },
        };
    }
}

export const dashboard = new Dashboard();
