import Iodine from '@caneara/iodine';

import { pressSearch, labelSearch } from '../libs/api';
import { sleep } from '../helpers/utils';

export default function (Alpine) {
    // const setting = ;

    Alpine.data('queryItems', (type = 'article') => ({
        status: 'empty', // loaded, loading

        keywords: '',
        data: {},
        validate: false,
        selected: null,

        isArticle: false,

        init() {
            this.isArticle = type === 'article';
        },

        searchInput: {
            ['@input']() {
                const iodine = new Iodine();
                const result = iodine.assert(this.keywords, ['required', 'string', 'minLength:1']);

                this.validate = result.valid;
            },
            ['@change']() {
                const iodine = new Iodine();
                const result = iodine.assert(this.keywords, ['required', 'string', 'minLength:1']);

                this.validate = result.valid;
            },
            async ['@keyup.enter.debounce']() {
                if (this.$event.isComposing === false) {
                    await this.query.apply(this);
                }
            },
        },
        searchButton: {
            async ['@click']() {
                await this.query.apply(this);
            },
        },

        async query() {
            if (this.validate === false) {
                window.sendEvent('toast', { title: '請輸入搜尋文字。' });
                return false;
            }

            this.status = 'loading';

            const { code, data } = this.isArticle ? await pressSearch(this.keywords) : await labelSearch(this.keywords);

            if (code === 1) {
                this.status = 'loaded';

                this.data = data;
            } else {
                this.status = 'empty';
            }
        },

        radio: {
            [':value']() {
                console.log(this.item.id, '====');
                return +this.item.id;
            },
            [':id']() {
                return `_${this.item.id}`;
            },
            [':name']() {
                return `_article`;
            },
            // ['@click']() {
            //     this.selected = {
            //         id: this.item.id,
            //         name: this.item.title,
            //     };
            // },
        },

        label: {
            ['x-text']() {
                return this.item.title;
            },
            [':for']() {
                return `_${this.item.id}`;
            },
        },

        add: {
            ['@click']() {
                const query = Alpine.store(this.isArticle ? 'articleQuery' : 'categoryQuery');
                if (this.selected === null) {
                    window.sendEvent('toast', { title: this.isArticle ? '請選擇一篇文章。' : '請選擇一個專欄。' });
                    return false;
                }

                if (query.list.find((i) => +i.id === +this.selected) !== undefined) {
                    this.selected = null;
                    query.reset();
                    window.sendEvent('toast', { title: this.isArticle ? '文章已選擇。' : '專欄已選擇。' });
                    return false;
                }

                query.selected = this.data.rows.filter((i) => +i.id === +this.selected)[0];

                query.save();
                query.reset();

                this.m.close();
            },
        },
    }));
}
