import tippy from 'tippy.js';

export default function (Alpine) {
    Alpine.directive('tippy', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        console.log('tippy::init', el);
        
        const instance = tippy(el, { interactive: true, appendTo: () => document.body });

        effect(() => {
            instance.enable();
        });

        cleanup(() => {
            instance.destroy();
        });
    });
}
