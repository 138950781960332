import Alpine from 'alpinejs';
import Iodine from '@caneara/iodine';
import Controller from './controller';

import { siteAvailableCheck, memberForgot, memberReset, memberWriterVerify } from '../libs/api';
import { sleep, buttonFlow } from '../helpers/utils';

class Auth extends Controller {
    data() {
        return {
            title: 'Login',
            // login: this.login,
            // register: this.register,
            login: {
                data: {
                    account: '',
                    password: '',
                    token: '',
                },
                passwordType: 'password',
                saved: null,
                init() {
                    let saved = localStorage.getItem('rmbr');
                    saved = saved === null ? '{}' : saved;
                    saved = JSON.parse(saved);

                    const firstKey = Object.keys(saved)[0];

                    if (typeof firstKey !== 'undefined') {
                        const first = saved[firstKey];

                        this.saved = first;
                    }
                },
                loginByToken() {
                    Alpine.store('auth').signInByToken(
                        {
                            password: this.data.password,
                            token: this.data.token,
                            remember: this.saved.token,
                        },
                        'writer',
                    );
                },
                clearToken() {
                    this.saved = null;

                    localStorage.setItem('rmbr', '{}');
                },
                submit() {
                    Alpine.store('auth').signIn(this.data, 'writer');
                },
                async forgot(input, btn) {
                    btn = $(btn);

                    const button = buttonFlow(btn);

                    button.loading();

                    if (!$.trim(this.data.account) || !$.trim(this.data.token)) {
                        window.sendEvent('toast', { title: '我們需要你的信箱做密碼重設' });

                        input.focus();
                        button.reset();

                        return false;
                    }

                    await sleep(650);

                    const { code, data } = await memberForgot({ account: this.data.account, token: this.data.token });

                    button.done();
                    window.sendEvent('toast', { title: '已發送密碼提示到你的信箱', icon: 'bi-send' });

                    sendEvent('validation::reset');
                },
            },
            reset: {
                data: {
                    account: '',
                    token: null,
                },
                async submit() {
                    const { code, data } = await memberForgot(this.data);

                    if (code === 1) {
                        window.sendEvent('toast', { title: '重設密碼信已寄出', icon: 'bi-send' });

                        Alpine.store('router').go('/auth/login');
                    }

                    sendEvent('validation::reset');
                },
            },
            forgot: {
                passwordType: 'password',
                data: {
                    password: '',
                    'password-rtn': '',
                    token: '',
                },
                async submit() {
                    const urlParams = new URLSearchParams(window.location.search.split('?')[1]);
                    const v = urlParams.get('v');

                    const { code } = await memberReset({
                        password: this.data.password,
                        token: this.data.token,
                        reset_token: v || '',
                    });

                    if (code === 1) {
                        const router = Alpine.store('router');

                        router.go('auth/forgot/success');
                    }
                },
                timer: null,
                count: 5,
                countdown() {
                    const that = this;
                    const router = Alpine.store('router');

                    clearInterval(that.timer);

                    that.timer = setInterval(() => {
                        that.count -= 1;

                        if (that.count === 0) {
                            clearInterval(that.timer);

                            router.go('auth/login');
                        }
                    }, 1000);
                },
            },
            register: {
                data: {
                    account: '',
                    password: '',
                    name: '',
                    slug: '',
                    verify: null,
                    token: null,
                },
                password: {
                    type: 'password',
                    display: false,
                },
                account: {
                    verify: false,
                },
                submit() {
                    const that = this;
                    const data = that.data;

                    if (that.account.verify === false) {
                        window.sendEvent('toast', { title: '請先驗證電子信箱', icon: 'bi-envelope-exclamation' });
                        sendEvent('validation::done');
                        return false;
                    }

                    sendEvent('altcha::verify');

                    Alpine.store('auth').register(data, 'writer', () => {
                        Alpine.store('router').go('/');
                    });
                },
                async verify($el) {
                    const that = this;

                    const button = buttonFlow($($el));
                    button.loading();

                    const iodine = new Iodine();
                    const result = iodine.assert(this.data.account, ['required', 'email']);

                    if (result.valid === false) {
                        window.sendEvent('toast', { title: '無效的電子信箱', icon: 'bi-envelope-slash' });
                        button.reset();
                        return false;
                    }

                    const { code, data } = await memberWriterVerify({
                        email: this.data.account,
                        token: this.data.token,
                    });

                    if (code === 1) {
                        sendEvent('altcha::verify');

                        const verify = Alpine.store('verify');
                        verify.display(
                            data.token,
                            data.ts,
                            {
                                email: this.data.account,
                            },
                            '信箱驗證',
                            (data) => {
                                that.data.verify = data.token;
                                that.account.verify = true;
                            },
                        );
                    }

                    button.done();
                },
            },
            // register: {
            //     data: {
            //         slug: '',
            //         title: '',
            //         name: '',
            //         desc: '',
            //         category: null,
            //         tag: [],
            //         account: '',
            //         password: '',
            //     },

            //     domain() {
            //         let domain = new URL(import.meta.env.VITE_WEB_URL);
            //         return domain.hostname;
            //     },

            //     displayPassword: false,
            //     passwordType: 'password',
            //     // displayingPassword() {},

            //     step: 1,
            //     prevStep() {
            //         this.step -= 1;

            //         if (this.step <= 1) {
            //             this.step = 1;
            //         }
            //     },
            //     nextStep() {
            //         this.step += 1;

            //         console.log('auth::next::step', this.step);

            //         if (this.step >= 5) {
            //             this.step = 5;
            //         }

            //         console.log('auth::next::step::checked', this.step);
            //     },
            //     checkSlug() {
            //         const that = this;
            //         return async (done) => {
            //             const {
            //                 code,
            //                 data: { available },
            //             } = await siteAvailableCheck(that.data.slug);

            //             if (code === 1 && available === true) {
            //                 done();
            //             } else {
            //                 window.sendEvent('toast', {
            //                     title: '該網名已被使用，請嘗試別的',
            //                 });
            //                 done(false);
            //             }

            //             console.log('register:check::slug');
            //         };
            //     },
            //     changed() {
            //         return () => console.log('register::changed');
            //     },
            //     submit() {
            //         const that = this;
            //         Alpine.store('auth').register(
            //             this.data,
            //             'writer',
            //             // {
            //             //     email: this.data.account,
            //             // },
            //             // '信箱驗證',
            //             () => {
            //                 that.nextStep();
            //             },
            //         );
            //     },
            //     tags: [
            //         { id: 'culture', name: '文化' },
            //         { id: 'politics', name: '政治' },
            //         { id: 'international', name: '國際' },
            //         { id: 'technology', name: '科技' },
            //         { id: 'warzone', name: '戰地' },
            //         { id: 'sustainability', name: '永續' },
            //         { id: 'business', name: '商業' },
            //         { id: 'finance', name: '金融' },
            //         { id: 'life', name: '生活' },
            //         { id: 'entertainment', name: '娛樂' },
            //         { id: 'fashion', name: '時尚' },
            //         { id: 'art', name: '藝術' },
            //         { id: 'education', name: '教育' },
            //         { id: 'philosophy', name: '哲學' },
            //         { id: 'history', name: '歷史' },
            //     ],
            // },
        };
    }

    // login() {
    //     Alpine.store('auth').signIn(Alpine.store('info').formData);
    // }

    // register() {
    //     Alpine.store('auth').register(Alpine.store('info').register);
    // }
}

export const auth = new Auth();
