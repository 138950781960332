const set2def = (el) => {
    const def = el.find('[data-default=1]');
    const defValue = def.prop('value');

    el.val(defValue);

    return defValue;
};

const update = (el, token, force) => {
    const query = Alpine.store('query');

    let saved = query.fetch();

    saved.sort = {};
    saved.sort.current = token;

    query.update(saved);

    el.val(token);

    if (!!force) {
        const info = Alpine.store('info');
        info.list.get();
    }
};

export default function (Alpine) {
    Alpine.directive('sort', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);
        const query = Alpine.store('query');

        let saved = query.fetch();
        let init = false;

        if (typeof saved.sort === 'undefined') {
            const def = set2def(ta);
            update(ta, def);
        } else if (saved.sort.current) {
            console.log('sort::current', saved.sort.current);

            update(ta, saved.sort.current);
        }

        ta.on('change', () => {
            const current = ta.val();
            update(ta, current, true);
        });

        cleanup(() => {
            ta.off('change');
        });
    });
}
