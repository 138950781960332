import { sleep } from './utils';
const templates = new Map();

export default {
    list: [],
    id: 0,
    tmpl: {},
    closeTa(id) {
        const index = this.list.findIndex((x) => x.id === +id);
        const closed = this.list.splice(index, 1)[0] ?? {};

        if (typeof closed.onClose === 'function') {
            closed.onClose.apply(null);
        }
    },
    closeThis(id) {
        this.closeTa(id);
    },
    add(data) {
        data.id = this.id;
        data.close = () => this.closeThis(data.id);
        this.id += 1;
        this.list.push(data);
    },
    close() {
        if (this.list.length === 0) {
            return false;
        }

        const poped = this.list.pop();
        if (typeof poped.onClose === 'function') {
            poped.onClose.apply(null);
        }
    },

    init(name, refs) {
        if (typeof name === 'undefined') {
            return false;
        }

        const content = this.get(name);
        if (content) {
            refs.innerHTML = '';
            sleep(50).then(() => {
                refs.innerHTML = content;
            });
        } else {
            fetch(name)
                .then((response) => response.text())
                .then((data) => {
                    refs.innerHTML = data;
                    this.save(name, data);
                });
        }
    },

    get(name) {
        return templates.get(name);
    },

    save(name, tmpl) {
        templates.set(name, tmpl);
    },
};
