import { labelList, labelShow, labelSave, labelDel } from '../libs/api';
import { buttonFlow, sleep, uploadProgress } from '../helpers/utils';

export default function (Alpine) {
    const query = Alpine.store('query');
    let labelData = Alpine.reactive({
        loaded: false,
        data: {
            type: null,
            rows: {},
        },
        get meta() {
            return {
                pos: +this.data.rows.pos || 0,
                count: +this.data.rows.count || 0,
                total: +this.data.rows.total || 0,
            };
        },
    });

    Alpine.data('label', (type = null, id = 0) => ({
        // loaded: false,
        // data: {
        //     type: null,
        //     rows: {},
        // },
        label: {
            mode: 'new',
            loaded: false,
            data: {
                cover: null,
            },
            get name() {
                return this.data.title;
            },
            set slug(val) {
                // console.log(name)
                this.data.slug = `${val}`;
            },
        },
        title(postfix = '管理') {
            return (this.type === 'tag' ? '標籤' : '專欄') + postfix;
        },
        url() {
            return this.type === 'tag' ? 'tag' : 'category';
        },
        get loaded() {
            return labelData.loaded;
        },
        set loaded(val) {
            labelData.loaded = !!val;
        },
        get type() {
            return labelData.data.type === 'tag' ? 'tag' : 'category';
        },
        set labels(val) {
            labelData.data.rows = val;
        },
        get labels() {
            return labelData.data.rows;
        },
        get meta() {
            return labelData.meta;
        },

        async init() {
            if (type === null) {
                type = this.$store.info.data.type;
            }

            labelData.data.type = type;

            if (typeof id === 'number' && id > 0) {
                this.label.mode = 'edit';
                await this.show(id);
            } else if (id === null) {
                this.label.loaded = true;
            } else {
                await this.list();
            }
        },

        async list() {
            labelData.loaded = false;

            const { code, data } = await labelList({
                type: labelData.data.type,
                query: query.fetch(),
            });

            labelData.loaded = true;

            if (code === 1 && data !== undefined) {
                labelData.data.rows = data;

                query.midify(labelData.meta);
            }
        },

        async show(id) {
            const { code, data } = await labelShow({
                type: this.type,
                id: id,
            });

            this.label.loaded = true;
            if (code === 1 && data !== undefined) {
                this.label.data = data;
            }
        },

        cover() {
            const that = this;
            return async (files, limit) => {
                const result = await uploadProgress(files, limit);
                if (result.success === true) {
                    that.label.data.cover = result.path;
                }

                return result;
            };
        },

        trash: {
            ['@click']() {
                sendEvent('modal', {
                    title: `刪除 「${this.label.title || this.label.name}」${this.title('')}`,
                    html: '/pages/label/delete.html',
                    closeBtn: true,
                    data: { id: this.label.id },
                });
            },
        },

        deleting: {
            async ['@click']() {
                const ta = $(this.$el);
                const btn = buttonFlow(ta);

                try {
                    btn.loading();
                    const { code, data } = await labelDel({
                        type: this.type,
                        id: id,
                    });
                    if (code === 1) {
                        Alpine.store('modal').close();
                        await this.list();
                    }
                    btn.done();
                } catch (error) {
                    console.error('Error subscribe:', error);
                    btn.reset();
                }
            },
        },

        deleteMessage: {
            ['x-text']() {
                if (this.type === 'tag') {
                    return '刪除後，此標籤將從關聯的文章及商品資訊中移除';
                }
                return '刪除後，專欄內的文章會被歸類為「未分類」的文章';
            },
        },

        edit: {
            ['@click']() {
                const isNew = this.label.id === undefined;
                sendEvent('modal', {
                    title: `${isNew ? '新增' : '編輯'}${this.title('')}`,
                    html: '/pages/label/edit.html',
                    closeBtn: true,
                    data: { id: isNew ? null : this.label.id },
                });
            },
        },

        async saving() {
            const payload = this.label.data;

            if (this.label.mode !== 'new') {
                payload.id = id;
            }

            payload.type = type;

            try {
                const { code, data } = await labelSave(payload);
                if (code === 1) {
                    Alpine.store('modal').close();
                    await this.list();
                }
            } catch (error) {
                console.error('Error subscribe:', error);
            }
        },

        save: {
            ['x-text']() {
                return this.label.mode === 'new' ? '新增' : '儲存';
            },
        },
    }));
}
