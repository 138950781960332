// import Choices from 'choices.js';
import TomSelect from 'tom-select';

export default function (Alpine) {
    let group = {};
    Alpine.directive('selector', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);
        const groupName = ta.data('group');

        let selector = null;
        let initialed = false;
        let settings = {
            valueField: 'id',
            labelField: 'title',
            searchField: 'title',
            plugins: {},
            createFilter: function (input) {
                return input.length >= 2;
            },
            render: {
                option_create: function (data, escape) {
                    return `<div class="create">新增 "${escape(data.input)}"</div>`;
                },
                no_results: function (data, escape) {
                    return `<div class="no-results">沒有找到關於 "${escape(data.input)}"</div>`;
                },
                loading: function (data, escape) {
                    return `<div class="mx-auto text-center"><div class="spinner-border m-2" role="status"><span class="sr-only"></span></div></div>`;
                },
            },
        };

        const onChange = function (value) {
            const that = this;
            const others = group[groupName].filter((item) => item != that);

            others.map((item) => {
                const itemValue = item.getValue();
                if (+value === +itemValue) {
                    item.clear(false);
                }
            });
        };

        if (ta.is('[data-can-add="true"]')) {
            settings.create = true;
        }

        if (ta.is('[data-max-items]')) {
            settings.maxItems = +ta.data('max-items');
        }

        if (ta.is('[data-remove-btn="true"]')) {
            settings.plugins.remove_button = {
                title: '刪除',
            };
        }

        if (ta.is('[data-on-option-add]')) {
            const cb = evaluate(ta.data('on-option-add'));
            settings.onOptionAdd = (value, item) => {
                cb.call(null, value, item, selector);
            };
        }

        if (ta.is('[data-on-change]')) {
            const cb = evaluate(ta.data('on-change'));
            settings.onChange = (value) => {
                cb.call(null, value, selector);
            };
        }

        if (ta.is('[data-clear-btn="true"]')) {
            settings.plugins.clear_button = {
                title: '刪除',
                html: (data) =>
                    `<div class="${data.className}" title="${data.title}"><i class="bi bi-x-circle-fill"></i></div>`,
            };
        }

        if (ta.is('[data-group]')) {
            group[groupName] = group[groupName] ? group[groupName] : [];
            settings.onChange = onChange;
        }

        if (ta.is('[data-load]')) {
            settings.load = evaluate(ta.data('load'));
        }

        console.log('selector::settings', settings);

        selector = new TomSelect(el, settings);

        if (ta.is('[data-preload]')) {
            const method = evaluate(ta.data('preload'));

            if (typeof method === 'function') {
                method.call(null, selector);
            }
        }

        if (ta.is('[data-group]')) {
            const groupName = ta.data('group');
            group[groupName].push(selector);
        }

        console.log('selector::group', group);

        // console.log(selectize);

        // selectize(selector, {});

        // selector.selectize()
        // const dataExpression = selector.data('model');

        // let choices = null;

        // selector.on('change', () => {
        //     const val = choices.getValue(true);
        //     console.log('selector changed', val, 'input', selector.val());
        //         evaluate(`${dataExpression} = ${JSON.stringify(val)}`);
        // });

        if (`${expression}` !== '') {
            const container = evaluate(expression);

            effect(() => {
                if (container && container.loaded && selector !== null && initialed === false) {
                    container.set(selector);
                    initialed = true;
                }
            });
        }

        // console.log(selector.is('[data-can-add="true"]'), '[[======================')

        // choices = new Choices(el, {
        //     noResultsText: '找不到',
        //     removeItems: true,
        //     removeItemButton: true,
        //     duplicateItemsAllowed: false,
        //     addItems: !!selector.is('[data-can-add="true"]'),
        //     addItemText: (value) => {
        //         return `新增<b>"${value}"</b>`;
        //     },
        // }).enable();
    });
}
