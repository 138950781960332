import EditorJS from '@editorjs/editorjs';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import NestedList from '@editorjs/nested-list';

import Paragraph from '@editorjs/paragraph';
import AnchorTune from '../libs/editor.js/editorjs-anchor';
// import Album from '../libs/editor.js/album/album';
// import AnchorBlockTune from '../libs/editor.js/editorjs-anchor.js';
// import FontColor from '../libs/editor.js/editorjs-text-color-plugin.js';
import ImageTool from '@editorjs/image';
import TextVariantTune from '@editorjs/text-variant-tune';
import Table from '@editorjs/table';
import Undo from 'editorjs-undo';
import Embed from '@editorjs/embed';

import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';

import { compareArrays, jsonParse, sleep, url } from './utils';
import { elementImageUpload } from '../libs/api';
import draftConfig from '../libs/draft.config';

const analysis = function (blocks) {
    let paragraph = '';
    let imageCount = 0;
    const avgReadingSpeed = 300;

    blocks.map((block) => {
        if (block.type === 'paragraph') {
            const text = block.data.text.replace(/(<([^>]+)>)/gi, '').replace(/&(nbsp|amp|quot|lt|gt);/g, '');

            paragraph = paragraph.concat('', text);
        } else if (block.type === 'image') {
            imageCount += 1;
        } else if (block.type === 'album') {
            imageCount += block.data.urls.length ?? 0;
        }
    });

    const chineseCount = paragraph.match(/[\u4e00-\u9fa5]/g) ? paragraph.match(/[\u4e00-\u9fa5]/g).length : 0;
    const englishCount = paragraph.match(/[a-zA-Z]/g) ? paragraph.match(/[a-zA-Z]/g).length : 0;
    const numberCount = paragraph.match(/\d/g) ? paragraph.match(/\d/g).length : 0;

    const totalCount = chineseCount + englishCount + numberCount + imageCount;
    const readingTime = totalCount / avgReadingSpeed;

    return {
        text: paragraph,
        total: totalCount,
        readingTime: Math.ceil(readingTime),
        detail: {
            chinese: chineseCount,
            english: englishCount,
            number: numberCount,
            image: imageCount,
        },
    };
};

const getConfigs = () => {
    return draftConfig(AlpineI18n);
};

export const registerEditorHelper = (alpine) => {
    let editor = null;
    let execWaiting = null;

    alpine.directive('editor', async (el, { expression }, { evaluate, evaluateLater, effect, cleanup }) => {
        const getContent = evaluateLater(expression);
        const loadedSignal = el.dataset.loaded;

        let loaded = false;
        let readonly = false;
        let configs = getConfigs();

        const execAnalysis = function (content) {
            if (el.dataset.analysis) {
                const meta = analysis(content.blocks);
                evaluate(`${el.dataset.analysis} = ${JSON.stringify(meta)}`);
            }
        };

        const execSave = function (api) {
            api.saver.save().then((output) => {
                const originalContentString = evaluate(expression);
                const originalContent = jsonParse(originalContentString);
                const isDifferent = compareArrays(originalContent.blocks, output.blocks);

                const spell = `${expression} = ${JSON.stringify(output)}`;
                evaluate(spell);

                execAnalysis(output);

                if (el.dataset.onchange) {
                    const onChange = evaluate(el.dataset.onchange);

                    console.log(isDifferent, originalContent.blocks, output.blocks, '()()()()()()()()()()()');

                    if (isDifferent === false && typeof onChange === 'function') {
                        onChange(originalContent, output);
                    }
                }
            });
        };

        const setContent = function () {
            getContent((content) => {
                try {
                    console.log('editor::set::content', content, evaluate(loadedSignal));
                    if (typeof content === 'undefined' || typeof content.blocks === 'undefined') {
                        if (/<\/?[a-z][\s\S]*>/i.test(content)) {
                            console.log('html content', content);
                            editor.blocks.renderFromHTML(content).then(() => {
                                this.api.saver.save().then((saved) => {
                                    const undo = new Undo({ editor });
                                    undo.initialize(saved);

                                    execAnalysis(saved);
                                });
                            });
                        }
                        return true;
                    }

                    // let content = JSON.parse(content);

                    if (content?.blocks?.length === 0) {
                        return false;
                    }

                    editor.blocks.render(content).then(() => {
                        const undo = new Undo({ editor });
                        undo.initialize(content);

                        execAnalysis(content);
                    });
                } catch (e) {
                    console.error(e, 'error');
                    editor.clear();
                }
            });
        };

        // await sleep(450);

        editor = new EditorJS({
            holder: el,
            onReady: () => {
                effect(() => {
                    console.log('editor::ready::effect');
                    let current = false;
                    if (typeof loadedSignal !== 'undefined') {
                        current = evaluate(loadedSignal);
                    } else {
                        current = true;
                    }

                    if (loaded === false && current === true) {
                        setContent();
                        loaded = true;
                    }
                });
            },
            onChange: (api, event) => {
                if (readonly) return;

                clearTimeout(execWaiting);
                execWaiting = setTimeout(() => execSave(api), 50);
            },
            ...configs,
        });

        await editor.isReady;

        $(window)
            .on('editor::focus', () => {
                editor.focus();
            })
            .on('editor::set::readonly', () => {
                readonly = true;
                editor.readOnly.toggle(true);
            })
            .on('editor::set::content', async (e) => {
                editor.clear();

                await sleep(10);

                const {
                    originalEvent: {
                        detail: { content },
                    },
                } = e;

                try {
                    // const data = JSON.parse(content);
                    await editor.blocks.render(content);

                    const undo = new Undo({ editor });
                    undo.initialize(content);

                    execAnalysis(content);
                } catch (e) {
                    console.error(e);
                }
            })
            .on('editor::set::content::html', async (e) => {
                editor.clear();

                await sleep(10);

                const {
                    originalEvent: {
                        detail: { content },
                    },
                } = e;

                try {
                    // const data = JSON.parse(content);
                    await editor.blocks.renderFromHTML(content);
                } catch (e) {
                    console.error(e);
                }
            })
            .on('editor::reset', async () => {
                editor.clear();

                await sleep(10);
                setContent();

                await sleep(10);
                editor.readOnly.toggle(false);

                readonly = false;
            });

        cleanup(() => {
            editor.destroy();

            el.remove();
            $(window).off(
                'editor::focus editor::set::readonly editor::set::content editor::set::content::html editor::reset'
            );
        });
    });

    alpine.magic('editor', (el, { Alpine }) => {
        return editor;
    });
};
